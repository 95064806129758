import React from 'react'
import PrintIcon from '../../../ui/icons/PrintIcon/PrintIcon'
import QRIcon from '../../../ui/icons/QRIcon/QRIcon'
import styles from './PrintClass.module.scss'
import { ILocalization } from '../../../config/languages.config'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, COLORS, SIZE } from '@magmamath/ui'
import useText from '../../../i18n/hook'
import { IClass } from '../../../api/types.classes'
import { useUnit } from 'effector-react'
import { printClassModel } from './model'
import PrintClassModal from './PrintClassModal'
import { PrintClassType } from './types'

export interface IPrintClassProps {
  addedClass: IClass
  title: string
  localization: ILocalization
  isArchivedClass: boolean
  isQRLoginEnabled: boolean
}

const PrintClass: React.FC<IPrintClassProps> = ({
  addedClass,
  title,
  localization,
  isQRLoginEnabled,
}) => {
  const isLoading = useUnit(printClassModel.$loadingQRCodeData)
  const t = useText()

  const Icon = isQRLoginEnabled ? QRIcon : PrintIcon

  return (
    <>
      <div className={styles.Container}>
        <Button
          size={SIZE.MEDIUM}
          variant={BUTTON_VARIANT.SECONDARY}
          color={BUTTON_COLOR.ORANGE}
          icon={<Icon color={COLORS.NEUTRAL_10} size={14} />}
          isLoading={isLoading}
          onClick={() =>
            printClassModel.setState({
              class: addedClass,
              type: isQRLoginEnabled ? PrintClassType.QR : PrintClassType.LIST,
            })
          }
        >
          {t.printTxt}
        </Button>
      </div>
      <PrintClassModal title={title} localization={localization} />
    </>
  )
}

export default PrintClass
