import { createEffect, createEvent, createStore, attach } from 'effector'
import { FormFields, FormState, CheckUsernameFxProps, SubmitFormFxProps } from './types'
import { defaultFormState } from './constants'
import { api } from 'api'
import { studentsTabModel } from 'features/tables/StudentsTable/model'

export class addStudentToClassForm {
  public static readonly setValue = createEvent<{ fieldName: FormFields; value: string }>()
  public static readonly setError = createEvent<{ fieldName: FormFields; error: string | null }>()
  public static readonly reset = createEvent()

  public static readonly checkUsernameFx = createEffect(({ username, t }: CheckUsernameFxProps) =>
    api.auth.usernameCheck({ username })
  )

  public static readonly $formState = createStore<FormState>(defaultFormState)
    .on(this.setError, (oldState, { fieldName, error }) => {
      return {
        ...oldState,
        [fieldName]: {
          ...oldState[fieldName],
          error,
        },
      }
    })
    .on(this.setValue, (oldState, { fieldName, value }) => {
      return {
        ...oldState,
        [fieldName]: {
          ...oldState[fieldName],
          value,
        },
      }
    })
    .on(this.checkUsernameFx.done, (oldState, { result, params }) => {
      if (result.available && result.valid) {
        return {
          ...oldState,
          [FormFields.USERNAME]: {
            ...oldState[FormFields.USERNAME],
            error: null,
          },
        }
      }
      return {
        ...oldState,
        [FormFields.USERNAME]: {
          ...oldState[FormFields.USERNAME],
          error: params.t.alreadyExistUsername,
        },
      }
    })
    .on(this.reset, () => defaultFormState)
  public static readonly $hasError = this.$formState.map((state) => {
    return Object.values(state).some((field) => field.error !== null)
  })

  public static readonly submitFormFx = attach({
    source: { formState: this.$formState },
    mapParams: (props: SubmitFormFxProps, { formState }: { formState: FormState }) => ({
      ...props,
      formState,
    }),
    effect: createEffect(
      async ({
        invitationalCode,
        schoolId,
        classId,
        t,
        formState,
      }: SubmitFormFxProps & { formState: FormState }) => {
        const values = {
          firstName: formState[FormFields.FIRST_NAME].value,
          lastName: formState[FormFields.LAST_NAME].value,
          username: formState[FormFields.USERNAME].value,
          password: formState[FormFields.PASSWORD].value,
          phone: 0,
          type: 2,
          invitationalCode,
          schoolId,
        }

        return await studentsTabModel.signUpStudentFx({ data: values, classId, t })
      }
    ),
  })
}
