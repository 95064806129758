export const swedishDictionary: ILocalization['data'] = {
  add: 'Lägg till',
  activatePracticeModeTxt: 'Aktivera träna',
  addYourFirstStudent: 'Lägg till din första elev',
  acceptTxt: 'Jag accepterar',
  access: 'Åtkomst',
  accountTxt: 'Konto',
  addAnswer: 'Lägg till svar',
  addClass: 'Skapa klass',
  addExistingStudentTxt: 'Lägg till existerande elev',
  addImage: 'Lägg till bild',
  addNewProblemTxt: 'Ny uppgift',
  addMoreBooks: 'Lägg till Fler böcker',
  addMoreStudents: 'Lägg till en ny elev',
  addMoreStudentsCta: 'Vill du lägga till fler elever?',
  addNewTopic: 'Ny kategori',
  addManuallyTxt: 'Lägg till manuellt',
  addStudentDirectlyTxt: 'Lägg till elev direkt',
  addStudentsWithCodeTxt: 'Elever kan gå med genom att ange klasskoden när de registrerar sig',
  addStudent: 'Lägg till elev',
  addFirstStudent: 'Lägg till en första elev',
  algebra: 'Algebra',
  goToAssignmentTxt: 'Gå till övningen',
  goToAssignmentsTxt: 'Gå till övningar',
  addStudentSuccessTxt: 'Elev tillagd!',
  addStudentsToGroup: 'Välj elever till din grupp',
  addStudents: 'Lägg till elever',
  addSubproblem: 'Lägg till deluppgift',
  addTeachersTxt: 'Lägg till lärare',
  addedBulkExercisesMessage: (classesNumber) => `Övningar skapade för ${classesNumber} klasser`,
  addedExerciseTxt: 'Övning tillagd',
  alertMessageWhenDeleteAClass:
    'Om du delar klassen kommer övningen raderas för din kollega också.',
  allBooks: 'Alla böcker',
  allBooksFromSeriesAddedTxt: 'Dessa böcker är redan i din lista',
  allExercises: 'Alla',
  allGradesTxt: 'Alla årskurser',
  allPasswordsOfClassWereUpdated: 'Alla lösenord i klassen har updaterats',
  allClassesTxt: 'Alla klasser',
  allGroups: 'Alla grupper',
  allStudentsTxt: 'Alla elever',
  allTxt: 'Alla',
  allowAnswer: 'Tillåt facit',
  allowAnswerChecking: 'Tillåt facit',
  alreadyExistEmail: 'Det här email finns redan eller är ogiltigt.',
  alreadyExistUsername: 'Det här användarnamnet finns redan eller är ogiltigt.',
  alternativeTxt: 'Alternativ',
  answerTxt: 'Svar',
  answeredTxt: 'Avklarat',
  anonymousName: 'Anonymisera',
  anonymousCorrectness: 'Dölj resultat',
  applyTxt: 'Tillämpa',
  applyToAllTxt: 'Tillämpa för alla',
  archiveExerciseTxt: 'Arkiveras',
  archiveForAll: 'Arkivera för alla',
  archiveTxt: 'Arkivera',
  archivedSingleTxt: 'Arkiverad',
  archivedTxt: 'Arkiverade',
  archiveClass: 'Arkivera klass',
  archiveClassDescription: 'Är du säker? Alla lärare kommer att förlora tillgången till klassen.',
  archiveClassConfirmation:
    'Klassen kommer att arkiveras eftersom du är den enda läraren med åtkomst till den.',
  archivedClass: 'Arkiverade klasser',
  areYouStudent: 'Är du elev?',
  areYouSure: 'Är du säker?',
  askTxt: 'Be',
  askRequestRestTxt:
    'att dela klassen med dig, eller skicka ett meddelande genom att klicka på knappen nedan',
  assessExam: 'Rätta',
  assessExamQuestion:
    'När du rättat, får ALLA elever se sitt resultat. Gör detta först när ALLA elever är klara med provet.',
  assignmentNamePlaceholder: 'Ge övningen ett namn',
  attemptTxt: 'Försök',
  attemptsTxt: 'Försök',
  attentionMaxChosenProblems: 'Hallå där! Vi rekommenderar att ha max 50 uppgifter i en övning.',
  availabilityTxt: 'Tillgänglighet',
  availableGradesTxt: 'Tillgängliga årskurser',
  availableSingleTxt: 'Tillgänglig',
  availableStatusTxt: 'Tillgänglig',
  availableTxt: 'Tillgängliga',
  // tslint:disable-next-line:object-literal-sort-keys
  aZText: 'A-ö',
  zAText: 'Ö-a',
  backTxt: 'Tillbaka',
  blockTxt: 'Block',
  bookshelf: 'Bokhylla',
  bookSeriesEmptyPageTxt:
    'Det finns inget specifikt material för ditt land. Men du kan fortfarande skapa uppgifter själv',
  bookTxt: 'Bok',
  bookAddedToList:
    'lades till bland dina böcker. Boken och dess uppgifter finns nu bland dina böcker när du skapar en ny övning',
  bookBelongsToAnotherTeacher: 'tillhör en annan lärare',
  bookRemovedFromList: 'tas bort från din lista',
  booksTxt: 'Böcker',
  calculator: 'Räknare',
  cancelTxt: 'Avbryt',
  cancelChangesTxt: 'Avsluta',
  cannotDeleteSection: 'Du kan tyvärr inte radera det här delkapitlet',
  classInfo: 'Klassinformation',
  categoryText: 'Kategori',
  categories: 'Delområden',
  contentTitle:
    'Här kan du hitta fler böcker att lägga till i din lista. (K) står för kompletterande material. Detta är Matteappens eget material som kompletterar populära bokserier.',
  chapterTxt: 'Kapitel',
  changeGrade: 'Ändra årskurs',
  changesSavedTxt: 'Sparat',
  cheatDetection: 'Säkert läge',
  cheatDetectionDescription:
    'Meddelar dig när en elev lämnar provet, och du behöver släppa in dem igen.',
  chooseGrade: 'Välj årskurs',
  chooseSchoolFromList: 'Välj en skola från listan',
  chooseSingleStudentTxt: 'Välj enskilda elever',
  chooseStudents: 'Välj elever',
  chosenTxt: 'valda',
  classAddedTxt: 'Klass tillagd',
  classcode: 'Klasskod',
  // tslint:disable-next-line:object-literal-sort-keys
  classDeletedTxt: 'Klassen raderad',
  classNameEditedTxt: 'Klassnamn redigerad',
  classNameTxt: 'Klassnamn',
  createClassNameTxt: 'Klassens namn',
  classOwnerTxt: 'Klassägare',
  classSettings: 'Klassinställningar',
  classTxt: 'Klass',
  classesTxt: 'Klasser',
  clearTxt: 'Rensa',
  common: 'Vanlig',
  codeTxt: 'Kod',
  colorIndex: 'Färgindex',
  confirm: 'Bekräfta',
  contentGuide:
    'Här kan du lägga till böcker i din lista. Klicka på "Ny övning" för att se färdiga övningar att dela ut till dina elever.',
  contentSweden: 'Svenskt material',
  contentTxt: 'innehåll',
  contentMaterialTxt: 'Bibliotek',
  commonError: 'Vanligt felsvar',
  complementaryContentSweden: 'Svenskt material i annan ordning',
  compressHeatmap: 'Komprimera vy',
  copy: 'Kopiera',
  copyCode: 'Kopiera kod',
  copyLink: 'Kopiera länk',
  copied: 'Kopierat',
  correctTxt: 'rätt',
  correctAnswer: 'Facit',
  correctAnswerTxt: 'Rätt svar',
  correctAnswersTxt: 'Rätt svar',
  countryTxt: 'Land',
  create: 'Skapa',
  createAnAccount: 'Skapa konto',
  createFirstAssignmentSuccessDescription: 'Dina elever kan nu räkna i den',
  createFirstAssignmentSuccessTitle: 'Bra jobbat med din övning!',
  createFirstClassDescription:
    'till dina elever när de skapar konto eller skapa elevkonton manuellt. Du kan även maila oss en klasslista så laddar vi upp kontona åt dig.',
  createdAt: 'Skapad',
  createNewAccount1: 'Skapa nytt konto 1/2',
  createNewAccount2: 'Skapa nytt konto 2/2',
  createNewGroup: 'Namnge grupp',
  createTheFirstClassTitle: 'Välkommen till Magma',
  createTheFirstClassDescription: 'Skapa en klass för att komma igång',
  creatingExerciseTitle: 'Vem är övningen till?',
  creatingExercise: 'Skapa övning:',
  disableQrCodeLogin: 'Inaktivera QR-kod inloggning',
  doForAll: (text: string) => `${text} för alla`,
  dontHestitate: 'Tveka inte att kontakta oss om du behöver hjälp.',
  done: 'Gjort',
  download: 'Ladda ner resultat',
  dublicatedEmail: 'Det finns redan en användare med den här mailadressen. Kontakta kundtjänst.',
  duplicateAssignment: 'Kopia av',
  easyLevel: 'Nivå 1',
  edit: 'Redigera',
  editClassNameTxt: 'Ändra övningsnamn',
  editExerciseNameTxt: 'Övningens namn',
  editGroupNameTxt: 'Redigera namn på gruppen',
  editSectionNameTxt: 'Redigera namnet på Delkapitel',
  editExercise: 'Redigera',
  editProblemTxt: 'Ändra uppgift',
  editSectionTxt: 'Ändra namn',
  editFirstName: 'Ändra förnamn',
  editLastName: 'Ändra efternamn',
  editEmail: 'Ändra email',
  editUsername: 'Ändra användarnamn',
  editMyBook: 'Ändra Min bok',
  emailOrUsernameTxt: 'Email eller användarnamn',
  emailTxt: 'Email',
  emptyMyBooksTxt: 'Din boklista är tom',
  emptyUsersList: 'Du har inte tillgång till några elever.',
  emptyUsersSearchList: 'Din sökning gav inget resultat.',
  emptyUserName: 'Tomt användarnamn',
  enableForAllTxt: 'Sätt på för alla',
  enableQrCodeLogin: 'Aktivera QR-kod inloggning',
  endExam: 'Avsluta prov',
  endExamWarning: (isSingleStudent: boolean) =>
    `Om du avslutar provet kommer resultatet delas med ${isSingleStudent ? 'eleven' : 'eleverna'}.`,
  endTxt: 'Avsluta',
  endedTxt: 'Avslutad',
  enteredFractionsFormatTxt: 'skrivs genom att använda formatet',
  enteredExponentialsTxt: 'skrivs som basen följt av',
  enteredMixedFormFormatTxt:
    'skrivs på liknande sätt som bråk. Lägg till den hela delen innan bråket',
  enterNewPass: 'Ange nytt lösenord',
  eraseStudentTxt: 'Radera elevkonto',
  removeStudentTxt: 'Ta bort från klassen',
  errorMessageAnswer: 'Flervalsfrågorna behöver åtminstone 1 alternativ',
  errorMessageAnswerShort: 'Vad är svaret?',
  errorMessageCorrectAnswer: 'Vilket alternativ är korrekt',
  errorMessageQuestion: 'Vad är frågan? Skriv i rutan eller lägg till en bild',
  errorMessageUnfollowClass: 'Du är ägare av klassen, vill du ändå avfölja? Skriv i chatten',
  exampleTxt: 'Exempel',
  exampleProblemTxt: 'Exempel på problem',
  exampleProblemForAbilityTxt: 'Exempelproblem för',
  exam: 'Prov',
  examSettings: 'Provinställningar',
  examEnded: 'Färdiga',
  examMode: 'Provläge',
  exerciseSettings: 'Övningens inställningar',
  exerciseTxt: 'Övning',
  exerciseNameTxt: 'Övningsnamn',
  exerciseArchiveBtn: 'Arkivera',
  exerciseDeleteBtn: 'Radera',
  exercisesTxt: 'Övningar',
  exerciseWasCreatedByAnotherTeacherTxt: 'Kunde inte ta bort övning. Denna övning var skapad av ',
  existedAccount: 'Du har redan ett Skolfederation-konto. Gå till Logga in istället.',
  existingStudent: 'Befintlig elev',
  existingStudents: 'Befintliga elever',
  expandHeatmap: 'Expandera vy',
  exponentialsTxt: 'Potenser',
  dateTxt: 'Datum',
  dearTxt: 'Kära',
  describeError: 'Beskriv felet (valfritt)',
  deletedExerciseTxt: 'Övning raderad',
  deleteProblem: 'Radera uppgift',
  deleteProblemQuestion: 'Är du säker på att du vill radera uppgiften?',
  deleteSection: 'Radera delkapitel',
  deleteSectionQuestion: 'Är du säker på att du vill radera det här delkapitlet?',
  deleteSectionNote: 'Alla uppgifter i delkapitlet kommer också att raderas.',
  deleteStudentNote: 'Är du säker på att du vill ta bort eleven från klassen?',
  deleteClassNote:
    'Eleverna i klassen försvinner och kan inte återskapas. Se till att flytta eleverna till en annan klass ifall de ska fortsätta arbeta.',
  deleteTxt: 'Radera',
  difficultyTxt: 'Svårighetsgrad',
  districtLocked: 'Avslutad',
  districtLockedTooltip: 'Övningen är avslutad av kommunen.',
  removeTxt: 'Ta bort från listan',
  removeFromLibraryTxt: 'Ta bort från bokhylla',
  removeFromListTxt: 'Ta bort',
  duplicateExerciseTxt: 'Duplicera',
  duplicateExerciseNotifyTxt: 'Övning duplicerad',
  emptySubchapterTxt: 'Det finns inga uppgifter i detta delkapitel :/',
  findYourProblemsTxt: 'Hitta dina uppgifter',
  findYourClassTxt: 'Hitta din klass',
  findOrCreateClassTxt: 'Hitta din klass eller skapa en ny',
  fileIsInvalid: 'Filen är ogiltig',
  filter: 'Filter',
  finaliseRegistrationText:
    'Genom att välja din skola kommer du att kunna samarbeta med dina kollegor',
  findAttachedStudentLists:
    'Nu har jag skapat en klasslista i Excel-format som innehåller elevernas Förnamn, Efternamn, Användarnamn och Klasskod (vilket även kommer vara elevernas lösenord). Jag har bifogat denna innan jag skickar iväg detta mail',
  firstNameTxt: 'Förnamn',
  follow: 'Följ',
  followSuccess: 'Du följer nu klassen! Gå till övningar för att komma igång',
  forcedDrawing: 'Kräv uträkning',
  forcedDrawingTooltip:
    'Elever kan inte svara förrän de gjort en uträkning. Gäller bara elever som använder webversionen.',
  forStudentsRegisterTxt: 'till dina elever när de skapar konto eller',
  forbiddenTxt: 'Förbjuden',
  forgotPasswordIntrodution:
    'För att återställa ditt lösenord anger du din email så skickar vi dig instruktioner om hur du skapar nytt lösenord.',
  forgotPasswordTxt: 'Glömt ditt lösenord',
  forIPadOnlyTxt: 'Endast för iPad',
  fractionsTxt: 'Bråkform',
  freeForSomeDays: 'Gratis i 60 dagar!',
  fromDistrict: 'Från kommun',
  fromTxt: 'Från',
  getResetCode: 'Skicka mail',
  getStarted: 'Sätt igång',
  giveCodeToStudentTxt: 'Ge koden',
  giveExerciseToClass: 'Ge övning till klassen',
  googleClassroom: 'Google Classroom',
  goTo: 'Gå till ',
  grade: 'Årskurs',
  gradeChanged: 'Årskurs ändrad',
  gradeZeroValue: 'F',
  graphicCalculator: 'Grafisk',
  graphicalResponseTxt: 'Eleven svarar grafiskt',
  groupAdded: 'Grupp tillagd',
  groupDeleted: 'Grupp raderad',
  groupNameEditedTxt: 'Gruppnamn redigerat',
  groupName: 'Gruppnamn',
  groupSettings: 'Gruppinställningar',
  groupTxt: 'Grupp',
  groupsTxt: 'Grupper',
  handWritingAnswer: 'Svar',
  haveAccess: 'Har åtkomst',
  hardLevel: 'Nivå 3',
  headerForHelpCreatingExerciseModal: 'Tips för att skriva svar',
  heatmapTxt: 'Resultatmatris',
  helpcenterTxt: 'Hjälpcenter',
  helpQueue: 'Hjälpkö',
  hideNamesAndAnswers: 'Dölj namn och facit',
  hiTxt: 'Hej',
  ifYouHaveQuestionsBeforeChatTxt:
    'Om du känner att du har en fråga som inte besvarats så hör av dig via',
  immigrationAgreement: 'användaravtal',
  informationTxt: 'Uppgifter',
  inputCodeIntrodution:
    'Vänligen ange återställningskod och användarnamn som vi skickade till ditt e-postmeddelande för att fortsätta.',
  insertEquation: 'Infoga ekvation',
  internationalContent: 'Internationellt material',
  inTheAnswerBox: 'i svarsrutan',
  invalidEmail: 'Ogiltigt e-postadress',
  invalidSchool: 'Din skola finns inte registrerad. Vänligen kontakta kundtjänst.',
  invalidUsernameOrPassword: 'Ogiltigt användarnamn eller lösenord',
  itIsPublicBook: 'Denna bok går inte att ta bort från din lista',
  isEnteredAsTxt: 'anges som',
  isLoading: 'Laddar...',
  isNotInTheList: 'finns inte i listan',
  kindRegardsTxt: 'Vänliga hälsningar',
  klasslistaSubject: 'Klasslista',
  klasslistaTxt: 'en klasslista som vi laddar upp åt dig',
  languageTxt: 'SE',
  languageListNoResults: 'Din sökning gav inget resultat. Prova ett annat språk',
  languageSupportTxt: 'Språkstöd',
  lastNameTxt: 'Efternamn',
  left: 'Lämnade',
  leftMagma: 'Lämnade Magma',
  leftTheAssignment: 'Lämnade övningen',
  levelTxt: 'Nivå',
  lessInfoTxt: 'Mindre info',
  libraryTxt: 'Bibliotek',
  loadingDataTxt: 'Laddar data ...',
  lockUnlockGradesTxt: 'Tillgängliga årskurser',
  logInTxt: 'Logga in',
  logoutTxt: 'Logga ut',
  goToDistrict: 'Gå till kommun',
  lostConnection1: 'Det verkar som att du',
  lostConnection2: ' förlorat internetanslutningen.',
  manualTxt: 'Manuellt',
  markAsCorrect: 'Markera som rätt',
  markAsInCorrect: 'Markera som fel',
  markAsDoneTxt: 'klarmarkera',
  mediumLevel: 'Nivå 2',
  membersCanTryMatteappenTxt:
    'Just nu får nya medlemmar testa Matteappen gratis i två månader - passa på!',
  messageUsTxt: 'Behöver du hjälp? Skriv i chatten nedan.',
  messageUs: 'Meddela oss i chatten nedan om du saknar något material.',
  minutesAbbreviation: 'min',
  mixedTxt: 'Blandat',
  mixedFormTxt: 'Blandad form',
  moreTxt: 'Visa Mer',
  moreBooks: 'Fler böcker',
  moreClassesTxt: 'Fler klasser...',
  moreInfoTxt: 'Mer info',
  moreThan: 'Mer än',
  moreOptions: 'Fler alternativ',
  mostAnswersTxt: 'Flest svar',
  mostCorrectAnswersTxt: 'Flest rätta svar',
  moveToAnotherClass: 'Byt klass',
  moveToTheBottomTxt: 'Flytta till botten',
  moveToTheTopTxt: 'Flytta till toppen',
  moveDownTxt: 'Flytta ner',
  moveUpTxt: 'Flytta upp',
  multiple: 'Flerval (flera korrekta svar)',
  multipleAnswers: 'Flera svar',
  multipleChoice: 'Flervalsfråga',
  multipleChoiceErrorMessage: 'Du har nått maxlängden',
  multipleTopics: 'Flera kategorier',
  multiSelectAnswer: 'Välj flera',
  districtTxt: 'Kommun',
  mustBeGreatPassword: 'Lösenordet måste vara 6 tecken. Försök igen',
  mustBeSixCode: 'Kod måste vara 6 tecken. Försök igen',
  mustBeLessTwenty: 'Måste vara 20 tecken eller färre',
  mustBeLongerUsername: 'Användarnamn måste vara minst 4 tecken',
  mustBeGreatSchool: 'Mata in 3 eller fler tecken',
  mustMatchEmail: 'måste matcha email',
  myBookTxt: 'Min bok',
  myClassesTxt: 'Följda',
  myListTxt: 'Min Lista',
  myGroupsTxt: 'Mina grupper',
  nameTxt: 'Namn',
  displayName: 'Visningsnamn',
  addName: 'Lägg till namn',
  addDisplayName: 'Lägg till visningsnamn',
  editDisplayName: 'Redigera visningsnamn',
  nameYourNew: 'Namnge',
  networkError: 'Nätverksfel',
  newClassTxt: 'Ny klass',
  newExerciseName: 'Ange ett nytt övningsnamn',
  newExerciseTxt: 'Ny övning',
  newFirstNameTxt: 'Ny förnamn',
  newLastNameTxt: 'Ny efternamn',
  newGroupTxt: 'Ny grupp',
  newGroupWithoutPlus: 'Ny grupp',
  newPassConfirmTxt: 'Repetera lösenord',
  newPassTxt: 'Nytt lösenord',
  newStudent: 'Ny elev',
  newSubchapterTxt: 'Nytt delkapitel',
  newSubchapterChooseNameTxt: 'Namnge delkapitel',
  newSubchapterNameTxt: 'Nytt delkapitel namn',
  nextTxt: 'Nästa',
  noAnswer: 'Inga svar',
  notAnsweredYet: 'Obesvarad',
  noBooksInBookSeries: 'Inga böcker i denna serie',
  noProblemsAddedForLevel: (level?: string) => `Inga uppgifter har lagts till för nivå`,
  noResultsForTxt: 'Inga resultat för',
  noResultsFound: 'Tyvärr hittade vi inga matchningar på din sökning.',
  noClassesFound: 'Hittade ingen klass. Behöver du hjälp? Skriv i chatten nedan.',
  noClassesFoundTxt: 'Du har inga klasser. Skriv i chatten nedan så hjälper vi dig att komma igång',
  noSchoolClassesTxt: 'Det finns inga klasser på din skola',
  noLongerInAssignmentTxt: 'Är inte länge med i övningen.',
  noSchoolGroupsTxt: 'Du har inga grupper',
  noSectionsFound: 'Det finns inga delkapitel i det här kapitlet.',
  noStudentsText: 'Det finns inga elever i den här klassen.',
  noSolutionSubmitted: 'Ingen uträkning',
  noStudentsForHelp: 'Inga elever behöver hjälp just nu',
  noStudentsInGroup: 'Inga studenter hittades i denna grupp',
  noteAboutChangeNameLater:
    'Välj ett meningsfullt namn som representerar innehållet. Du kan alltid ändra namnet senare.',
  noChaptersFound: 'Det finns inga kapitel i den här boken.',
  notFoundAccount: 'Du saknar konto. Skapa konto eller kontakta Matteappens support.',
  noteForCreatingAccountUnderButton:
    'Ingen bindningstid. Du kan när som helst, utan uppsägningstid, avsluta ditt medlemsskap. Du behöver inte uppge några betalningsuppgifter för att skapa ett konto. Om din kommun har avtal med Matteappen så börjar din licens gälla automatiskt när du registrerat dig. ',
  notAnswered: 'Inte besvarad',
  notAnsweredInProgressPage: 'Ej besvarat',
  noProblemsFound: 'Det finns inga uppgifter i det här delkapitlet.',
  noTopicsMessage: 'Du har inga kategorier ännu',
  noTxt: 'Nej',
  numberOfStudents: 'Antal elever',
  numeric: 'Tal',
  off: 'Av',
  option: 'Alternativ',
  optional: 'Valfritt',
  optionsTxt: 'Alternativ',
  orderProblemsOnTxt: 'Ordna uppgifter efter:',
  orderSavedTxt: 'Uppgiftsordningen har sparats',
  other: 'Övrigt',
  othersOptionsTxt: 'Andra alternativ',
  ownedByTxt: 'Ägs av',
  passDontMatch: 'Lösenorden matchar inte',
  passUpdateSuccess: 'Lösenord uppdaterat',
  passwordTxt: 'Lösenord',
  pauseTxt: 'Pausa',
  pausedTxt: 'Pausad',
  percentageOfAnsweredQuestions: 'Andel besvarade uppgifter',
  placeholderCountryTxt: 'Börja skriva landets namn',
  placeholderDistrictTxt: 'Börja skriva kommunnamn',
  placeholderSchoolTxt: 'Börja skriva skolnamn',
  play: 'Spela',
  pleaseSelectBook: 'Välj bok',
  pleaseSelectClass: 'Välj klass/grupp',
  pleaseSelectBookAndClass: 'Välj bok och klass',
  pleaseSelectDateRange: 'Välj ett datumintervall',
  pickAClass: 'Välj en klass',
  pickAName: 'Välj ett namn',
  practiceTxt: 'Träna',
  problemsOfExercise: 'Övningens uppgifter',
  problemTxt: 'Uppgift',
  problemsTxt: 'Uppgifter',
  problemsTxtShort: 'uppg.',
  mailingClassList: 'Maila klasslista',
  mailingList: 'Maila lista',
  mapTxt: 'Karta',
  nameAssignment: 'Namnge övningen',
  ofTxt: 'av',
  orTxt: 'eller',
  schoolClassesTxt: 'Skolans klasser',
  oupsTxt: 'Hoppsan!',
  overview: 'Översikt',
  printExercise: 'Utskrift för övning ',
  printPdfToExercise: 'Jag skulle vilja ha en pdf för utskrift för övning ',
  printExerciseTxt: 'Skriv ut övningen',
  printTxt: 'Skriv ut',
  privateBooksTxt: 'Privata böcker',
  profileTxt: 'Profil',
  publicBooksTxt: 'Offentliga böcker',
  publishExerciseTxt: 'Publiceras',
  publishTxt: 'Publicera',
  publishingTxt: 'Publicering',
  toPublishTxt: 'Publiceras',
  toArchiveTxt: 'Arkiveras',
  pinnedOrderTxt: 'Fastnålad ordning',
  pinnedSolutionTxt: 'Fastnålade uträkningar',
  publishAgainTxt: 'publicera igen',
  publishNowTxt: 'Publicera nu',
  questionTxt: 'Fråga',
  publishedNowTxt: 'Publiceras nu',
  randomizeTxt: 'Blanda',
  randomTxt: 'Slumpmässig',
  removeStudentFromGroup: 'Ta bort från gruppen',
  removeStudentFromClass: 'Ta bort från klassen',
  reorderProblemsTxt: 'Ändra uppgiftsordningen',
  reportQuestion: 'Rapportera frågan',
  deleteClass: 'Radera klass',
  recommendTxt: 'Rekommendera',
  requestAccess: 'Begär åtkomst',
  requestSent: 'Förfrågan skickad',
  requiredFieldText: 'Obligatoriskt fält',
  reportOrdered: 'Rapport beställd',
  readAloudSuccessTxt: 'Uppläsning påslaget för alla elever i klassen',
  readAloudErrorTxt:
    'Det gick inte att aktivera uppläsning. Skriv i chatten nedan så hjälper vi dig.',
  readingTxt: 'Uppläsning',
  readMeAboutCreatingAccount: 'Läs mer om hur du',
  regular: 'Skriv för hand',
  regularCalculator: 'Vanlig',
  republish: 'Fortsätt prov',
  republishExam: 'Fortsätt',
  requestTxt: 'Lägg till',
  reset: 'Återställ',
  resetAllPasswordInClass: 'Återställ alla lösenord',
  resetAllPasswordsQuestion: 'Välj nytt lösenord att ge till klassen',
  resetChange: 'Återställ ändring',
  resetPassIntrodution: 'Vänligen ange nytt lösenord för att fortsätta.',
  resetPassTxt: 'Ändra lösenord',
  resetPasswordsTxt: 'Återställ lösenord',
  resetStudentsPasswordsTxt: 'Återställ elevernas lösenord',
  resultTxt: 'resultat',
  resultsTxt: 'resultat',
  resultButtonText: 'Resultat',
  requireUnit: 'Kräv enhet',
  saveTxt: 'Spara',
  saveOrCancelChangesTxt: 'Spara dina ändringar eller avbryt.',
  createTxt: 'Skapa',
  createYourOwnProblems: 'Skapa dina egna uppgifter',
  scheduleTxt: 'Schemalägg',
  scheduledSettingInfoTxt: 'Övningen publiceras automatiskt',
  scheduledSettingInfoTestModeTxt: 'Provet publiceras och startar automatiskt',
  showStats: 'Visa statistik',
  showTxt: 'Visa',
  showPinned: 'Visa fastnålade',
  schoolTxt: 'Skola',
  schoolYear: 'Läsår',
  scientificCalculator: 'Teknisk',
  scatterplot: 'Spridningsdiagram',
  scatterplotTxt:
    'Den här grafen representerar antalet korrekta svar samt andelen rätt för en övning',
  searchTxt: 'Sök',
  searchClassesTxt: 'Sök klass',
  searchHereExample: 'Sök här, t.ex. "multiplikationstabeller"',
  secondsAbbreviation: 'sek',
  selectAll: 'Välj alla',
  selectClass: 'Välj klass',
  selectClassOrGroup: 'Välj klass/grupp',
  selectLevel: 'Välj nivå',
  selectYourProblemsTxt: 'Välj dina uppgifter',
  selectStudent: 'Välj elev',
  selectEndDate: 'Sluttid',
  selectInOrderAnswer: 'Välj i ordning',
  selectProblems: 'Välj uppgifter',
  selectStartDate: 'Starttid',
  sendClasslist: 'Skicka klasslista',
  sendClasslistInstead: 'Skicka klasslista istället',
  sendClasslistTxtTop: 'Skicka oss en lista med dina elever, så skapar vi konton åt dem!',
  sendClasslistTxtBottom: 'Bifoga listan som en excel-fil och inkludera elevernas',
  sendEmailTxt: 'Skicka e-post',
  sendMeReport: 'Beställ rapport',
  sendMeReportFor: 'Beställ rapport för övning ',
  sendMeReportFullTxt: 'Jag skulle vilja ha en rapport för övningen ',
  sendTxt: 'Skicka',
  selectLanguageTxt: 'Välj språk',
  setExerciseNameTxt: 'Namnge övningen',
  singleSelectAnswer: 'Välj en',
  signInTxt: 'Loggar in',
  signInWithQRCodeTxt: 'Logga in med QR-kod',
  signInAsTeacher: 'Logga in som lärare',
  signUpTxt: 'Skapa konto',
  signUpScolon: 'Logga in med Skolon',
  skillsBtnHint: 'Tryck här för att komma till översiktsvyn för elevernas färdigheter på övningen.',
  skillsPlusBtnHint: 'Tryck här för att skapa en övning med repetitionsmaterial till dina elever.',
  startExam: 'Starta prov',
  startguide: 'Startguide',
  startTxt: 'Starta',
  startedTxt: 'Startad',
  stopExam: 'Stoppa',
  createStudentAccount: 'Skapa elevkonto',
  studentPreview: 'Elevens vy',
  settingsTxt: 'Inställningar',
  showMore: 'Visa mer',
  signFollowedBySquareBrackets: '-tecknet följt av en hakparentes runt potensen',
  signUpTeacherTxt: 'Skapa lärarkonto gratis',
  signUpStudentManually: 'skapa nya elevkonton manuellt',
  singleAnswer: 'Flerval (ett korrekt svar)',
  sortStudents: 'Sortera elever',
  singleStudents: 'Enstaka elever',
  skillsMatrix: 'Områdesmatris',
  skillsInfoTxt:
    'I matteappen är alla uppgifter kategoriserade efter vilken färdighet som uppgiften provar eleven på. I den här vyn har elevens resultat på övningen sammanslagits för att ge en översikt över eventuella kunskapsluckor där du direkt kan skapa extramaterial eller repetitionsuppgifter till dina elever.',

  skillsModalText:
    'Tryck på skapa, så skapas en repetitionsövning för de elever som fått mindre än 60% rätt i färdigheten.',
  solutionsTxt: 'Lösningar',
  specialBook:
    'Du har valt uppgifter som kräver manuell rättning. Läs följande guide innan du börjar:',
  standardAbbreviations: 'Enheter anges enligt vedertagna förkortningar, t.ex. kg, cm, ml',
  startExerciseTxt: 'Ge class',
  statsInfoTxt:
    'Här ser du statistik på dina övningar, klasser och elever. Du kan filtrera vyn genom att trycka på “Välj klass” längst till höger. Det blå fältet visar hur stor andel rätt svar eleven har på övningen. Tryck på en elev för att få mer information.',
  statisticsTxt: 'Statistik',
  studentAddedTxt: 'Elev tillagd',
  studentDeletedTxt: 'Elev borttagen',
  studentTxt: 'Elev',
  studentsTxt: 'Elever',
  studentsQuantityWasChanged: 'Elevernas kvantitet ändrades',
  studentSignInLinkTxt: 'Elevinloggning',
  subchapterAdded: 'Delkapitel tillagd',
  subchapterTxt: 'Delkapitel',
  subchaptersTxt: 'Delkapitel',
  submitTxt: 'Återställ',
  solve: 'Räkna',
  textReplacement: 'Textersättning',
  textSizeTxt: 'Textstorlek',
  translationLanguageTxt: 'Översättningsspråk',
  typeTxt: 'Typ',
  userNameTxt: 'Användarnamn',
  userNotFound: 'Användaren hittades inte',
  userNotTeacher: 'Användare inte en lärare',
  // tslint:disable-next-line:object-literal-sort-keys
  changeClass: 'Byt klass',
  changeClassesNameTxt: 'Ändra klassens namn',
  changeGroupNameTxt: 'Ändra gruppnamn',
  changeStudentUsername: 'Ändra elevens användarnamn',
  changeTopic: 'Ändra kategori',
  changeUsernameTxt: 'Ändra användarnamn',
  removeClassTxt: 'Radera klass',
  removeGroupTxt: 'Radera grupp',
  removeImage: 'Ta bort bild',
  rightAnswerTxt: 'Facit',
  rightAnswerAnyAttempts: 'Rätt flera försök',
  rightAnswerFirstAttempt: 'Rätt direkt',
  selectTxt: 'Välj',
  shareClassroomError: 'Kunde inte dela övning. Försök igen senare!',
  shareClassroomSuccess: "Kopia skapad. Din kollega hittar den under 'Opublicerade'",
  shareClassTxt: 'Dela klass',
  shareGroupTxt: 'Dela grupp',
  shareLinkTxt: 'Dela med elever',
  shareWithTeachers: 'Dela med lärare',
  shareWithTxt: 'Dela med',
  shareLinkTeachersTxt: 'Kopiera till kollega',
  shareLinkTeachersNotifyTxt: 'Övning delad med',
  shareLinkModalBody: 'Dela länken nedan så skickas dina elever direkt in i övningen',
  shareLinkModalFooter:
    'OBS, denna länk fungerar enbart för elever som redan är tilldelade övningen.',
  shareLinkModalTitle: 'Dela övning med elever',
  shareClassMessageTitle: 'Magma - Dela gärna din klass',
  shareClassMessageBody: 'Kan du dela klass',
  shareClassClickLinkTxt: 'Klicka på länken nedan för att dela klassen',
  sharing: 'Dela',
  statusTxt: 'Status',
  studentMovedToAnotherClass: 'Eleven har flyttats till en annan klass',
  studentUsernameEditedTxt: 'Elevens användarnamn redigerades',
  somethingWentWrong: 'Hoppsan… Något gick fel. Försök igen!',
  switchSchool: 'Byt skola',
  taskTxt: 'Uppgift',
  teacherGuide: 'Lärarhandledning',
  teacherNotFound: 'Lärare hittades inte',
  teacherPlatform: 'Lärarplattform',
  teacherTxt: 'Lärare',
  testsTxt: 'Tester',
  teoryTxt: 'Teori',
  timesTxt: 'gånger',
  thankYouShort: 'Tack',
  thankYouTxt: 'Tack för hjälpen',
  thanksForYourFeedback: 'Tack för din feedback. Vi undersöker frågan!',
  theClassCodeTxt: 'Klasskoden',
  thisActionCannotBeUndone:
    'Klassen kommer raderas för samtliga lärare som har tillgång till klassen. Elevkontona raderas också',
  tryAgainTxt: 'Försök igen',
  topicTxt: 'Kategori',
  topicsTxt: 'Kategorier',
  toTxt: 'Till',
  tryToSearchSomethingElse: ':( Försök igen med en annan sökning',
  tryAnotherName: 'Prova ett annat namn',
  tryCreateNewUserTxt: 'Pröva att skapa en ny!',
  uncategorizedTxt: 'Okategoriserad',
  unarchiveClass: 'Aktivera klass',
  unfollow: 'Sluta följa',
  unfollowClass: 'Avfölj klass',
  unfollowClassDescription: 'Är du säker? Du kommer att förlora tillgången till klassen.',
  unfollowTxt: 'Avfölj',
  unfollowedSuccess: 'Slutade följa framgångsrikt',
  unpublishTxt: 'Avpublicera',
  unpublishedTxt: 'Opublicerade',
  unpublishedSingleTxt: 'Opublicerad',
  updateBooksFailTxt: 'Ojdå! Något gick fel. Försök igen',
  updateBooksSuccessTxt: 'Bokordning uppdaterad',
  updateTxt: 'Uppdatera',
  uppgifterAndRanking: 'UPPGIFTER OCH RÄKNING',
  unitTxt: 'Enhet',
  useClassCodeTxt: 'Använd klasskod',
  userExistingWithEmail: 'Användare med det här emailet finns redan',
  incorrectUsername: 'Användarnamnet är inte korrekt',
  incorrectTxt: 'Fel',
  invalidUsername: 'Ogiltigt användarnamn',
  invalidCode: 'Ogiltig kod',
  watched: 'Tittade i facit',
  waitingForAutologin: 'Loggar in...',
  weHaveSentEmailTo: 'Vi har skickat ett mail till',
  weWillSupportAsSoonAsPossible: 'så hjälper vi dig inom ett par minuter',
  whoIsTheAssignmentFor: 'Vem är övningen för?',
  withMeTxt: 'med mig',
  wholeClassTxt: 'Hela klassen',
  withSomeTipsHowToGetStarted: 'med några tips på hur du kommer igång',
  writeYourProblemTxt: 'Skriv din uppgift här',
  writeYourSubProblemTxt: 'Skriv din fråga här...',
  writeYourQuestionTxt: 'Skriv din deluppgift här',
  noAvailable: 'Skapa en övning',
  noArchived: 'Du har inga arkiverade övningar',
  noClassesAddedTxt: 'Du har ingen klass ännu.',
  noCountriesInTheList: 'Inga länder i listan',
  noGroupsAddedTxt:
    'Grupper används för att dela in elever på andra sätt än deras klass. Detta för att du lättare ska kunna anpassa undervisningen.',
  noMunicipalitiesInTheList: 'Inga kommuner i listan',
  noOptionsMessage: 'Inga alternativ',
  noSchoolsInTheList: 'Inga skolor i listan',
  noStudentsInThisAssignmentTxt: 'Det finns inga elever i denna övning',
  noTranslationTxt: 'Inget valt',
  noUnpublished: 'Du har inga opublicerade övningar',
  nowTxt: 'Nu',
  noneTxt: 'Ingen',
  pickClassOrGroupTxt: 'Välj en klass eller grupp',
  pieChart: 'Statistikhjul',
  statsWheel: 'Kunskapshjul',
  standardAndSkill: 'Kunskapsomården',
  multipleStudents: 'Flera elever',
  typeStringToDelete: `Skriv RADERA för att bekräfta`,
  wohoo: 'Wohoo',
  year: 'År',
  yesTxt: 'Ja',
  yetTxt: 'än',
  youAreAllSetToGo: 'Du är redo att sätta igång!',
  youCanAlsoTxt: 'Du kan också',
  youCanChangeStudentsInGroup: 'Du kan när som helst byta vilka elever som skall ingå i gruppen.',
  youCantCreateClass: 'Du följer inga klasser. Behöver du hjälp? Skriv i chatten nedan',
  youHaveNoStudentProgressStats: 'Här kommer du se data när dina elever börjat räkna',
  yourChangesSavedTxt: 'Dina ändringar har sparats',
  yourChangesSavingErrorTxt: 'Dina ändringar sparades inte. Försök igen',
  zeroGradeTxt: 'F',
  today: 'Idag',
  tomorrow: 'Imorgon',
  startDateTxt: 'Startar',
  dueDateTxt: 'Slut',
  scheduleDateError: 'Vänligen välj en sluttid efter starttiden',
  nameSubchapterInputPlaceholder: 'Välj ett meningsfullt namn som representerar innehållet',
  nameSubchapterInputLabel: 'Namnge delkapitel',
  deleteSubchapterModalTitle: 'Radera delkapitel',
  deleteProblemModalTitle: 'Radera uppgift',
  editSubchapterModalTitle: 'Redigera namnet på delkapitel',
  hiddenStudentsNamesTooltip: 'Elevnamnen är gömda när man aktiverar anonymisering.',
  selectGrade: 'Välj en årskurs',
  assignGrade: (classNameValue: string) =>
    `Välj en årskurs till ${classNameValue} för att fortsätta`,
  setGradeErrorNotification: 'Det går inte att spara årskurs',
  setGradeSuccessNotification: 'Årskurs har lagts till',
  groupUpdatedNotificationText: 'Gruppen har blivit uppdaterad',
  reportQuestionModalText:
    'Din feedback går direkt till vårt team på Magma. Vi åtgärdar det så snabbt som möjligt. Tack för att du hjälper oss att bli bättre!',
  printQRCodes: 'Skriv ut QR-koder',
  printList: 'Skriv ut lista',
  layoutTxt: 'Layout',
  sixCodesPerPage: '6 koder per sida',
  oneCodePerPage: '1 kod per sida',
  reportSentPopupText: 'Feedbacken har skickats till vårt team på Magma',
  reportErrorPopupText: 'Ojdå något gick fel! Prova igen.',
  noStudentsForAddingToClass:
    'Det verkar som att det inte finns några studenter kvar som inte redan är i klassen.',
}

export const englishDictionary = {
  add: 'Add',
  activatePracticeModeTxt: 'Activate practice',
  addYourFirstStudent: 'Add your first student',
  acceptTxt: 'I accept the',
  access: 'Access',
  accountTxt: 'Account',
  addAnswer: 'Add answer',
  addClass: 'Add class',
  addExistingStudentTxt: 'Add existing student',
  addImage: 'Add image',
  addMoreBooks: 'Add more books',
  addMoreStudents: 'Add another student',
  addMoreStudentsCta: 'Do you want to add more students?',
  addNewProblemTxt: 'New problem',
  addNewTopic: 'New topic',
  addManuallyTxt: 'Add manually',
  addStudentDirectlyTxt: 'Add student directly',
  addStudentsWithCodeTxt: 'Students can join by entering the class code when signing up',
  addFirstStudent: 'Add a first student',
  algebra: 'Algebra',
  addStudentSuccessTxt: 'Student was added!',
  goToAssignmentTxt: 'Go to assignment',
  goToAssignmentsTxt: 'Go to assignments',
  addStudent: 'Add student',
  addStudentsToGroup: 'Add students to group',
  addStudents: 'Add students',
  addSubproblem: 'Add subproblem',
  addTeachersTxt: 'Add teachers',
  addedBulkExercisesMessage: (classesNumber: number) =>
    `Assignments created for ${classesNumber} classes`,
  addedExerciseTxt: 'Assignment added',
  alertMessageWhenDeleteAClass:
    'If you are sharing a class, the assignment will be deleted for your colleagues as well.',
  allBooks: 'All books',
  allBooksFromSeriesAddedTxt: 'These books are already in your list',
  allExercises: 'All assignments',
  allGradesTxt: 'All grades',
  allPasswordsOfClassWereUpdated: 'All passwords in the class were updated',
  allClassesTxt: 'All classes',
  allGroups: 'All groups',
  allStudentsTxt: 'All students',
  allTxt: 'All',
  allowAnswer: 'Allow looking at answer',
  allowAnswerChecking: 'Allow answer checking',
  alreadyExistEmail: 'This email is already exist or invalid.',
  alreadyExistUsername: 'This username already exists or is invalid',
  alternativeTxt: 'Alternative',
  answerTxt: 'Answer',
  answeredTxt: 'Answered',
  anonymousName: 'Anonymize',
  anonymousCorrectness: 'Hide results',
  applyTxt: 'Apply',
  applyToAllTxt: 'Apply to all',
  archiveExerciseTxt: 'Archived',
  archiveForAll: 'Archive for all',
  archiveTxt: 'Archive',
  archivedSingleTxt: 'Archived',
  archivedTxt: 'Archived',
  archiveClass: 'Archive class',
  archiveClassDescription: 'Are you sure? All teachers will lose access to the class.',
  archiveClassConfirmation:
    'The class will be archived, since you are the only teacher with access to it.',
  archivedClass: 'Archived classes',
  areYouStudent: 'Are you a student?',
  areYouSure: 'Are you sure?',
  askTxt: 'Ask',
  askRequestRestTxt: 'to share this class with you, or send an email by pressing the button below.',
  assessExam: 'Assess',
  assessExamQuestion:
    'When you have handed out result of quiz, ALL students will see their result. Do only this if ALL students have finished the quiz.',
  assignmentNamePlaceholder: 'Give the assignment a name',
  attemptTxt: 'Attempt',
  attemptsTxt: 'Attempts',
  attentionMaxChosenProblems:
    'Hey there! We recommend adding maximum 50 problems to the assignment.',
  availableGradesTxt: 'Available grades',
  availabilityTxt: 'Availability',
  availableSingleTxt: 'Available',
  availableTxt: 'Available',
  // tslint:disable-next-line:object-literal-sort-keys
  availableStatusTxt: 'Available',
  aZText: 'A-z',
  zAText: 'Z-a',
  backTxt: 'Back',
  blockTxt: 'Block',
  bookshelf: 'Bookshelf',
  bookSeriesEmptyPageTxt:
    'There is no specific content for your country. You can still create your own math problems',
  bookTxt: 'Book',
  bookAddedToList: 'added to your list',
  bookBelongsToAnotherTeacher: 'belongs to another teacher',
  bookRemovedFromList: 'removed from your list',
  booksTxt: 'Books',
  calculator: 'Calculator',
  cancelTxt: 'Cancel',
  cancelChangesTxt: 'Cancel',
  cannotDeleteSection: 'Unfortunately you cannot delete this section',
  categoryText: 'Category',
  categories: 'Categories',
  classInfo: 'Class info',
  chapterTxt: 'Chapter',
  contentTitle: 'Here you can browse our books and add them to your list.',
  changeGrade: 'Change grade',
  changesSavedTxt: 'Changes saved',
  cheatDetection: 'Safe mode',
  cheatDetectionDescription:
    'Notifies you when a student leaves the quiz, and your permission is required to let them back in.',
  chooseGrade: 'Choose grade',
  chooseSchoolFromList: 'Choose school from list',
  chooseSingleStudentTxt: 'Choose single students',
  chooseStudents: 'Choose students',
  chosenTxt: 'chosen',
  classAddedTxt: 'Class added',
  classcode: 'Class code',
  classDeletedTxt: 'Class deleted',
  classNameEditedTxt: 'Class name edited',
  classNameTxt: 'Class name',
  createClassNameTxt: 'Name of the class',
  classOwnerTxt: 'Class owner',
  classSettings: 'Class settings',
  classTxt: 'Class',
  classesTxt: 'Classes',
  clearTxt: 'Clear',
  common: 'Regular',
  codeTxt: 'Code',
  colorIndex: 'Color index',
  confirm: 'Confirm',
  contentGuide:
    'Here you can add books to your list. Click on "New assignment" to view problems and hand them out to your students.',
  contentSweden: 'Swedish content',
  contentTxt: 'content',
  contentMaterialTxt: 'Library',
  commonError: 'Common error',
  compressHeatmap: 'Compress view',
  complementaryContentSweden: 'Swedish content re-structured',
  copyCode: 'Copy code',
  copyLink: 'Copy link',
  copy: 'Copy',
  copied: 'Copied',
  correctTxt: 'correct',
  correctAnswer: 'Correct answer',
  correctAnswerTxt: 'Correct answer',
  correctAnswersTxt: 'Correct answers',
  countryTxt: 'Country',
  create: 'Create',
  createAnAccount: 'Create an account',
  createFirstAssignmentSuccessDescription: 'Your students can now access it',
  createFirstAssignmentSuccessTitle: 'Good job creating an assignment!',
  createFirstClassDescription:
    'to your students when they create an account or create student accounts manually. You can also email us a class list and we will upload the accounts for you.',
  createdAt: 'Created at',
  createNewAccount1: 'Create new account 1/2',
  createNewAccount2: 'Create new account 2/2',
  createNewGroup: 'Name your new group',
  createTheFirstClassTitle: 'Welcome to Magma math!',
  createTheFirstClassDescription: 'Create a class to get started',
  createTxt: 'Create',
  createYourOwnProblems: 'Create your own problems',
  creatingExerciseTitle: 'Select students',
  creatingExercise: 'Create assignment:',
  disableQrCodeLogin: 'Disable QR code login',
  doForAll: (text: string) => `${text} for all`,
  done: 'Done',
  dontHestitate: 'Please don’t hesitate to contact us if you need help.',
  download: 'Download results',
  dublicatedEmail: 'Det finns redan en användare med den här mailadressen. Kontakta kundtjänst.',
  duplicateAssignment: 'Copy of',
  easyLevel: 'Mild',
  edit: 'Edit',
  editClassNameTxt: 'Edit name of class',
  editExerciseNameTxt: 'Assignment name',
  editGroupNameTxt: 'Edit name of group',
  editEmail: 'Edit email',
  editFirstName: 'Edit first name',
  editLastName: 'Edit last name',
  editSectionNameTxt: 'Edit name of subchapter',
  editExercise: 'Edit',
  editProblemTxt: 'Edit problem',
  editSectionTxt: 'Edit',
  editUsername: 'Edit username',
  editMyBook: 'Edit My book',
  emailOrUsernameTxt: 'Email or username',
  emailTxt: 'Email',
  emptyMyBooksTxt: 'Your book list is empty',
  emptyUsersList: 'You don’t have access to any students.',
  emptyUsersSearchList: 'Your search returned no results.',
  emptyUserName: 'Tomt användarnamn',
  enableForAllTxt: 'Enable for all',
  enableQrCodeLogin: 'Enable QR code login',
  endExam: 'End quiz',
  endExamWarning: (isSingleStudent: boolean) =>
    `If you end the quiz, the results will be shared with the ${
      isSingleStudent ? 'student' : 'students'
    }.`,
  endTxt: 'End',
  endedTxt: 'Ended',
  enteredFractionsFormatTxt: 'are entered by using the format',
  enteredExponentialsTxt: 'are entered by typing the base followed by the',
  enteredMixedFormFormatTxt:
    'is entered similar to fractions. Enter the whole part(s) before the fraction',
  enterNewPass: 'Enter new password',
  eraseStudentTxt: 'Delete student',
  removeStudentTxt: 'Remove student',
  errorMessageAnswer: 'Multiple choice requires at least 1 options',
  errorMessageAnswerShort: 'What is the answer?',
  errorMessageCorrectAnswer: 'Please select the correct answer',
  errorMessageQuestion: 'What is the question? Please write or add an image',
  errorMessageUnfollowClass:
    'You are owner of the class, if you want to unfollow, write in the support chat',
  exam: 'Quiz',
  examSettings: 'Quiz settings',
  examEnded: 'Finished',
  examMode: 'Quiz mode',
  exampleTxt: 'Example',
  exampleProblemTxt: 'Example problem',
  exampleProblemForAbilityTxt: 'Sample problems for',
  exerciseSettings: 'Assignment settings',
  exerciseTxt: 'Assignment',
  exerciseNameTxt: 'Assignment name',
  exercisesTxt: 'Assignments',
  exerciseArchiveBtn: 'Archive',
  exerciseDeleteBtn: 'Delete',
  exerciseWasCreatedByAnotherTeacherTxt: 'Could not delete assignment. This was created by ',
  existedAccount: 'Du har redan ett Skolfederation-konto. Gå till Logga in istället.',
  existingStudent: 'Existing student',
  existingStudents: 'Existing students',
  expandHeatmap: 'Expand view',
  exponentialsTxt: 'Exponentials',
  dateTxt: 'Date',
  dearTxt: 'Dear',
  describeError: 'Whats wrong? (optional)',
  deletedExerciseTxt: 'Assignment deleted',
  deleteProblem: 'Delete problem',
  deleteProblemQuestion: 'Are you sure you want to delete this problem?',
  deleteSection: 'Delete',
  deleteSectionQuestion: 'Are you sure you want to delete this section?',
  deleteSectionNote: 'All problems in this section will also be deleted.',
  deleteStudentNote: 'Are you sure that you want to remove the student from this class?',
  deleteClassNote:
    'Student accounts will be deleted and they can not log in. Make sure to move students to another class if they should continue to work. ',
  deleteTxt: 'Delete',
  difficultyTxt: 'Difficulty',
  districtLocked: 'Ended',
  districtLockedTooltip: 'The assignment is ended by the district.',
  removeTxt: 'Remove',
  removeFromLibraryTxt: 'Remove from bookshelf',
  removeFromListTxt: 'Remove from list',
  duplicateExerciseTxt: 'Duplicate',
  duplicateExerciseNotifyTxt: 'Assignment duplicated',
  emptySubchapterTxt: 'There are no problems in this subchapter :/',
  findYourProblemsTxt: 'Find your problems',
  findYourClassTxt: 'Find your class',
  findOrCreateClassTxt: 'Find your class or create a new one',
  fileIsInvalid: 'The file is invalid',
  filter: 'Filter',
  finaliseRegistrationText:
    'By selecting your school, you will be able to collaborate with your colleagues',
  findAttachedStudentLists: 'Please find attached student lists',
  firstNameTxt: 'First name',
  follow: 'Follow',
  followSuccess: 'Class followed! Go to assignments to get started',
  forcedDrawing: 'Show work',
  forcedDrawingTooltip:
    'Students can not submit answer before showing their work on selected problems. Only applicable to students working on webb version.',
  forStudentsRegisterTxt: 'to the students, or register him yourself',
  forbiddenTxt: 'Forbidden',
  forgotPasswordIntrodution:
    "To reset password enter your email and we'll send you instructions on how to create new password",
  forgotPasswordTxt: 'Forgot password',
  forIPadOnlyTxt: 'For iPad only',
  fractionsTxt: 'Fractions',
  freeForSomeDays: 'Free for the first 60 days!',
  fromDistrict: 'From district',
  fromTxt: 'From',
  getResetCode: 'Get reset code',
  getStarted: 'Get started',
  giveCodeToStudentTxt: 'Give the code',
  giveExerciseToClass: 'Hand out assignment to class',
  googleClassroom: 'Google Classroom',
  goTo: 'Go to ',
  grade: 'Grade',
  gradeChanged: 'Grade changed',
  gradeZeroValue: 'K',
  graphicCalculator: 'Graphing',
  graphicalResponseTxt: 'Graphical response',
  groupAdded: 'Group added',
  groupDeleted: 'Group deleted',
  groupNameEditedTxt: 'Group name edited',
  groupName: 'Group name',
  groupSettings: 'Group settings',
  groupTxt: 'Group',
  groupsTxt: 'Groups',
  handWritingAnswer: 'Answer',
  haveAccess: 'Have access',
  hardLevel: 'Spicy',
  headerForHelpCreatingExerciseModal: 'Tips for entering answer',
  heatmapTxt: 'Heatmap',
  helpcenterTxt: 'Help center',
  helpQueue: 'Help queue',
  hideNamesAndAnswers: 'Hide name and correctness',
  hiTxt: 'Hi',
  ifYouHaveQuestionsBeforeChatTxt: 'If you have any questions, don’t hesitate to reach out via',
  immigrationAgreement: 'terms and conditions',
  informationTxt: 'Information',
  inputCodeIntrodution: 'Please enter reset code and username we sent to your email to continue.',
  insertEquation: 'Insert equation',
  internationalContent: 'International content',
  invalidEmail: 'Invalid email address',
  invalidSchool: 'Din skola finns inte registrerad. Vänligen kontakta kundtjänst.',
  invalidUsernameOrPassword: 'Invalid username or password',
  inTheAnswerBox: 'in the answer box',
  itIsPublicBook: 'This book can not be removed from your list',
  isEnteredAsTxt: 'is entered as',
  isLoading: 'Loading...',
  kindRegardsTxt: 'Kind regards',
  isNotInTheList: 'is not in the list',
  klasslistaSubject: 'Student list',
  klasslistaTxt: 'us a list of students that we upload for you',
  languageTxt: 'US',
  languageListNoResults: 'Your search returned no results. Try another language',
  languageSupportTxt: 'Language support',
  lastNameTxt: 'Last name',
  left: 'Left',
  leftMagma: 'Left Magma',
  leftTheAssignment: 'Left the assignment',
  levelTxt: 'Level',
  lessInfoTxt: 'Less info',
  libraryTxt: 'Library',
  loadingDataTxt: 'Loading data ...',
  lockUnlockGradesTxt: 'lock/unlock grades',
  logInTxt: 'Log in',
  logoutTxt: 'Sign out',
  goToDistrict: 'Go to district',
  lostConnection1: 'It seems like you have lost',
  lostConnection2: 'your internet connection.',
  manualTxt: 'Manual',
  markAsCorrect: 'Mark as correct',
  markAsInCorrect: 'Mark as incorrect',
  markAsDoneTxt: 'mark as done',
  mediumLevel: 'Medium',
  membersCanTryMatteappenTxt:
    "Right now, new users get to try out our service for free for 60 days - don't miss out on this amazing offer!",
  messageUsTxt: 'Need help? Message us in the chat below.',
  messageUs: 'Message us in the chat below to request specific content.',
  minutesAbbreviation: 'min',
  mixedTxt: 'Mixed',
  mixedFormTxt: 'Mixed form',
  moreTxt: 'More',
  moreBooks: 'More books',
  moreClassesTxt: 'More classes...',
  moreInfoTxt: 'More info',
  moreThan: 'More than',
  moreOptions: 'More options',
  mostAnswersTxt: 'Most answers',
  mostCorrectAnswersTxt: 'Most correct answers',
  moveToAnotherClass: 'Move to another class',
  moveToTheBottomTxt: 'Move to bottom',
  moveToTheTopTxt: 'Move to top',
  moveDownTxt: 'Move down',
  moveUpTxt: 'Move up',
  multiple: 'Multiple choice (multiple correct answers)',
  multipleAnswers: 'Multiple answers',
  multipleChoice: 'Multiple choice',
  multipleChoiceErrorMessage: 'You have reached the max length',
  multipleTopics: 'Multiple topics',
  multiSelectAnswer: 'Select several',
  districtTxt: 'District',
  mustBeGreatPassword: 'Password must be 6 characters',
  mustBeSixCode: 'Code must be 6 characters',
  mustBeLessTwenty: 'Must be 20 characters or less',
  mustBeLongerUsername: 'Username needs to be at least 4 characters',
  mustBeGreatSchool: 'Input 3 characters or more',
  mustMatchEmail: 'must match email',
  myBookTxt: 'My book',
  myClassesTxt: 'Followed',
  myListTxt: 'My List',
  myGroupsTxt: 'My groups',
  nameTxt: 'Name',
  displayName: 'Display name',
  addName: 'Add name',
  addDisplayName: 'Add display name',
  editDisplayName: 'Edit display name',
  nameYourNew: 'Name your new',
  networkError: 'Network error',
  newClassTxt: 'New class',
  newExerciseName: 'Enter a new assignment name',
  newExerciseTxt: 'New assignment',
  newFirstNameTxt: 'New first name',
  newLastNameTxt: 'New last name',
  newGroupTxt: '+ New group',
  newGroupWithoutPlus: 'New group',
  newPassConfirmTxt: 'New password confirmation',
  newPassTxt: 'New password',
  newStudent: 'New student',
  newSubchapterTxt: 'New subchapter',
  newSubchapterChooseNameTxt: 'Name subchapter',
  newSubchapterNameTxt: 'New subchapter name',
  nextTxt: 'Next',
  noAnswer: 'No answers',
  noBooksInBookSeries: 'No books in this series',
  noChaptersFound: 'There are no chapters in this book.',
  notFoundAccount: 'You are missing an account. Create an account or contact Magma Math support.',
  noProblemsAddedForLevel: (level?: string) => `No ${level ? `${level} ` : ''}problems added`,
  noResultsForTxt: 'No results for',
  noClassesFound: 'No class found. Need help? Message us in the chat below.',
  noClassesFoundTxt:
    "You have no classes. Message us in the chat below and we'll help you get started",
  noLongerInAssignmentTxt: 'No longer in assignment.',
  noSchoolClassesTxt: 'There are no classes at your school',
  noSchoolGroupsTxt: 'You have no groups',
  noSectionsFound: 'There are no subchapters in this chapter.',
  noStudentsText: 'There are no students in this class.',
  noSolutionSubmitted: 'No calculations',
  noStudentsForHelp: 'No students waiting for help',
  noStudentsInGroup: 'No students found in this group',
  noteAboutChangeNameLater:
    'Choose a meaningful name that represents the content. You can always change the name later.',
  noteForCreatingAccountUnderButton:
    "It's free to create an account and we will not ask you for any payment details until you have tried our service. You can end your subscription at any time.",
  notAnswered: 'Not answered',
  notAnsweredInProgressPage: 'Not answered',
  notAnsweredYet: 'No answer yet',
  noProblemsFound: 'There are no problems in this subchapter.',
  noTopicsMessage: 'You have no topics now',
  noTxt: 'No',
  numberOfStudents: 'Number of students',
  optionsTxt: 'Options',
  numeric: 'Numeric',
  off: 'Off',
  option: 'Option',
  optional: 'Optional',
  orderProblemsOnTxt: 'Order problems on:',
  orderSavedTxt: 'The order of problems has successfully been saved',
  other: 'Other',
  othersOptionsTxt: 'Others options',
  ownedByTxt: 'Owned by',
  passDontMatch: "Passwords don't match",
  passUpdateSuccess: 'Password update successfully',
  passwordTxt: 'Password',
  pauseTxt: 'Pause',
  pausedTxt: 'Paused',
  percentageOfAnsweredQuestions: 'Percentage of answered questions',
  placeholderCountryTxt: 'Start typing country name',
  placeholderDistrictTxt: 'Start typing district name',
  placeholderSchoolTxt: 'Start typing school name',
  play: 'Play',
  pickAClass: 'Pick a class',
  pickAName: 'Pick a name',
  problemsOfExercise: 'Problems of assignment',
  problemTxt: 'Problem',
  problemsTxt: 'Problems',
  problemsTxtShort: 'prob.',
  pleaseSelectBook: 'Please select book source',
  pleaseSelectClass: 'Please select a class/group',
  pleaseSelectBookAndClass: 'Please select book source and class',
  pleaseSelectDateRange: 'Please select a date range',
  practiceTxt: 'Practice',
  mailingClassList: 'Email class list',
  mailingList: 'Email list',
  mapTxt: 'Map',
  nameAssignment: 'Name assignment',
  ofTxt: 'of',
  orTxt: 'or',
  schoolClassesTxt: 'School classes',
  oupsTxt: 'Oups!',
  overview: 'Overview',
  printExercise: 'Print assignment ',
  printPdfToExercise: 'Please send me a pdf to print for assignment ',
  printExerciseTxt: 'Print',
  printTxt: 'Print',
  privateBooksTxt: 'Private books',
  profileTxt: 'Profile',
  publicBooksTxt: 'Public books',
  publishExerciseTxt: 'Published',
  publishTxt: 'Publish',
  publishingTxt: 'Publishing',
  toPublishTxt: 'Publish',
  toArchiveTxt: 'Archive',
  pinnedOrderTxt: 'Pinned order',
  pinnedSolutionTxt: 'Pinned solutions',
  publishAgainTxt: 'publicise again',
  publishNowTxt: 'Publish now',
  publishedNowTxt: 'Published now',
  questionTxt: 'Question',
  randomizeTxt: 'Randomize',
  randomTxt: 'Random',
  readAloudSuccessTxt: 'Text-to-speech enabled for all students in the class',
  readAloudErrorTxt:
    'Could not activate text-to-speech. Message us in the chat below and we will assist you.',
  readingTxt: 'Text-to-speech',
  readMeAboutCreatingAccount: 'Read more about how you',
  regular: 'Write by hand',
  regularCalculator: 'Basic',
  deleteClass: 'Delete class',
  recommendTxt: 'Recommend',
  removeStudentFromGroup: 'Remove from group',
  removeStudentFromClass: 'Remove from class',
  reorderProblemsTxt: 'Reorder problems',
  reportQuestion: 'Report the question',
  requestAccess: 'Request access',
  requestSent: 'Request sent',
  requiredFieldText: 'Required field',
  requestTxt: 'Request',
  reportOrdered: 'Report ordered',
  reset: 'Reset',
  resetAllPasswordInClass: 'Reset all passwords',
  resetAllPasswordsQuestion: 'Choose new password for the whole class',
  resetChange: 'Reset change',
  resetPassIntrodution: 'Please enter new password to continue.',
  resetPassTxt: 'Reset password',
  resetPasswordsTxt: 'Reset passwords',
  resetStudentsPasswordsTxt: 'Reset students passwords',
  resultTxt: 'result',
  resultsTxt: 'results',
  resultButtonText: 'Result',
  requireUnit: 'Require unit',
  rightAnswerTxt: 'Right answer',
  rightAnswerAnyAttempts: 'Multiple attempts',
  rightAnswerFirstAttempt: 'One attempt',
  selectTxt: 'Select',
  saveTxt: 'Save',
  saveOrCancelChangesTxt: 'Please save or cancel your changes.',
  scatterplot: 'Scatterplot',
  scatterplotTxt:
    'This graph represents number of correct answers and share of correct answers for the assignment',
  scheduleTxt: 'Schedule',
  scheduledSettingInfoTxt: 'The assignment will be published automatically',
  scheduledSettingInfoTestModeTxt: 'The quiz will be published and start automatically',
  showStats: 'Show stats',
  showTxt: 'Show',
  showPinned: 'Show pinned',
  schoolTxt: 'School',
  schoolYear: 'Year',
  scientificCalculator: 'Scientific',
  searchTxt: 'Search',
  searchClassesTxt: 'Search for class',
  searchHereExample: 'Search here. E.g. "multiplication"',
  secondsAbbreviation: 'sec',
  selectAll: 'Select all',
  selectClass: 'Select class',
  selectClassOrGroup: 'Select class/group',
  selectLevel: 'Select level',
  selectEndDate: 'Select end date',
  selectInOrderAnswer: 'Select in order',
  selectProblems: 'Select problems',
  selectStartDate: 'Select start date',
  selectStudent: 'Select student',
  selectYourProblemsTxt: 'Select your problems',
  sendClasslist: 'Send classlist',
  sendClasslistInstead: 'Send classlist instead',
  sendClasslistTxtTop: 'Send us a list of your students, and we create their accounts!',
  sendClasslistTxtBottom: ' In the email, please attach an excel file with their',
  sendEmailTxt: 'Send email',
  sendMeReport: 'Send me a report',
  sendMeReportFor: 'Send me a report for ',
  sendMeReportFullTxt: 'Please send me a report for assignment ',
  sendTxt: 'Send',
  selectLanguageTxt: 'Select language',
  setExerciseNameTxt: 'Please set name of the assignment',
  singleSelectAnswer: 'Select one',
  signInAsTeacher: 'Sign in as teacher',
  signInTxt: 'Sign in',
  signInWithQRCodeTxt: 'Sign in with QR code',
  signUpTxt: 'Sign up',
  signUpScolon: 'Log in with Scolon',
  skillsBtnHint:
    'Click here to get an overview of the students results for different skills in the assignment.',
  skillsPlusBtnHint:
    'Click here to create an assignment with rehearsal materials for your students.',
  createStudentAccount: 'Create student account',
  showMore: 'Show more',
  signFollowedBySquareBrackets: '-sign, followed by square brackets surrounding the exponent',
  signUpTeacherTxt: 'Create free teacher account',
  // tslint:disable-next-line:object-literal-sort-keys
  signUpStudentManually: 'Sign up student manually',
  singleAnswer: 'Multiple choice (one correct answer)',
  singleStudents: 'Single students',
  sortStudents: 'Sort students',
  skillsMatrix: 'Skills matrix',
  skillsInfoTxt:
    "In the math app, all tasks are categorized according to the skill that the task the student tests. In this view, the student's results on the assignment have been combined to provide an overview of any knowledge gaps where you can directly create extra material or rehearsal assignments for your students.",
  skillsModalText:
    'Press create, to hand out a repetition assignment to all students who got less than 60% correct in the given skill.',
  solutionsTxt: 'Solutions',
  specialBook:
    'You have chosen problems that require manual marking. Read the guide before you start here:',
  standardAbbreviations:
    'Units are entered accordsolutionsTxting to standard abbreviations, e.g. kg, cm, ml, yd',
  startExam: 'Start quiz',
  startguide: 'Startguide',
  startTxt: 'Start',
  startedTxt: 'Started',
  startExerciseTxt: 'Start assignment for',
  statsInfoTxt:
    'Here’s a statistical overview of your students answer rate. Filter by class or group by pressing “Select class”. The percentage shows the correct answers from a student. Press on a student to get more info.',
  statisticsTxt: 'Stats',
  studentPreview: 'Student preview',
  settingsTxt: 'Settings',
  stopExam: 'Stop',
  studentAddedTxt: 'Student added',
  studentDeletedTxt: 'Student removed',
  studentTxt: 'Student',
  studentsTxt: 'Students',
  studentsQuantityWasChanged: 'Students quantity was changed',
  studentSignInLinkTxt: 'Student login',
  subchapterAdded: 'Subchapter added',
  subchapterTxt: 'Subchapter',
  subchaptersTxt: 'Subchapters',
  submitTxt: 'Submit',
  taskTxt: 'Task',
  teacherGuide: 'Teacher guide',
  teacherNotFound: 'Teacher not found',
  teacherPlatform: 'Teacher platform',
  teacherTxt: 'Teacher',
  testsTxt: 'Tests',
  teoryTxt: 'Theory',
  timesTxt: 'times',
  thankYouShort: 'Thank you',
  thankYouTxt: 'Thank you',
  thanksForYourFeedback: 'Thanks for your feedback. We will look into it!',
  theClassCodeTxt: 'The class code',
  thisActionCannotBeUndone: 'This action cannot be undone',
  tryAgainTxt: 'Try again',
  tryToSearchSomethingElse: ':( Try searching for something else.',
  tryAnotherName: 'Try another name',
  tryCreateNewUserTxt: 'Try creating a new one!',
  topicTxt: 'Topic',
  topicsTxt: 'Topics',
  toTxt: 'To',
  solve: 'Solve',
  textReplacement: 'Text replacement',
  textSizeTxt: 'Text size',
  translationLanguageTxt: 'Translation language',
  typeTxt: 'Type',
  userNameTxt: 'Username',
  userNotFound: 'User not found',
  userNotTeacher: 'User not a teacher',
  changeClass: 'Change class',
  // tslint:disable-next-line:object-literal-sort-keys
  changeClassesNameTxt: 'Change class name',
  changeGroupNameTxt: 'Change group name',
  changeStudentUsername: 'Change student username',
  changeTopic: 'Change topic',
  changeUsernameTxt: 'Change username',
  removeClassTxt: 'Remove class',
  removeGroupTxt: 'Remove group',
  removeImage: 'Remove image',
  republish: 'Re-open',
  republishExam: 'Re-open',
  shareClassroomError: 'Could not share the assignment. Please try again later.',
  shareClassroomSuccess: "Copy created. Your colleague will find it in 'Unpublished'",
  shareClassTxt: 'Share class',
  shareGroupTxt: 'Share group',
  shareLinkTxt: 'Share with students',
  shareWithTxt: 'Share with',
  shareWithTeachers: 'Share with teachers',
  shareLinkTeachersTxt: 'Copy to colleague',
  shareLinkTeachersNotifyTxt: 'Assignment shared with',
  shareLinkModalBody:
    'When the students click the link below, they will be sent straight into the assignment.',
  shareLinkModalFooter:
    'Note that this link will only work for students who were assigned the specific assignment.',
  shareLinkModalTitle: 'Share the assignment with students',
  shareClassMessageTitle: 'Magma - Share your class please',
  shareClassMessageBody: 'Can you please share your class',
  shareClassClickLinkTxt: 'Click the link below to share your class',
  sharing: 'Sharing',
  statusTxt: 'Status',
  studentMovedToAnotherClass: 'Student has been moved to a different class',
  studentUsernameEditedTxt: 'Student username was edited',
  somethingWentWrong: 'Oups… something went wrong. Please try again!',
  switchSchool: 'Switch school',
  typeStringToDelete: `Type DELETE to confirm`,
  unarchiveClass: 'Unarchive class',
  uncategorizedTxt: 'Uncategorized',
  unfollow: 'Unfollow',
  unfollowClass: 'Unfollow class',
  unfollowClassDescription: 'Are you sure? You will lose access to the class.',
  unfollowTxt: 'Unfollow',
  unfollowedSuccess: 'Unfollowed successfully',
  unpublishTxt: 'Unpublish',
  unpublishedTxt: 'Unpublished',
  unpublishedSingleTxt: 'Unpublished',
  updateBooksFailTxt: 'Ops! Something went wrong. Try again',
  updateBooksSuccessTxt: 'Bookshelf updated',
  updateTxt: 'Update',
  uppgifterAndRanking: 'Tasks and rankings',
  unitTxt: 'Unit',
  useClassCodeTxt: 'Use class code',
  userExistingWithEmail: 'User with this email already exists',
  incorrectUsername: 'Username is not correct',
  incorrectTxt: 'Incorrect',
  invalidUsername: 'Invalid username',
  invalidCode: 'Invalid code',
  waitingForAutologin: 'Signing in...',
  watched: 'Looked at the answer',
  weHaveSentEmailTo: 'We have sent an email to',
  weWillSupportAsSoonAsPossible: 'we will support you as soon as possible',
  whoIsTheAssignmentFor: 'Who is the assignment for?',
  wholeClassTxt: 'Whole class',
  withSomeTipsHowToGetStarted: 'with some tips how to get started',
  withMeTxt: 'with me',
  writeYourProblemTxt: 'Write your problem here',
  writeYourSubProblemTxt: 'Write your subproblem here',
  writeYourQuestionTxt: 'Write your question here...',
  noAvailable: 'Create an assignment',
  noArchived: 'You have no archived assignments',
  noClassesAddedTxt: "You don't have any class yet.",
  noCountriesInTheList: 'No countries in the list',
  noGroupsAddedTxt:
    'Groups are used to divide students in ways other than their class. This is to make it easier for you to personalize the learning experience for each of your students.',
  noMunicipalitiesInTheList: 'No districts in the list',
  noOptionsMessage: 'No options',
  noSchoolsInTheList: 'No schools in the list',
  noStudentsInThisAssignmentTxt: 'There are no students in this assignment',
  noTranslationTxt: 'Nothing selected',
  noUnpublished: 'You have no unpublished assignments',
  noResultsFound: "We're sorry, but we couldn't find any results.",
  nowTxt: 'Now',
  noneTxt: 'None',
  pickClassOrGroupTxt: 'Pick a class or group',
  pieChart: 'Stats wheel',
  statsWheel: 'Stats wheel',
  standardAndSkill: 'Standard and skill',
  multipleStudents: 'Multiple students',
  wohoo: 'Yay',
  year: 'Year',
  yesTxt: 'Yes',
  yetTxt: 'yet',
  youAreAllSetToGo: 'You are all set to go!',
  youCanAlsoTxt: 'You can also',
  youCanChangeStudentsInGroup:
    'You can change which students are to be included in the group at any time.',
  youCantCreateClass: 'You are not following any class. Need help? Message us in the chat below',
  youHaveNoStudentProgressStats: 'Data will be shown when students have started working',
  yourChangesSavedTxt: 'Your changes have been saved',
  yourChangesSavingErrorTxt: 'Your changes were not saved. Please try again.',
  zeroGradeTxt: 'K',
  today: 'Today',
  tomorrow: 'Tomorrow',
  startDateTxt: 'Starts',
  dueDateTxt: 'Due',
  scheduleDateError: 'Please select a due time after the start time',
  nameSubchapterInputPlaceholder: 'Choose a name that represents the content',
  nameSubchapterInputLabel: 'Subchapter name',
  deleteSubchapterModalTitle: 'Delete subchapter',
  deleteProblemModalTitle: 'Delete problem',
  editSubchapterModalTitle: 'Edit name of subchapter',
  hiddenStudentsNamesTooltip: 'Student names are hidden when you use the anonymizing feature.',
  selectGrade: 'Select a Grade',
  assignGrade: (classNameValue: string) => `Assign ${classNameValue} a grade to continue`,
  setGradeErrorNotification: 'Unable to save grade to class',
  setGradeSuccessNotification: 'Grade was added to class',
  groupUpdatedNotificationText: 'The group has successfully been updated',
  printQRCodes: 'Print QR-codes',
  printList: 'Print list',
  layoutTxt: 'Layout',
  sixCodesPerPage: '6 codes per page',
  oneCodePerPage: '1 code per page',
  reportQuestionModalText: `Your feedback goes straight to our team at Magma. We’ll fix it as quickly as possible. Thanks for helping us improve!`,
  reportSentPopupText: 'The report has successfully been sent to our team at Magma',
  reportErrorPopupText: 'Oh no something went wrong! Try again.',
  noStudentsForAddingToClass:
    'Looks like there aren’t any students left who aren’t already in the class.',
}

export const UKDictionary: ILocalization['data'] = {
  add: 'Add',
  activatePracticeModeTxt: 'Activate practice',
  addYourFirstStudent: 'Add your first pupil',
  acceptTxt: 'I accept the',
  access: 'Access',
  accountTxt: 'Account',
  addAnswer: 'Add answer',
  addClass: 'Add class',
  addExistingStudentTxt: 'Add existing pupil',
  addImage: 'Add image',
  addMoreBooks: 'Add more books',
  addMoreStudents: 'Add another pupil',
  addMoreStudentsCta: 'Do you want to add more pupils?',
  addNewProblemTxt: 'New problem',
  addNewTopic: 'New topic',
  addManuallyTxt: 'Add manually',
  addStudentDirectlyTxt: 'Add pupil directly',
  addStudentsWithCodeTxt: 'Pupils can join by entering the class code when signing up',
  addFirstStudent: 'Add a first pupil',
  algebra: 'Algebra',
  addStudentSuccessTxt: 'Pupil was added!',
  goToAssignmentTxt: 'Go to assignment',
  goToAssignmentsTxt: 'Go to assignments',
  addStudent: 'Add pupil',
  addStudentsToGroup: 'Add pupils to group',
  addStudents: 'Add pupils',
  addSubproblem: 'Add subproblem',
  addTeachersTxt: 'Add teachers',
  addedBulkExercisesMessage: (classesNumber: number) =>
    `Assignments created for ${classesNumber} classes`,
  addedExerciseTxt: 'Assignment added',
  alertMessageWhenDeleteAClass:
    'If you are sharing a class, the assignment will be deleted for your colleagues as well.',
  allBooks: 'All books',
  allBooksFromSeriesAddedTxt: 'These books are already in your list',
  allExercises: 'All assignments',
  allGradesTxt: 'All years',
  allPasswordsOfClassWereUpdated: 'All passwords in the class were updated',
  allClassesTxt: 'All classes',
  allGroups: 'All groups',
  allStudentsTxt: 'All pupils',
  allTxt: 'All',
  allowAnswer: 'Allow looking at answer',
  allowAnswerChecking: 'Allow answer checking',
  alreadyExistEmail: 'This email is already exist or invalid.',
  alreadyExistUsername: 'This username already exists or is invalid',
  alternativeTxt: 'Alternative',
  answerTxt: 'Answer',
  answeredTxt: 'Answered',
  anonymousName: 'Anonymize',
  anonymousCorrectness: 'Hide results',
  applyTxt: 'Apply',
  applyToAllTxt: 'Apply to all',
  archiveExerciseTxt: 'Archived',
  archiveForAll: 'Archive for all',
  archiveTxt: 'Archive',
  archivedSingleTxt: 'Archived',
  archivedTxt: 'Archived',
  archiveClass: 'Archive class',
  archiveClassDescription: 'Are you sure? All teachers will lose access to the class.',
  archiveClassConfirmation:
    'The class will be archived, since you are the only teacher with access to it.',
  archivedClass: 'Archived classes',
  areYouStudent: 'Are you a pupil?',
  areYouSure: 'Are you sure?',
  askTxt: 'Ask',
  askRequestRestTxt: 'to share this class with you, or send an email by pressing the button below.',
  assessExam: 'Assess',
  assessExamQuestion:
    'When you have handed out result of quiz, ALL pupils will see their result. Do only this if ALL pupils have finished the quiz.',
  assignmentNamePlaceholder: 'Give the assignment a name',
  attemptTxt: 'Attempt',
  attemptsTxt: 'Attempts',
  attentionMaxChosenProblems:
    'Hey there! We recommend adding maximum 50 problems to the assignment',
  availableGradesTxt: 'Available years',
  availabilityTxt: 'Availability',
  availableSingleTxt: 'Available',
  availableTxt: 'Available',
  // tslint:disable-next-line:object-literal-sort-keys
  availableStatusTxt: 'Available',
  aZText: 'A-z',
  zAText: 'Z-a',
  backTxt: 'Back',
  blockTxt: 'Block',
  bookshelf: 'Bookshelf',
  bookSeriesEmptyPageTxt:
    'There is no specific content for your country. You can still create your own math problems',
  bookTxt: 'Book',
  bookAddedToList: 'added to your list',
  bookBelongsToAnotherTeacher: 'belongs to another teacher',
  bookRemovedFromList: 'removed from your list',
  booksTxt: 'Books',
  calculator: 'Calculator',
  cancelTxt: 'Cancel',
  cancelChangesTxt: 'Cancel',
  cannotDeleteSection: 'Unfortunately you cannot delete this section',
  categoryText: 'Category',
  categories: 'Categories',
  classInfo: 'Class info',
  chapterTxt: 'Chapter',
  contentTitle: 'Here you can browse our books and add them to your list.',
  changeGrade: 'Change year',
  changesSavedTxt: 'Changes saved',
  cheatDetection: 'Safe mode',
  cheatDetectionDescription:
    'Notifies you when a pupil leaves the quiz, and your permission is required to let them back in.',
  chooseGrade: 'Choose year',
  chooseSchoolFromList: 'Choose school from list',
  chooseSingleStudentTxt: 'Choose single pupils',
  chooseStudents: 'Choose pupils',
  chosenTxt: 'chosen',
  classAddedTxt: 'Class added',
  classcode: 'Class code',
  classDeletedTxt: 'Class deleted',
  classNameEditedTxt: 'Class name edited',
  classNameTxt: 'Class name',
  createClassNameTxt: 'Name of the class',
  classOwnerTxt: 'Class owner',
  classSettings: 'Class settings',
  classTxt: 'Class',
  classesTxt: 'Classes',
  clearTxt: 'Clear',
  common: 'Regular',
  codeTxt: 'Code',
  colorIndex: 'Color index',
  confirm: 'Confirm',
  contentGuide:
    'Here you can add books to your list. Click on "New assignment" to view problems and hand them out to your pupils.',
  contentSweden: 'Swedish content',
  contentTxt: 'content',
  contentMaterialTxt: 'Library',
  commonError: 'Common error',
  compressHeatmap: 'Compress view',
  complementaryContentSweden: 'Swedish content re-structured',
  copyCode: 'Copy code',
  copyLink: 'Copy link',
  copy: 'Copy',
  copied: 'Copied',
  correctTxt: 'correct',
  correctAnswer: 'Correct answer',
  correctAnswerTxt: 'Correct answer',
  correctAnswersTxt: 'Correct answers',
  countryTxt: 'Country',
  create: 'Create',
  createAnAccount: 'Create an account',
  createFirstAssignmentSuccessDescription: 'Your pupils can now access it',
  createFirstAssignmentSuccessTitle: 'Good job creating an assignment!',
  createFirstClassDescription:
    'to your pupils when they create an account or create pupil accounts manually. You can also email us a class list and we will upload the accounts for you.',
  createdAt: 'Created at',
  createNewAccount1: 'Create new account 1/2',
  createNewAccount2: 'Create new account 2/2',
  createNewGroup: 'Name your new group',
  createTheFirstClassTitle: 'Welcome to Magma math!',
  createTheFirstClassDescription: 'Create a class to get started',
  createTxt: 'Create',
  createYourOwnProblems: 'Create your own problems',
  creatingExerciseTitle: 'Select pupils',
  creatingExercise: 'Create assignment:',
  disableQrCodeLogin: 'Disable QR code login',
  doForAll: (text: string) => `${text} for all`,
  done: 'Done',
  dontHestitate: 'Please don’t hesitate to contact us if you need help.',
  download: 'Download results',
  dublicatedEmail: 'Det finns redan en användare med den här mailadressen. Kontakta kundtjänst.',
  duplicateAssignment: 'Copy of',
  easyLevel: 'Fluency',
  edit: 'Edit',
  editClassNameTxt: 'Edit name of class',
  editExerciseNameTxt: 'Assignment name',
  editGroupNameTxt: 'Edit name of group',
  editEmail: 'Edit email',
  editFirstName: 'Edit first name',
  editLastName: 'Edit last name',
  editSectionNameTxt: 'Edit name of subchapter',
  editExercise: 'Edit',
  editProblemTxt: 'Edit problem',
  editSectionTxt: 'Edit',
  editUsername: 'Edit username',
  editMyBook: 'Edit My book',
  emailOrUsernameTxt: 'Email or username',
  emailTxt: 'Email',
  emptyMyBooksTxt: 'Your book list is empty',
  emptyUsersList: 'You don’t have access to any pupils.',
  emptyUsersSearchList: 'Your search returned no results.',
  emptyUserName: 'Tomt användarnamn',
  enableForAllTxt: 'Enable for all',
  enableQrCodeLogin: 'Enable QR code login',
  endExam: 'End quiz',
  endExamWarning: (isSingleStudent: boolean) =>
    `If you end the quiz, the results will be shared with the ${
      isSingleStudent ? 'pupil' : 'pupils'
    }.`,
  endTxt: 'End',
  endedTxt: 'Ended',
  enteredFractionsFormatTxt: 'are entered by using the format',
  enteredExponentialsTxt: 'are entered by typing the base followed by the',
  enteredMixedFormFormatTxt:
    'is entered similar to fractions. Enter the whole part(s) before the fraction',
  enterNewPass: 'Enter new password',
  eraseStudentTxt: 'Delete pupil',
  removeStudentTxt: 'Remove pupil',
  errorMessageAnswer: 'Multiple choice requires at least 2 options',
  errorMessageAnswerShort: 'What is the answer?',
  errorMessageCorrectAnswer: 'Please select the correct answer',
  errorMessageQuestion: 'What is the question? Please write or add an image',
  errorMessageUnfollowClass:
    'You are owner of the class, if you want to unfollow, write in the support chat',
  exam: 'Quiz',
  examSettings: 'Quiz settings',
  examEnded: 'Finished',
  examMode: 'Quiz mode',
  exampleTxt: 'Example',
  exampleProblemTxt: 'Example problem',
  exampleProblemForAbilityTxt: 'Sample problems for',
  exerciseSettings: 'Assignment settings',
  exerciseTxt: 'Assignment',
  exerciseNameTxt: 'Assignment name',
  exercisesTxt: 'Assignments',
  exerciseArchiveBtn: 'Archive',
  exerciseDeleteBtn: 'Delete',
  exerciseWasCreatedByAnotherTeacherTxt: 'Could not delete assignment. This was created by ',
  existedAccount: 'Du har redan ett Skolfederation-konto. Gå till Logga in istället.',
  existingStudent: 'Existing pupil',
  existingStudents: 'Existing pupils',
  expandHeatmap: 'Expand view',
  exponentialsTxt: 'Exponentials',
  dateTxt: 'Date',
  dearTxt: 'Dear',
  describeError: 'Whats wrong? (optional)',
  deletedExerciseTxt: 'Assignment deleted',
  deleteProblem: 'Delete problem',
  deleteProblemQuestion: 'Are you sure you want to delete this problem?',
  deleteSection: 'Delete',
  deleteSectionQuestion: 'Are you sure you want to delete this subchapter?',
  deleteSectionNote: 'All problems in this section will also be deleted.',
  deleteStudentNote: 'Are you sure that you want to remove the pupil from this class?',
  deleteClassNote:
    'Pupil accounts will be deleted and they can not log in. Make sure to move pupils to another class if they should continue to work. ',
  deleteTxt: 'Delete',
  difficultyTxt: 'Difficulty',
  districtLocked: 'Ended',
  districtLockedTooltip: 'The assignment is ended by the district.',
  removeTxt: 'Remove',
  duplicateExerciseTxt: 'Duplicate',
  duplicateExerciseNotifyTxt: 'Assignment duplicated',
  emptySubchapterTxt: 'There are no problems in this subchapter :/',
  findYourProblemsTxt: 'Find your problems',
  findYourClassTxt: 'Find your class',
  findOrCreateClassTxt: 'Find your class or create a new one',
  fileIsInvalid: 'The file is invalid',
  filter: 'Filter',
  finaliseRegistrationText:
    'By selecting your school, you will be able to collaborate with your colleagues',
  findAttachedStudentLists: 'Please find attached pupil lists',
  firstNameTxt: 'First name',
  follow: 'Follow',
  followSuccess: 'Class followed! Go to assignments to get started',
  forcedDrawing: 'Show work',
  forcedDrawingTooltip:
    'Pupils can not submit an answer before showing their work on selected problems. Only applicable to pupils working on web version.',
  forStudentsRegisterTxt: 'to the pupils, or register him yourself',
  forbiddenTxt: 'Forbidden',
  forgotPasswordIntrodution:
    "To reset password enter your email and we'll send you instructions on how to create new password",
  forgotPasswordTxt: 'Forgot password',
  forIPadOnlyTxt: 'For iPad only',
  fractionsTxt: 'Fractions',
  freeForSomeDays: 'Free for the first 60 days!',
  fromDistrict: 'From district',
  fromTxt: 'From',
  getResetCode: 'Get reset code',
  getStarted: 'Get started',
  giveCodeToStudentTxt: 'Give the code',
  giveExerciseToClass: 'Hand out assignment to class',
  googleClassroom: 'Google Classroom',
  goTo: 'Go to ',
  grade: 'Year',
  gradeChanged: 'Year changed',
  gradeZeroValue: 'R',
  graphicCalculator: 'Graphing',
  graphicalResponseTxt: 'Graphical response',
  groupAdded: 'Group added',
  groupDeleted: 'Group deleted',
  groupNameEditedTxt: 'Group name edited',
  groupName: 'Group name',
  groupSettings: 'Group settings',
  groupTxt: 'Group',
  groupsTxt: 'Groups',
  handWritingAnswer: 'Answer',
  haveAccess: 'Have access',
  hardLevel: 'Problem solving',
  headerForHelpCreatingExerciseModal: 'Tips for entering answer',
  heatmapTxt: 'Heatmap',
  helpcenterTxt: 'Help center',
  helpQueue: 'Help queue',
  hideNamesAndAnswers: 'Hide name and correctness',
  hiTxt: 'Hi',
  ifYouHaveQuestionsBeforeChatTxt: 'If you have any questions, don’t hesitate to reach out via',
  immigrationAgreement: 'terms and conditions',
  informationTxt: 'Information',
  inputCodeIntrodution: 'Please enter reset code and username we sent to your email to continue.',
  insertEquation: 'Insert equation',
  internationalContent: 'International content',
  invalidEmail: 'Invalid email address',
  invalidSchool: 'Din skola finns inte registrerad. Vänligen kontakta kundtjänst.',
  invalidUsernameOrPassword: 'Invalid username or password',
  inTheAnswerBox: 'in the answer box',
  itIsPublicBook: 'This book can not be removed from your list',
  isEnteredAsTxt: 'is entered as',
  isLoading: 'Loading...',
  kindRegardsTxt: 'Kind regards',
  isNotInTheList: 'is not in the list',
  klasslistaSubject: 'Pupil list',
  klasslistaTxt: 'us a list of pupils that we upload for you',
  languageTxt: 'GB',
  languageListNoResults: 'Your search returned no results. Try another language',
  languageSupportTxt: 'Language support',
  lastNameTxt: 'Last name',
  left: 'Left',
  leftMagma: 'Left Magma',
  leftTheAssignment: 'Left the assignment',
  levelTxt: 'Level',
  lessInfoTxt: 'Less info',
  libraryTxt: 'Library',
  loadingDataTxt: 'Loading data ...',
  lockUnlockGradesTxt: 'lock/unlock years',
  logInTxt: 'Log in',
  logoutTxt: 'Sign out',
  goToDistrict: 'Go to district',
  lostConnection1: 'It seems like you have lost',
  lostConnection2: 'your internet connection.',
  manualTxt: 'Manual',
  markAsCorrect: 'Mark as correct',
  markAsInCorrect: 'Mark as incorrect',
  markAsDoneTxt: 'mark as done',
  mediumLevel: 'Reasoning',
  membersCanTryMatteappenTxt:
    "Right now, new users get to try out our service for free for 60 days - don't miss out on this amazing offer!",
  messageUsTxt: 'Need help? Message us in the chat below.',
  messageUs: 'Message us in the chat below to request specific content.',
  minutesAbbreviation: 'min',
  mixedTxt: 'Mixed',
  mixedFormTxt: 'Mixed form',
  moreTxt: 'More',
  moreBooks: 'More books...',
  moreClassesTxt: 'More classes...',
  moreInfoTxt: 'More info',
  moreThan: 'More than',
  moreOptions: 'More options',
  mostAnswersTxt: 'Most answers',
  mostCorrectAnswersTxt: 'Most correct answers',
  moveToAnotherClass: 'Move to another class',
  moveToTheBottomTxt: 'Move to bottom',
  moveToTheTopTxt: 'Move to top',
  moveDownTxt: 'Move down',
  moveUpTxt: 'Move up',
  multiple: 'Multiple choice (multiple correct answers)',
  multipleAnswers: 'Multiple answers',
  multipleChoice: 'Multiple choice',
  multipleChoiceErrorMessage: 'You have reached the max length',
  multipleTopics: 'Multiple topics',
  multiSelectAnswer: 'Select several',
  districtTxt: 'District',
  mustBeGreatPassword: 'Password must be 6 characters',
  mustBeSixCode: 'Code must be 6 characters',
  mustBeLessTwenty: 'Must be 20 characters or less',
  mustBeLongerUsername: 'Username needs to be at least 4 characters',
  mustBeGreatSchool: 'Input 3 characters or more',
  mustMatchEmail: 'must match email',
  myBookTxt: 'My book',
  myClassesTxt: 'Followed',
  myListTxt: 'My List',
  myGroupsTxt: 'My groups',
  nameTxt: 'Name',
  displayName: 'Display name',
  addName: 'Add name',
  addDisplayName: 'Add display name',
  editDisplayName: 'Edit display name',
  nameYourNew: 'Name your new',
  networkError: 'Network error',
  newClassTxt: 'New class',
  newExerciseName: 'Enter a new assignment name',
  newExerciseTxt: 'New assignment',
  newFirstNameTxt: 'New first name',
  newLastNameTxt: 'New last name',
  newGroupTxt: '+ New group',
  newGroupWithoutPlus: 'New group',
  newPassConfirmTxt: 'New password confirmation',
  newPassTxt: 'New password',
  newStudent: 'New pupil',
  newSubchapterTxt: 'New subchapter',
  newSubchapterChooseNameTxt: 'Name subchapter',
  newSubchapterNameTxt: 'New subchapter name',
  nextTxt: 'Next',
  noAnswer: 'No answers',
  noBooksInBookSeries: 'No books in this series',
  noChaptersFound: 'There are no chapters in this book.',
  notFoundAccount: 'Du saknar konto. Skapa konto eller kontakta Matteappens support.',
  noProblemsAddedForLevel: (level?: string) => `No ${level ? `${level} ` : ''}problems added`,
  noResultsForTxt: 'No results for',
  noClassesFound: 'No class found. Need help? Message us in the chat below.',
  noClassesFoundTxt:
    "You have no classes. Message us in the chat below and we'll help you get started",
  noLongerInAssignmentTxt: 'No longer in assignment.',
  noSchoolClassesTxt: 'There are no classes at your school',
  noSchoolGroupsTxt: 'You have no groups',
  noSectionsFound: 'There are no subchapters in this chapter.',
  noStudentsText: 'There are no pupils in this class.',
  noSolutionSubmitted: 'No calculations',
  noStudentsForHelp: 'No pupils waiting for help',
  noStudentsInGroup: 'No pupils found in this group',
  noResultsFound: "We're sorry, but we couldn't find any results.",
  noteAboutChangeNameLater:
    'Choose a meaningful name that represents the content. You can always change the name later.',
  noteForCreatingAccountUnderButton:
    "It's free to create an account and we will not ask you for any payment details until you have tried our service. You can end your subscription at any time.",
  notAnswered: 'Not answered',
  notAnsweredInProgressPage: 'Not answered',
  notAnsweredYet: 'No answer yet',
  noProblemsFound: 'There are no problems in this subchapter.',
  noTopicsMessage: 'You have no topics now',
  noTxt: 'No',
  numberOfStudents: 'Number of pupils',
  optionsTxt: 'Options',
  numeric: 'Numeric',
  off: 'Off',
  option: 'Option',
  optional: 'Optional',
  orderProblemsOnTxt: 'Order problems on:',
  orderSavedTxt: 'The order of problems has successfully been saved',
  other: 'Other',
  othersOptionsTxt: 'Others options',
  ownedByTxt: 'Owned by',
  passDontMatch: "Passwords don't match",
  passUpdateSuccess: 'Password update successfully',
  passwordTxt: 'Password',
  pauseTxt: 'Pause',
  pausedTxt: 'Paused',
  percentageOfAnsweredQuestions: 'Percentage of answered questions',
  placeholderCountryTxt: 'Start typing country name',
  placeholderDistrictTxt: 'Start typing district name',
  placeholderSchoolTxt: 'Start typing school name',
  play: 'Play',
  pickAClass: 'Pick a class',
  pickAName: 'Pick a name',
  problemsOfExercise: 'Problems of assignment',
  problemTxt: 'Problem',
  problemsTxt: 'Problems',
  problemsTxtShort: 'prob.',
  pleaseSelectBook: 'Please select book source',
  pleaseSelectClass: 'Please select a class/group',
  pleaseSelectBookAndClass: 'Please select book source and class',
  pleaseSelectDateRange: 'Please select a date range',
  practiceTxt: 'Practice',
  mailingClassList: 'Email class list',
  mailingList: 'Email list',
  mapTxt: 'Map',
  nameAssignment: 'Name assignment',
  ofTxt: 'of',
  orTxt: 'or',
  schoolClassesTxt: 'School classes',
  oupsTxt: 'Oups!',
  overview: 'Overview',
  printExercise: 'Print assignment ',
  printPdfToExercise: 'Please send me a pdf to print for assignment ',
  printExerciseTxt: 'Print',
  printTxt: 'Print',
  privateBooksTxt: 'Private books',
  profileTxt: 'Profile',
  publicBooksTxt: 'Public books',
  publishExerciseTxt: 'Published',
  publishTxt: 'Publish',
  publishingTxt: 'Publishing',
  toPublishTxt: 'Publish',
  toArchiveTxt: 'Archive',
  pinnedOrderTxt: 'Pinned order',
  pinnedSolutionTxt: 'Pinned solutions',
  publishAgainTxt: 'publicise again',
  publishNowTxt: 'Publish now',
  publishedNowTxt: 'Published now',
  questionTxt: 'Question',
  randomTxt: 'Random',
  readAloudSuccessTxt: 'Text-to-speech enabled for all pupils in the class',
  readAloudErrorTxt:
    'Could not activate text-to-speech. Message us in the chat below and we will assist you.',
  readingTxt: 'Text-to-speech',
  readMeAboutCreatingAccount: 'Read more about how you',
  regular: 'Write by hand',
  regularCalculator: 'Basic',
  deleteClass: 'Delete class',
  recommendTxt: 'Recommend',
  randomizeTxt: 'Randomize',
  removeStudentFromGroup: 'Remove from group',
  removeStudentFromClass: 'Remove from class',
  reorderProblemsTxt: 'Reorder problems',
  reportQuestion: 'Report the question',
  requestAccess: 'Request access',
  requestSent: 'Request sent',
  requiredFieldText: 'Required field',
  requestTxt: 'Request',
  reportOrdered: 'Report ordered',
  reset: 'Reset',
  resetAllPasswordInClass: 'Reset all passwords',
  resetAllPasswordsQuestion: 'Choose new password for the whole class',
  resetChange: 'Reset change',
  resetPassIntrodution: 'Please enter new password to continue.',
  resetPassTxt: 'Reset password',
  resetPasswordsTxt: 'Reset passwords',
  resetStudentsPasswordsTxt: 'Reset pupils passwords',
  resultTxt: 'result',
  resultsTxt: 'results',
  resultButtonText: 'Result',
  requireUnit: 'Require unit',
  rightAnswerTxt: 'Right answer',
  rightAnswerAnyAttempts: 'Multiple attempts',
  rightAnswerFirstAttempt: 'One attempt',
  selectTxt: 'Select',
  saveTxt: 'Save',
  saveOrCancelChangesTxt: 'Please save or cancel your changes.',
  scatterplot: 'Scatterplot',
  scatterplotTxt:
    'This graph represents number of correct answers and share of correct answers for the assignment',
  scheduleTxt: 'Schedule',
  scheduledSettingInfoTxt: 'The assignment will be published automatically',
  scheduledSettingInfoTestModeTxt: 'The quiz will be published and start automatically',
  showStats: 'Show stats',
  showTxt: 'Show',
  showPinned: 'Show pinned',
  schoolTxt: 'School',
  schoolYear: 'Academic Year',
  scientificCalculator: 'Scientific',
  searchTxt: 'Search',
  searchClassesTxt: 'Search for a class',
  searchHereExample: 'Search here. E.g. "multiplication"',
  secondsAbbreviation: 'sec',
  selectAll: 'Select all',
  selectClass: 'Select class',
  selectClassOrGroup: 'Select class/group',
  selectLevel: 'Select level',
  selectEndDate: 'Select end date',
  selectInOrderAnswer: 'Select in order',
  selectProblems: 'Select problems',
  selectStartDate: 'Select start date',
  selectStudent: 'Select pupil',
  selectYourProblemsTxt: 'Select your problems',
  sendClasslist: 'Send classlist',
  sendClasslistInstead: 'Send classlist instead',
  sendClasslistTxtTop: 'Send us a list of your pupils, and we create their accounts!',
  sendClasslistTxtBottom: ' In the email, please attach an excel file with their',
  sendEmailTxt: 'Send email',
  sendMeReport: 'Send me a report',
  sendMeReportFor: 'Send me a report for ',
  sendMeReportFullTxt: 'Please send me a report for assignment ',
  sendTxt: 'Send',
  selectLanguageTxt: 'Select language',
  setExerciseNameTxt: 'Please set name of the assignment',
  singleSelectAnswer: 'Select one',
  signInAsTeacher: 'Sign in as teacher',
  signInTxt: 'Sign in',
  signInWithQRCodeTxt: 'Sign in with QR code',
  signUpTxt: 'Sign up',
  signUpScolon: 'Log in with Scolon',
  skillsBtnHint:
    'Click here to get an overview of the pupils results for different skills in the assignment.',
  skillsPlusBtnHint: 'Click here to create an assignment with rehearsal materials for your pupils.',
  createStudentAccount: 'Create pupil account',
  showMore: 'Show more',
  signFollowedBySquareBrackets: '-sign, followed by square brackets surrounding the exponent',
  signUpTeacherTxt: 'Create free teacher account',
  // tslint:disable-next-line:object-literal-sort-keys
  signUpStudentManually: 'Sign up pupil manually',
  singleAnswer: 'Multiple choice (one correct answer)',
  singleStudents: 'Single pupils',
  sortStudents: 'Sort pupils',
  skillsMatrix: 'Skills matrix',
  skillsInfoTxt:
    "In the math app, all tasks are categorized according to the skill that the task the pupil tests. In this view, the pupil's results on the assignment have been combined to provide an overview of any knowledge gaps where you can directly create extra material or rehearsal assignments for your pupils.",
  skillsModalText:
    'Press create, to hand out a repetition assignment to all pupils who got less than 60% correct in the given skill.',
  solutionsTxt: 'Solutions',
  specialBook:
    'You have chosen problems that require manual marking. Read the guide before you start here:',
  standardAbbreviations:
    'Units are entered accordsolutionsTxting to standard abbreviations, e.g. kg, cm, ml, yd',
  startExam: 'Start quiz',
  startguide: 'Startguide',
  startTxt: 'Start',
  startedTxt: 'Started',
  startExerciseTxt: 'Start assignment for',
  statsInfoTxt:
    'Here’s a statistical overview of your pupils’ answer rate. Filter by class or group by pressing “Select class”. The percentage shows the correct answers from a pupil. Press on a pupil to get more info.',
  statisticsTxt: 'Stats',
  studentPreview: 'Pupil preview',
  settingsTxt: 'Settings',
  stopExam: 'Stop',
  studentAddedTxt: 'Pupil added',
  studentDeletedTxt: 'Pupil removed',
  studentTxt: 'Pupil',
  studentsTxt: 'Pupils',
  studentsQuantityWasChanged: 'Pupils quantity was changed',
  studentSignInLinkTxt: 'Pupil login',
  subchapterAdded: 'Subchapter added',
  subchapterTxt: 'Subchapter',
  subchaptersTxt: 'Subchapters',
  submitTxt: 'Submit',
  taskTxt: 'Task',
  teacherGuide: 'Teacher guide',
  teacherNotFound: 'Teacher not found',
  teacherPlatform: 'Teacher platform',
  teacherTxt: 'Teacher',
  testsTxt: 'Tests',
  teoryTxt: 'Theory',
  timesTxt: 'times',
  thankYouShort: 'Thank you',
  thankYouTxt: 'Thank you',
  thanksForYourFeedback: 'Thanks for your feedback. We will look into it!',
  theClassCodeTxt: 'The class code',
  thisActionCannotBeUndone: 'This action cannot be undone',
  tryAgainTxt: 'Try again',
  tryToSearchSomethingElse: ':( Try searching for something else.',
  tryAnotherName: 'Try another name',
  tryCreateNewUserTxt: 'Try creating a new one!',
  topicTxt: 'Topic',
  topicsTxt: 'Topics',
  toTxt: 'To',
  solve: 'Solve',
  textReplacement: 'Text replacement',
  textSizeTxt: 'Text size',
  translationLanguageTxt: 'Translation language',
  typeTxt: 'Type',
  userNameTxt: 'Username',
  userNotFound: 'User not found',
  userNotTeacher: 'User not a teacher',
  changeClass: 'Change class',
  // tslint:disable-next-line:object-literal-sort-keys
  changeClassesNameTxt: 'Change class name',
  changeGroupNameTxt: 'Change group name',
  changeStudentUsername: 'Change pupil username',
  changeTopic: 'Change topic',
  changeUsernameTxt: 'Change username',
  removeClassTxt: 'Remove class',
  removeGroupTxt: 'Remove group',
  removeImage: 'Remove image',
  republish: 'Re-open',
  republishExam: 'Re-open',
  shareClassroomError: 'Could not share the assignment. Please try again later.',
  shareClassroomSuccess: "Copy created. Your colleague will find it in 'Unpublished'",
  shareClassTxt: 'Share class',
  shareGroupTxt: 'Share group',
  shareLinkTxt: 'Share with pupils',
  shareWithTxt: 'Share with',
  shareWithTeachers: 'Share with teachers',
  shareLinkTeachersTxt: 'Copy to colleague',
  shareLinkTeachersNotifyTxt: 'Assignment shared with',
  shareLinkModalBody:
    'When the pupils click the link below, they will be sent straight into the assignment.',
  shareLinkModalFooter:
    'Note that this link will only work for pupils who were assigned the specific assignment.',
  shareLinkModalTitle: 'Share the assignment with pupils',
  shareClassMessageTitle: 'Magma - Share your class please',
  shareClassMessageBody: 'Can you please share your class',
  shareClassClickLinkTxt: 'Click the link below to share your class',
  sharing: 'Sharing',
  statusTxt: 'Status',
  studentMovedToAnotherClass: 'Pupil has been moved to a different class',
  studentUsernameEditedTxt: 'Pupil username was edited',
  somethingWentWrong: 'Oups… something went wrong. Please try again!',
  switchSchool: 'Switch school',
  typeStringToDelete: `Type DELETE to confirm`,
  unarchiveClass: 'Unarchive class',
  uncategorizedTxt: 'Uncategorized',
  unfollow: 'Unfollow',
  unfollowClass: 'Unfollow class',
  unfollowClassDescription: 'Are you sure? You will lose access to the class.',
  unfollowTxt: 'Unfollow',
  unfollowedSuccess: 'Unfollowed successfully',
  unpublishTxt: 'Unpublish',
  unpublishedTxt: 'Unpublished',
  unpublishedSingleTxt: 'Unpublished',
  updateBooksFailTxt: 'Ops! Something went wrong. Try again',
  updateBooksSuccessTxt: 'Bookshelf updated',
  updateTxt: 'Update',
  uppgifterAndRanking: 'Tasks and rankings',
  unitTxt: 'Unit',
  useClassCodeTxt: 'Use class code',
  userExistingWithEmail: 'User with this email already exists',
  incorrectUsername: 'Username is not correct',
  incorrectTxt: 'Incorrect',
  invalidUsername: 'Invalid username',
  invalidCode: 'Invalid code',
  waitingForAutologin: 'Signing in...',
  watched: 'Looked at the answer',
  weHaveSentEmailTo: 'We have sent an email to',
  weWillSupportAsSoonAsPossible: 'we will support you as soon as possible',
  whoIsTheAssignmentFor: 'Who is the assignment for?',
  wholeClassTxt: 'Whole class',
  withSomeTipsHowToGetStarted: 'with some tips how to get started',
  withMeTxt: 'with me',
  writeYourProblemTxt: 'Write your problem here',
  writeYourSubProblemTxt: 'Write your subproblem here',
  writeYourQuestionTxt: 'Write your question here...',
  noAvailable: 'Create an assignment',
  noArchived: 'You have no archived assignments',
  noClassesAddedTxt: "You don't have any class yet.",
  noCountriesInTheList: 'No countries in the list',
  noGroupsAddedTxt:
    'Groups are used to divide pupils in ways other than their class. This is to make it easier for you to personalize the learning experience for each of your pupils.',
  noMunicipalitiesInTheList: 'No districts in the list',
  noOptionsMessage: 'No options',
  noSchoolsInTheList: 'No schools in the list',
  noStudentsInThisAssignmentTxt: 'There are no pupils in this assignment',
  noTranslationTxt: 'Nothing selected',
  noUnpublished: 'You have no unpublished assignments',
  nowTxt: 'Now',
  noneTxt: 'None',
  pickClassOrGroupTxt: 'Pick a class or group',
  pieChart: 'Stats wheel',
  wohoo: 'Yay',
  year: 'Year',
  yesTxt: 'Yes',
  yetTxt: 'yet',
  youAreAllSetToGo: 'You are all set to go!',
  youCanAlsoTxt: 'You can also',
  youCanChangeStudentsInGroup:
    'You can change which pupils are to be included in the group at any time.',
  youCantCreateClass: 'You are not following any class. Need help? Message us in the chat below',
  youHaveNoStudentProgressStats: 'Data will be shown when pupils have started working',
  yourChangesSavedTxt: 'Your changes have been saved',
  yourChangesSavingErrorTxt: 'Your changes were not saved. Please try again.',
  zeroGradeTxt: 'R',
  today: 'Today',
  tomorrow: 'Tomorrow',
  startDateTxt: 'Starts',
  dueDateTxt: 'Due',
  scheduleDateError: 'Please select a due time after the start time',
  removeFromLibraryTxt: 'Remove from bookshelf',
  removeFromListTxt: 'Remove from list',
  statsWheel: 'Stats wheel',
  standardAndSkill: 'Standard and skill',
  multipleStudents: 'Multiple pupils',
  nameSubchapterInputPlaceholder: 'Choose a name that represents the content',
  nameSubchapterInputLabel: 'Subchapter name',
  deleteSubchapterModalTitle: 'Delete subchapter',
  deleteProblemModalTitle: 'Delete problem',
  editSubchapterModalTitle: 'Edit name of subchapter',
  hiddenStudentsNamesTooltip: 'Pupil names are hidden when you use the anonymizing feature.',
  selectGrade: 'Select a Grade',
  assignGrade: (classNameValue: string) => `Assign ${classNameValue} a grade to continue`,
  setGradeErrorNotification: 'Unable to save grade to class',
  setGradeSuccessNotification: 'Grade was added to class',
  groupUpdatedNotificationText: 'The group has successfully been updated',
  printQRCodes: 'Print QR-codes',
  printList: 'Print list',
  layoutTxt: 'Layout',
  sixCodesPerPage: '6 codes per page',
  oneCodePerPage: '1 code per page',
  reportQuestionModalText: `Your feedback goes straight to our team at Magma. We’ll fix it as quickly as possible. Thanks for helping us improve!`,
  reportSentPopupText: 'The report has successfully been sent to our team at Magma',
  reportErrorPopupText: 'Oh no something went wrong! Try again.',
  noStudentsForAddingToClass:
    'Looks like there aren’t any pupils left who aren’t already in the class.',
}

export type ILocalization = {
  data: typeof englishDictionary
}
