import { IClass, QRCodeData } from 'api/types.classes'

export const enum PrintClassType {
  QR,
  LIST,
}

export type PrintClassState = PrintQRCodesClassType | PrintListClassType | null

export type PrintQRCodesClassType = {
  type: PrintClassType.QR
  qrCodedata: QRCodeData[]
  layout: LayoutVariant
  class: IClass
}

export type PrintListClassType = {
  type: PrintClassType.LIST
  class: IClass
}

export const enum LayoutVariant {
  ONE_PER_PAGE = '1',
  SIX_PER_PAGE = '6',
}

export type SetStateProps = {
  type: PrintClassType
  class: IClass
}
