import React from 'react'
import { SvgIconProps } from '../types/types'

const TimerIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 15 16'
      fill='none'
    >
      <path
        d='M8 6.25C8 5.83579 7.66421 5.5 7.25 5.5C6.83579 5.5 6.5 5.83579 6.5 6.25V8.5H5.25C4.83579 8.5 4.5 8.83579 4.5 9.25C4.5 9.66421 4.83579 10 5.25 10H7.25C7.66421 10 8 9.66421 8 9.25V6.25Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.75 0C5.33579 0 5 0.335786 5 0.75C5 1.16421 5.33579 1.5 5.75 1.5H6.5V2.5412C3.12504 2.91428 0.5 5.77558 0.5 9.25C0.5 12.9779 3.52208 16 7.25 16C10.9779 16 14 12.9779 14 9.25C14 7.66422 13.4524 6.20525 12.5372 5.05341L13.2563 4.31699L13.7197 4.78033C14.0126 5.07322 14.4874 5.07322 14.7803 4.78033C15.0732 4.48744 15.0732 4.01256 14.7803 3.71967L12.7803 1.71967C12.4874 1.42678 12.0126 1.42678 11.7197 1.71967C11.4268 2.01256 11.4268 2.48744 11.7197 2.78033L12.1956 3.25625L11.4797 3.98933C10.5059 3.20558 9.30919 2.68609 8 2.54124V1.5H8.75C9.16421 1.5 9.5 1.16421 9.5 0.75C9.5 0.335786 9.16421 0 8.75 0H5.75ZM2 9.25C2 6.3505 4.35051 4 7.25 4C8.70921 4 10.0282 4.59433 10.9805 5.55599C11.9207 6.50536 12.5 7.8093 12.5 9.25C12.5 12.1495 10.1495 14.5 7.25 14.5C4.35051 14.5 2 12.1495 2 9.25Z'
        fill={color}
      />
    </svg>
  )
}

export default TimerIcon
