import { MenuItem, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import CreatingClassCard from 'ui/cards/CreatingClassCard/CreatingClassCard'
import UIModal from 'ui/modals/UIModal/UIModal'
import { ActionElement, ModalType } from '../../../helpers/enums'
import { IState } from '../../../interfaces/state.interface'
import { IMe } from '../../../api/types.users'
import { getFullGrades } from '../../../lib/grades'
import { IClassActions } from '../../../redux/modules/class.module'
import { IModalActions } from '../../../redux/modules/modal.module'
import { ModalBox } from '../../basic/Boxes/ModalBox/ModalBox'
import { ContainedButton } from '../../basic/Buttons/ContainedButton/ContainedButton'
import { TextButton } from '../../basic/Buttons/TextButton/TextButton'
import { SimpleInput } from '../../basic/Inputs/SimpleInput/SimpleInput'
import './ModalCreateClass.scss'
import { ILocalization } from '../../../config/languages.config'
import { IClass } from '../../../api/types.classes'
import { useDispatch } from 'react-redux'
import { clearExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'

interface IModalModalCreateClass {
  modal: any
  localization: ILocalization
  classActions: IClassActions
  modalActions: IModalActions
  addedClass: IState<IClass>
  me: IMe
}

const ModalCreateClass: React.FC<IModalModalCreateClass> = ({
  modal,
  localization,
  classActions,
  modalActions,
  addedClass,
  me,
}) => {
  const history = useHistory()
  const opened: boolean = modal.data.modalType === ModalType.CreateClassModal
  const handleClose = () => {
    modalActions.closeCreateClassModal()
  }
  const MIN_CLASSNAME_LENGTH = 1
  const { title, actionElement } = modal!.data
  const { saveTxt, nameYourNew, cancelTxt, chooseGrade, classTxt, newClassTxt } = localization!.data
  const [inputValue, setInputValue] = useState('')
  const [selectValue, setSelectValue] = useState('-1')
  const [disabled, setDisabled] = useState<boolean>(true)
  const isCreateClassAction = actionElement === ActionElement.createClass
  const dispatch = useDispatch()

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectValue(event.target.value)
  }

  const createNewCLass = () => {
    if (isCreateClassAction) {
      classActions!.addClass(
        { schoolId: me.school._id, name: inputValue, grade: +selectValue },
        history,
        localization
      )
    }
    if (actionElement === ActionElement.changeGrade) {
      classActions!.editClass({ grade: +selectValue }, addedClass!.data._id, history, localization)
      dispatch(clearExerciseWithoutStats())
    }
  }

  useEffect(() => {
    if (isCreateClassAction) {
      setDisabled(inputValue.trim().length < MIN_CLASSNAME_LENGTH)
      return
    }

    setDisabled(false)
  }, [inputValue])

  if (ActionElement.createClass === actionElement) {
    return (
      <UIModal open={opened} onClose={handleClose} wrapperClassName='create-class-wrapper'>
        <CreatingClassCard
          localization={localization}
          me={me}
          schoolId={me.school._id}
          headerTitle={newClassTxt}
          shouldRedirectOnClassCreation={modal?.data?.shouldRedirectOnClassCreation}
        />
      </UIModal>
    )
  }
  return (
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <ModalBox>
        <div className='create-class-modal-container'>
          <div className='create-class-modal-header'>
            <h1>{title}</h1>
          </div>
          <div className='create-class-modal-body'>
            {ActionElement.createClass === actionElement && (
              <div className='create-class-modal-input'>
                <p className='create-class-modal-description'>
                  {`${nameYourNew} ${classTxt.toLowerCase()}`}
                </p>
                <SimpleInput
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  value={inputValue}
                  onChange={inputHandler}
                  autoFocus={true}
                />
              </div>
            )}
            <div className='create-class-modal-select'>
              <p className='create-class-modal-description'>{chooseGrade}</p>
              <SimpleInput
                select={true}
                value={selectValue}
                onChange={handleChange}
                variant='standard'
                InputProps={{ disableUnderline: true }}
              >
                {getFullGrades(me.setting.appLanguage, localization).map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SimpleInput>
            </div>
          </div>
          <div className='create-class-modal-footer'>
            <div className='create-class-modal-btn-container'>
              <TextButton variant='text' onClick={handleClose}>
                {cancelTxt}
              </TextButton>
            </div>
            <div className='create-class-modal-btn-container'>
              <ContainedButton variant='contained' onClick={createNewCLass} disabled={disabled}>
                {saveTxt}
              </ContainedButton>
            </div>
          </div>
        </div>
      </ModalBox>
    </Modal>
  )
}

export default ModalCreateClass
