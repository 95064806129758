import { IBook, IMyBook } from '../../api/types.books'
import { SectionPath } from './Book/model/types'

export const getRemovedBookIds = (books: IBook[], selectedBooks: string[]) => {
  return selectedBooks.reduce<Set<string>>((bookIds, selectedBook) => {
    const bookExists = books.find((book: IMyBook) => book._id === selectedBook)

    if (bookExists) {
      return bookIds
    }
    bookIds.add(selectedBook)
    return bookIds
  }, new Set())
}

export const getFirstOpenedBookPath = (
  sectionPaths: SectionPath[],
  books: IBook[],
  selectedProblemsPaths: SectionPath[]
) => {
  const firstBook = books.find((book) =>
    sectionPaths.find((section) => section.bookId === book._id)
  )

  if (!firstBook) {
    return null
  }

  const sectionPath = sectionPaths.find(
    (section) =>
      section.bookId === firstBook._id &&
      selectedProblemsPaths.find((path) => path.id === section.id)
  )

  return sectionPath
    ? {
        bookId: sectionPath.bookId,
        chapter: { id: sectionPath.chapterId, name: sectionPath.chapterName },
        section: sectionPath,
      }
    : null
}
