import { attach, createEvent, createStore } from 'effector'
import { IBook } from '../../../api/types.books'
import { removeChapters } from '../Book/model/chapters'
import { $sectionPaths } from '../Book/model/sectionPaths'
import { $selectedProblems } from '../Book/model/selectedProblems'
import { SectionPath } from '../Book/model/types'
import { getFirstOpenedBookPath, getRemovedBookIds } from '../helpers'
import { openSection } from './openedBooks'
import { fetchBooksFx, fetchMyBooksFx, updateBooksFx } from './requests'

export const setMyBooks = createEvent<IBook[]>()
export const resetMyBooks = createEvent()
const booksRemoved = createEvent<string[]>()
const booksAdded = createEvent<IBook[]>()

export const $myBooks = createStore<IBook[] | null>(null)
  .on(setMyBooks, (_, books) => books)
  .on(booksRemoved, (state, removedBooks) => {
    return state?.filter((book) => !removedBooks.includes(book._id))
  })
  .on(booksAdded, (state, addedBooks) => (state ? [...state, ...addedBooks] : addedBooks))
  .on(fetchMyBooksFx.doneData, (_, books) => books)
  .on(fetchMyBooksFx.failData, () => null)
  .on(fetchBooksFx.doneData, (state, books) => (state ? [...state, ...books] : books))
  .on(fetchBooksFx.failData, (state) => state)
  .on(updateBooksFx.doneData, (_, result) => result.books)
  .reset(resetMyBooks)

export const $areMyBooksLoading = fetchMyBooksFx.pending

const openFirstBook = (
  sectionPaths: SectionPath[],
  books: IBook[],
  selectedProblemsPaths: SectionPath[]
) => {
  const firstBookPath = getFirstOpenedBookPath(sectionPaths, books, selectedProblemsPaths)
  if (firstBookPath) {
    openSection(firstBookPath)
  }
}

export const initBooks = attach({
  source: { sectionPaths: $sectionPaths, selectedProblems: $selectedProblems, myBooks: $myBooks },
  effect: async ({ sectionPaths, selectedProblems, myBooks }) => {
    if (myBooks && sectionPaths.size) {
      return
    }

    const books = await fetchMyBooksFx()

    const selectedBooks = [...sectionPaths.values()].reduce<string[]>((acc, section) => {
      if (selectedProblems.has(section.id)) {
        acc.push(section.bookId)
      }
      return acc
    }, [])

    const selectedProblemPaths = [...sectionPaths.values()].filter((section) =>
      selectedProblems.has(section.id)
    )

    const removedBookIds = [...getRemovedBookIds(books || [], selectedBooks)]

    if (!removedBookIds.length && books) {
      openFirstBook([...sectionPaths.values()], books, selectedProblemPaths)
      return
    }

    // Fetch books that were removed from the bookshelf but contain selected problems
    const removedBooks = await fetchBooksFx(removedBookIds)
    openFirstBook([...sectionPaths.values()], [...books, ...removedBooks], selectedProblemPaths)
  },
})

booksRemoved.watch((removedBooks) => {
  removedBooks.forEach((bookId) => removeChapters(bookId))
})

export class booksModel {
  public static removed = booksRemoved
  public static added = booksAdded
}
