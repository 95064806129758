import { APP_ID } from 'containers/App/constants'
import { Events } from 'helpers/enums'
import { useEffect } from 'react'
import styles from './MultipleSolutions.module.scss'

export const usePrepareForPrint = (blockRef: React.MutableRefObject<HTMLDivElement | null>) => {
  useEffect(() => {
    const appContainer = document.getElementById(APP_ID)

    const handleBeforePrint = () => {
      if (appContainer) appContainer.classList.add(styles.Printable)
      if (blockRef.current) blockRef.current.classList.add(styles.Printable)
    }
    const handleAfterPrint = () => {
      if (appContainer) appContainer.classList.remove(styles.Printable)
      if (blockRef.current) blockRef.current.classList.remove(styles.Printable)
    }

    window.addEventListener(Events.BEFOREPRINT, handleBeforePrint)
    window.addEventListener(Events.AFTERPRINT, handleAfterPrint)

    return () => {
      window.removeEventListener(Events.BEFOREPRINT, handleBeforePrint)
      window.removeEventListener(Events.AFTERPRINT, handleAfterPrint)
    }
  }, [blockRef])
}
