import React from 'react'
import {
  SelectOptionColor,
  SelectOptionSize,
  SelectOptionState,
  SingleSelectGroup,
} from '@magmamath/ui'
import styles from './GradeSelect.module.scss'
import { GradeItem } from './types'

export type GradeSelectProps = {
  grade?: string | null
  onGradeChanged: (grade: string | null) => void
  gradeItems: GradeItem[]
}

export const GradeSelect = ({ grade, onGradeChanged, gradeItems }: GradeSelectProps) => {
  return (
    <SingleSelectGroup
      value={grade}
      onOptionChange={onGradeChanged}
      color={SelectOptionColor.ORANGE}
      state={SelectOptionState.DEFAULT}
      size={SelectOptionSize.SMALL}
      classes={{
        select: styles.GradeSelectContainer,
      }}
    >
      {gradeItems.map((grade) => (
        <SingleSelectGroup.Option
          key={grade.value}
          value={grade.value.toString()}
          classes={{
            selectOption: styles.GradeSelecetOptionContainer,
            content: styles.GradeSelectOption,
          }}
        >
          {grade.label}
        </SingleSelectGroup.Option>
      ))}
    </SingleSelectGroup>
  )
}
