import { createEffect } from 'effector'
import { FetchAllBookSeriesFxParams, FetchAllBooksFxParams } from './types'
import { BOOKS_LIMIT } from '../constants'
import { IBook } from '../../../api/types.books'
import { api } from '../../../api'

type FetchBooksParams = {
  offset: number
  books: IBook[]
  bookSeriesIds?: string[]
}

const fetchBooks = async ({
  offset = 0,
  books,
  bookSeriesIds,
}: FetchBooksParams): Promise<IBook[]> => {
  if (!bookSeriesIds) return books
  const res = await api.books.getByBookSeries({
    limit: BOOKS_LIMIT,
    offset,
    bookSeriesIds: bookSeriesIds?.join(','),
  })
  books.push(...res.items)
  if (res.items.length === BOOKS_LIMIT) {
    return fetchBooks({ offset: offset + BOOKS_LIMIT, bookSeriesIds, books })
  }
  return books
}

export const fetchMyBooksFx = createEffect(async () => {
  const res = await api.library.getAll()
  return res.books
})

export const fetchBooksFx = createEffect((bookIds: string[]) =>
  Promise.all(bookIds.map((id) => api.books.getSingle(id)))
)
export const updateBooksFx = createEffect((bookIds: string[]) =>
  api.library.updateBooks({ bookIds })
)

export const fetchAllBooksFx = createEffect(
  ({ offset = 0, bookSeriesIds }: FetchAllBooksFxParams) => {
    //TODO: can set books as default empty array in props of fetchBooks fn
    //so then can replace with createEffect(fetchBooks)
    return fetchBooks({ offset, bookSeriesIds, books: [] })
  }
)

export const fetchAllBookSeriesFx = createEffect(
  async ({ offset = 0, bookSeriesIds }: FetchAllBookSeriesFxParams) => {
    const res = await api.bookSeries.getAll({ offset, ids: bookSeriesIds.join(',') })
    const pages = res.pages
    const promises = []
    const bookSeries = res._embedded.bookSeries

    for (let i = 1; i < pages; i++) {
      promises.push(api.bookSeries.getAll({ offset: i * 20, ids: bookSeriesIds.join(',') }))
    }

    const responses = await Promise.all(promises).then((responses) =>
      //TODO: accumulator should be first then booksSeries in reducer callback arguments
      responses.reduce(
        (bookSeries, accumulator) => [...bookSeries, ...accumulator._embedded.bookSeries],
        bookSeries
      )
    )

    return responses
      .filter((bookSeries) => bookSeries.books.length > 0)
      .sort((a, b) => a.name.localeCompare(b.name))
  }
)

export const fetchFoundBooksFx = createEffect((searchTerm: string) => {
  return api.problems.elasticSearch({
    search: searchTerm,
    filterByTeacherLibrary: true,
    limit: 300,
    fetchProblemsObjects: true,
  })
})
