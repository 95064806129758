export enum AddStudentMethod {
  FORM = 'FORM',
  CODE = 'CODE',
  LIST = 'LIST',
}

export type Tab = {
  value: AddStudentMethod
  content: string
}

export enum DirectMethodTabStages {
  FORM = 'FORM',
  SUCCESS = 'SUCCESS',
}
