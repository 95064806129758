import React from 'react'
import useText from '../../../i18n/hook'
import styles from './ColorIndexMenu.module.scss'
import { getMenuContent } from './helpers'
import Popover from 'ui/Popover/Popover'
import ColorIndexButton from 'views/PieChart/NewPieChart/ColorIndexButton'

const ColorIndexMenu = () => {
  const t = useText()

  return (
    <Popover Button={ColorIndexButton} classes={{ Panel: styles.Panel }}>
      <ul className={styles.List}>
        {getMenuContent().map((infoItem) => (
          <li className={styles.Legend} key={infoItem.color}>
            <div className={styles.Circle} style={{ backgroundColor: infoItem.color }} />
            {infoItem.getTitle(t)}
          </li>
        ))}
      </ul>
    </Popover>
  )
}

export default ColorIndexMenu
