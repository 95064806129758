import * as React from 'react'
import { IStudent } from '../../../../api/types.users'
import StudentsOptionsMenuUpdated from '../StudentTableRow/StudentsOptionsMenuUpdated/StudentsOptionsMenuUpdated'
import StudentsTableUIRow from '../../../../ui/tables/StudentsTableUI/StudentsTableUIRow/StudentsTableUIRow'
import { IModalActions } from '../../../../redux/modules/modal.module'
import { IClass } from '../../../../api/types.classes'

type IStudentsTableRowProps = {
  student: IStudent
  modalActions: IModalActions
  classroom: IClass
  onClickShowQRCode: () => void
}

const StudentsTableRow: React.FC<IStudentsTableRowProps> = ({
  student,
  modalActions,
  classroom,
  onClickShowQRCode,
}) => {
  return (
    <StudentsTableUIRow
      student={student}
      isQRCodeEnabled={classroom.qrCodeLogin}
      onClickShowQRCode={onClickShowQRCode}
    >
      <StudentsOptionsMenuUpdated
        username={student.username!}
        modalActions={modalActions}
        student={student}
        classroom={classroom}
      />
    </StudentsTableUIRow>
  )
}

export default StudentsTableRow
