import React, { useState } from 'react'
import styles from './StudentsButtonBlock.module.scss'
import PrintClass from '../../print/PrintClass/PrintClass'
import { IModalActions } from '../../../redux/modules/modal.module'
import { shouldShowBtn } from '../../../lib/permissions'
import { hasDisplayName } from 'helpers/classesView.helpers'
import useText from '../../../i18n/hook'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import PlusIcon from '../../../ui/icons/PlusIcon/PlusIcon'
import { IClass } from '../../../api/types.classes'
import AddStudentToClassModal from 'features/modals/AddStudentToClassModal/AddStudentToClassModal'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import AddExistingStudentModal from 'features/modals/AddExistingStudentModal/AddExistingStudentModal'

type IStudentsButtonBlockProps = {
  classroom: IClass
  modalActions: IModalActions
}

const StudentsButtonBlock: React.FC<IStudentsButtonBlockProps> = ({ classroom, modalActions }) => {
  const t = useText()
  const me = useSelector((state: RootState) => state.auth.data.me)
  const localization = useSelector((state: RootState) => state.localization)
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false)
  const [isAddExistingStudentModalOpen, setIsAddExistingStudentModalOpen] = useState(false)
  const isRestrictedMunicipality = me?.municipality?.isPrivate

  const openAddStudentModal = () => setIsAddStudentModalOpen(true)

  const openClassStudentsList = () => {
    if (classroom._id) modalActions!.openChooseStudentsModalUpdated(classroom._id)
  }

  const handleAddStudentClick = () => {
    return isRestrictedMunicipality ? openClassStudentsList() : openAddStudentModal()
  }

  const handleAddExistingStudentClick = () => {
    setIsAddExistingStudentModalOpen(true)
    setIsAddStudentModalOpen(false)
  }

  return (
    <>
      <div className={styles.TitleWithIcon}>
        <PrintClass
          addedClass={classroom}
          title={hasDisplayName(classroom) ? classroom.displayName : classroom.name}
          localization={localization as any}
          isArchivedClass={classroom.archived}
          isQRLoginEnabled={classroom.qrCodeLogin}
        />
      </div>
      {shouldShowBtn(me.municipality.isPrivate, classroom.source) && (
        <Button
          size={SIZE.MEDIUM}
          variant={BUTTON_VARIANT.PRIMARY}
          color={BUTTON_COLOR.ORANGE}
          onClick={handleAddStudentClick}
          icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={14} />}
          iconAlign={ICON_ALIGNMENT.RIGHT}
        >
          {t.addStudent}
        </Button>
      )}
      <AddStudentToClassModal
        isOpen={isAddStudentModalOpen}
        onClose={() => setIsAddStudentModalOpen(false)}
        onAddExistingStudentClick={handleAddExistingStudentClick}
        classroom={classroom}
      />
      <AddExistingStudentModal
        isOpen={isAddExistingStudentModalOpen}
        onClose={() => setIsAddExistingStudentModalOpen(false)}
      />
    </>
  )
}

export default StudentsButtonBlock
