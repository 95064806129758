import { combine, createEvent, restore, sample } from 'effector'
import { Class, SelectionUpdateEvent } from '../types'
import { selectAll, unselectAll } from './events'
import { $students, setStudents } from './students'
import { $groups, setGroupItemSelected } from './groups'

export const setClass = createEvent<Class | null>()
export const unselectClass = createEvent()
export const selectAllClass = createEvent()
export const setClassSelected = createEvent<SelectionUpdateEvent | null>()

export const $class = restore(setClass, null)
  .on(selectAll, (state) => (state ? { ...state, selected: true } : state))
  .on(unselectAll, (state) => (state ? { ...state, selected: false } : state))
  .on(unselectClass, (state) => (state ? { ...state, selected: false } : state))
  .on(selectAllClass, (state) => (state ? { ...state, selected: true } : state))

sample({
  source: { class: $class, students: $students },
  clock: setClassSelected,
  fn: (source, payload) => ({ ...source, payload }),
}).watch(({ class: classItem, students, payload }) => {
  if (!payload || !classItem) return
  const allStudentsSelected = students.every((student) => student.selected)
  setClass({ ...classItem, selected: payload.selected && allStudentsSelected })
})

sample({
  source: { groups: $groups, students: $students },
  clock: setGroupItemSelected,
  fn: (source, { id }) => ({ ...source, id }),
}).watch(({ groups, students, id }) => {
  const group = groups.find((group) => group.id === id)
  if (!group) return

  const allGroupsSelected = groups.every(({ selected }) => selected)
  setClassSelected({ id: group.classId, selected: allGroupsSelected })

  setStudents(
    students.map((student) => {
      const studentGroups = groups.filter((group) => group.studentIds.includes(student.id))
      if (!studentGroups?.length) return student
      const isSelected = studentGroups.some((studentGroup) =>
        studentGroup.id === id ? group.selected : studentGroup.selected
      )

      return {
        ...student,
        selected: isSelected,
      }
    })
  )
})

combine($groups, $students).watch(([groups, students]) => {
  const areAllStudentsAssigned = students.every((student) => student.selected)
  const areAllGroupsAssigned = groups
    .filter((group) => group.studentIds.length)
    .every((group) => group.selected)

  if (!areAllStudentsAssigned) {
    return unselectClass()
  }

  if (areAllGroupsAssigned && areAllStudentsAssigned) {
    return selectAllClass()
  }
})
