import { Button, BUTTON_VARIANT, ICON_ALIGNMENT } from '@magmamath/ui'
import { IMe } from 'api/types.users'
import { localStorageKeys } from 'config/localStorageKeys'
import { useUnit } from 'effector-react'
import { AnimatePresence, motion } from 'framer-motion'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import React, { useEffect, useState } from 'react'
import { IMyBook } from '../../api/types.books'
import useText from '../../i18n/hook'
import PlusIcon from '../../ui/icons/PlusIcon/PlusIcon'
import Book from './Book/Book'
import styles from './Bookshelf.module.scss'
import BookshelfSkeleton from './BookshelfSkeleton/BookshelfSkeleton'
import BooksModal from './BooksModal/BooksModal'
import { getBookSeriesIds } from './BooksModal/helpers'
import { BOOKSHELF_ID } from './constants'
import { $allBooks } from './model/allBooks'
import { $foundBooks, $isFoundBooksLoading, $isSearchActive } from './model/foundBooks'
import { $areMyBooksLoading, $myBooks, initBooks } from './model/myBooks'
import { resetOpenedBooks } from './model/openedBooks'
import { fetchAllBooksFx } from './model/requests'
import SearchResults from './SearchResults/SearchResults'
import SearchResultsSkeleton from './SearchResultsSkeleton/SearchResultsSkeleton'

const Bookshelf = () => {
  const [isMoreBooksModalOpen, setIsMoreBooksModalOpen] = useState(false)
  const myBooks = useUnit($myBooks)
  const areMyBooksLoading = useUnit($areMyBooksLoading)
  const isSearchActive = useUnit($isSearchActive)
  const allBooks = useUnit($allBooks)
  const foundBooks = useUnit($foundBooks)
  const me: IMe = getItemFromLocalStorageWithParsing(localStorageKeys.me)
  const t = useText()
  const bookSeriesIds = getBookSeriesIds(me)
  const isSearchResultsLoading = useUnit($isFoundBooksLoading)

  useEffect(() => {
    const fetchAllBookSeries = async () => {
      if (!allBooks) {
        await fetchAllBooksFx({ bookSeriesIds })
      }
    }
    fetchAllBookSeries()
    initBooks()

    return () => {
      resetOpenedBooks()
    }
  }, [])

  const handleCloseMoreBooksModal = () => {
    setIsMoreBooksModalOpen(false)
  }

  const handleOpenMoreBooksModal = () => {
    setIsMoreBooksModalOpen(true)
  }

  if (areMyBooksLoading) {
    return <BookshelfSkeleton className={styles.Skeleton} />
  }

  if (isSearchResultsLoading || (foundBooks === null && isSearchActive)) {
    return <SearchResultsSkeleton className={styles.Skeleton} />
  }

  return (
    <div className={styles.Bookshelf} id={BOOKSHELF_ID}>
      {isSearchActive ? (
        <SearchResults />
      ) : (
        <AnimatePresence>
          {(myBooks ?? []).map((book: IMyBook) => (
            <motion.div
              key={book._id}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.17 }}
            >
              <Book title={book.name} id={book._id} image={book.image} key={book._id} />
            </motion.div>
          ))}
        </AnimatePresence>
      )}
      {!isSearchActive && (
        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          iconAlign={ICON_ALIGNMENT.RIGHT}
          classes={{ button: styles.AddMoreBooks }}
          icon={<PlusIcon color='var(--icon-color)' size={11} />}
          onClick={handleOpenMoreBooksModal}
        >
          {t.addMoreBooks}
        </Button>
      )}
      {isMoreBooksModalOpen && (
        <BooksModal isOpen={isMoreBooksModalOpen} onClose={handleCloseMoreBooksModal} />
      )}
    </div>
  )
}

export default Bookshelf
