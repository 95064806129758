import { Button, BUTTON_VARIANT, Modal, SIZE } from '@magmamath/ui'
import { useUnit } from 'effector-react'
import {
  getAddedBooks,
  getBookSeriesIds,
  getFormattedBookSeries,
  getRemovedBooks,
} from 'features/bookshelf/BooksModal/helpers'
import { toast } from 'features/ToastModel/ToastModel'
import useText from 'i18n/hook'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import Skeleton from 'ui/skeleton/Skeleton'
import { IBook } from '../../../api/types.books'
import { $allBooks, $allBooksLoading } from '../model/allBooks'
import { $allBookSeries, $allBookSeriesLoading } from '../model/bookSeries'
import { $myBooks, booksModel } from '../model/myBooks'
import { fetchAllBookSeriesFx, fetchAllBooksFx } from '../model/requests'
import styles from './BooksModal.module.scss'
import { addBooks, removeBooks } from './requests'
import SearchableList from './SearchableList/SearchableList'

type MoreBooksModalProps = {
  isOpen: boolean
  onClose: () => void
}

const BooksModal = ({ isOpen, onClose }: MoreBooksModalProps) => {
  const [selectedItems, setSelectedItems] = useState(new Map<string, IBook>())

  const myBooks = useUnit($myBooks)
  const books = useUnit($allBooks)
  const bookSeries = useUnit($allBookSeries)
  const areAllBooksLoading = useUnit($allBooksLoading)
  const areAllBookSeriesLoading = useUnit($allBookSeriesLoading)

  const isLoading = areAllBooksLoading || areAllBookSeriesLoading

  const me = useSelector((state: RootState) => state.auth.data.me)
  const bookSeriesIds = getBookSeriesIds(me)
  const t = useText()

  const initiallySelected = (myBooks || []).reduce<Map<string, IBook>>((acc, book) => {
    acc.set(book._id, book)
    return acc
  }, new Map())

  useEffect(() => {
    setSelectedItems(initiallySelected)
    if (!bookSeriesIds.length) {
      return
    }

    if (!books) {
      fetchAllBooksFx({ bookSeriesIds })
    }

    if (!bookSeries) {
      fetchAllBookSeriesFx({ bookSeriesIds })
    }
  }, [])

  const isSelectedItemsUpdated =
    [...selectedItems.keys()].find((item) => !initiallySelected.has(item)) ||
    [...initiallySelected.keys()].find((item) => !selectedItems.has(item))

  const formattedBookSeries = getFormattedBookSeries(bookSeries || [], books || [])

  const handleCancel = () => {
    setSelectedItems(initiallySelected)
    onClose()
  }

  const handleSave = () => {
    if (!isSelectedItemsUpdated) {
      onClose()
      return
    }

    const removedBooks = getRemovedBooks(initiallySelected, selectedItems)
    const addedBooks = getAddedBooks(initiallySelected, selectedItems)

    if (removedBooks.length) {
      removeBooks(removedBooks)
    }
    addBooks(addedBooks.map((book) => book._id))
    booksModel.removed(removedBooks)
    booksModel.added(addedBooks)
    toast.confirmation({ props: { title: t.updateBooksSuccessTxt } })
    onClose()
  }

  const handleSelect = useCallback(
    (id: string) => {
      setSelectedItems((prevSelectedItems) => {
        const currentSelectedItems = new Map(prevSelectedItems)
        if (currentSelectedItems.has(id)) {
          currentSelectedItems.delete(id)
          return currentSelectedItems
        }

        const addedBook = books?.find((book) => book._id === id)

        if (addedBook) {
          currentSelectedItems.set(addedBook._id, addedBook)
        }
        return currentSelectedItems
      })
    },
    [books]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title={t.moreBooks} />
      <Modal.Content>
        {isLoading ? (
          <Skeleton className={styles.Skeleton} />
        ) : (
          <SearchableList
            bookItems={formattedBookSeries}
            selectedItems={selectedItems}
            onClickItem={handleSelect}
          />
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button size={SIZE.LARGE} onClick={handleCancel} variant={BUTTON_VARIANT.TERTIARY}>
          {t.cancelTxt}
        </Button>
        <Button size={SIZE.LARGE} onClick={handleSave} disabled={!isSelectedItemsUpdated}>
          {t.saveTxt}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BooksModal
