import { IMyBook } from 'api/types.books'
import { BookAction } from './types'

export const getUpdatedBooksIds = (
  books: IMyBook[],
  bookAction: BookAction,
  bookId: string
): string[] => {
  const bookIndex = books.findIndex((book) => book._id === bookId)
  if (bookIndex === -1) return books.map((book) => book._id)

  const updatedBooks = [...books]
  const bookToMove = updatedBooks[bookIndex]

  const actionsMap = {
    [BookAction.MOVE_UP]: () => {
      if (bookIndex > 0) {
        updatedBooks.splice(bookIndex, 1)
        updatedBooks.splice(bookIndex - 1, 0, bookToMove)
      }
    },
    [BookAction.MOVE_DOWN]: () => {
      if (bookIndex < updatedBooks.length - 1) {
        updatedBooks.splice(bookIndex, 1)
        updatedBooks.splice(bookIndex + 1, 0, bookToMove)
      }
    },
    [BookAction.MOVE_TO_TOP]: () => {
      if (bookIndex > 0) {
        updatedBooks.splice(bookIndex, 1)
        updatedBooks.unshift(bookToMove)
      }
    },
    [BookAction.MOVE_TO_BOTTOM]: () => {
      if (bookIndex < updatedBooks.length - 1) {
        updatedBooks.splice(bookIndex, 1)
        updatedBooks.push(bookToMove)
      }
    },
    [BookAction.DELETE]: () => {
      updatedBooks.splice(bookIndex, 1)
    },
  }

  actionsMap[bookAction]?.()
  return updatedBooks.map((book) => book._id)
}
