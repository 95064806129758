import React, { useEffect, useState } from 'react'
import styles from './AddYourFirstStudent.module.scss'
import { IModalActions } from '../../../redux/modules/modal.module'
import { IMe } from '../../../api/types.users'
import { ILocalization } from '../../../config/languages.config'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import PlusIcon from '../../../ui/icons/PlusIcon/PlusIcon'
import useText from '../../../i18n/hook'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'
import AddStudentToClassModal from 'features/modals/AddStudentToClassModal/AddStudentToClassModal'
import AddExistingStudentModal from 'features/modals/AddExistingStudentModal/AddExistingStudentModal'
import { studentsTabModel } from 'features/tables/StudentsTable/model'
import { useParams } from 'react-router'
import { useUnit } from 'effector-react'
import Spinner from 'features/Spinner/Spinner'

type IAddYourFirstStudentProps = {
  localization: ILocalization
  classOrGroup: IClass | IGroup
  modalActions: IModalActions
  me: IMe
}

const AddYourFirstStudent: React.FC<IAddYourFirstStudentProps> = ({
  classOrGroup,
  me,
  modalActions,
  localization,
}) => {
  const t = useText()
  const isClassView = 'invitationalCode' in classOrGroup
  const isRestrictedMunicipality = me?.municipality?.isPrivate
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false)
  const [isAddExistingStudentModalOpen, setIsAddExistingStudentModalOpen] = useState(false)
  const { id: classId } = useParams<{ id: string }>()
  const classroom = useUnit(studentsTabModel.$class)

  useEffect(() => {
    studentsTabModel.fetchClassByIdFx(classId)
  }, [classId])

  const signUpStudent = () => setIsAddStudentModalOpen(true)

  const openGroupStudentsList = () => {
    const students: any = classOrGroup?.students
    modalActions!.openGroupStudentAutosuggest(students, classOrGroup._id, localization, history)
  }

  const openClassStudentsList = () => {
    if (classOrGroup._id) modalActions!.openChooseStudentsModalUpdated(classOrGroup._id)
  }

  const handleAddExistingStudentClick = () => {
    setIsAddExistingStudentModalOpen(true)
    setIsAddStudentModalOpen(false)
  }

  const openAddStudentModal = () => {
    if (isClassView) {
      if (isRestrictedMunicipality) {
        return openClassStudentsList()
      }
      return signUpStudent()
    }

    return openGroupStudentsList()
  }

  if (!classroom) return <Spinner className={styles.Spinner} />

  return (
    <div className={styles.Container}>
      <Button
        size={SIZE.LARGE}
        variant={BUTTON_VARIANT.PRIMARY}
        color={BUTTON_COLOR.ORANGE}
        onClick={openAddStudentModal}
        icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={18} />}
        iconAlign={ICON_ALIGNMENT.RIGHT}
      >
        {t.addStudent}
      </Button>
      <AddStudentToClassModal
        isOpen={isAddStudentModalOpen}
        onClose={() => setIsAddStudentModalOpen(false)}
        onAddExistingStudentClick={handleAddExistingStudentClick}
        classroom={classroom}
      />
      <AddExistingStudentModal
        isOpen={isAddExistingStudentModalOpen}
        onClose={() => setIsAddExistingStudentModalOpen(false)}
      />
    </div>
  )
}

export default AddYourFirstStudent
