import { SignUpStudentPayload } from 'api/api.users'
import { ILocalization } from 'config/languages.config'

enum ErrorCause {
  EMAIL = 'email',
  USERNAME = 'username',
}

type SignUpStudentFxProps = {
  data: SignUpStudentPayload
  classId: string
  t: ILocalization['data']
}

type DeleteStudentFromClassFxProps = {
  classId: string
  studentId: string
  schoolId: string
}
type AddStudentToClassProps = {
  studentIds: string[]
  classId: string
  schoolId: string
}

export { SignUpStudentFxProps, ErrorCause, DeleteStudentFromClassFxProps, AddStudentToClassProps }
