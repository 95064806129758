export const formatAnswer = (answer: string): string => {
  return answer
    .split('')
    .map((char) => {
      if (char === ' ') {
        return '\\ '
      } else if (char === '%') {
        return '\\%'
      } else {
        return char
      }
    })
    .join('')
}
