import React from 'react'
import useText from 'i18n/hook'
import styles from './ListMethodTab.module.scss'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import EmailClasslistLink from 'components/EmailClasslist/EmailClasslist'

type ListMethodTabProps = {
  invitationalCode: number
  onAddExistingStudentClick: () => void
}

const ListMethodTab = ({ invitationalCode, onAddExistingStudentClick }: ListMethodTabProps) => {
  const t = useText()

  return (
    <div className={styles.ListMethodTab}>
      <div className={styles.ListWrapper}>
        <p className={styles.Paragraph}>{t.sendClasslistTxtTop}</p>
        <p className={styles.Paragraph}>{t.sendClasslistTxtBottom}</p>
        <ul className={styles.List}>
          <li className={styles.ListItem}>{t.firstNameTxt}</li>
          <li className={styles.ListItem}>{t.lastNameTxt}</li>
          <li className={styles.ListItem}>{t.userNameTxt}</li>
          <li className={styles.ListItem}>
            {t.theClassCodeTxt} {invitationalCode}
          </li>
          <li className={styles.ListItem}>{t.grade}</li>
        </ul>
      </div>
      <div className={styles.BtnsWrap}>
        <EmailClasslistLink externalClassName={styles.Link}>
          <Button variant={BUTTON_VARIANT.PRIMARY} size={SIZE.LARGE}>
            {t.sendEmailTxt}
          </Button>
        </EmailClasslistLink>

        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZE.MEDIUM}
          onClick={onAddExistingStudentClick}
        >
          {t.addExistingStudentTxt}
        </Button>
      </div>
    </div>
  )
}

export default ListMethodTab
