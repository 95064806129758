import { FormFields, FormState, InputState } from "./types"

export const defaultInputState: InputState = {
  value: '',
  error: null
}

export const defaultFormState: FormState = {
  [FormFields.FIRST_NAME]: defaultInputState,
  [FormFields.LAST_NAME]: defaultInputState,
  [FormFields.USERNAME]: defaultInputState,
  [FormFields.PASSWORD]: defaultInputState
}
