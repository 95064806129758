import React from 'react'
import styles from './CodeMethodTab.module.scss'
import useText from 'i18n/hook'
import { Button, BUTTON_VARIANT, SIZE, Typography } from '@magmamath/ui'
import { toast } from 'features/ToastModel/ToastModel'

type CodeMethodTabProps = {
  invitationalCode: number
  onAddExistingStudentClick: () => void
}

const CODE_PATTERN = /^(.{2})(.{2})(.*)$/
const CODE_FORMAT = '$1 $2 $3'

const CodeMethodTab = ({ invitationalCode, onAddExistingStudentClick }: CodeMethodTabProps) => {
  const t = useText()

  const copyCode = () => {
    toast.confirmation({ props: { title: t.copied } })
    if (invitationalCode) navigator.clipboard.writeText(invitationalCode.toString())
  }

  return (
    <div className={styles.ClassCodeTab}>
      <p className={styles.Descrition}>{t.addStudentsWithCodeTxt}</p>
      {invitationalCode && (
        <Typography variant='h1' className={styles.Code}>
          {invitationalCode.toString().replace(CODE_PATTERN, CODE_FORMAT)}
        </Typography>
      )}
      <div className={styles.BtnsWrap}>
        <Button onClick={copyCode} variant={BUTTON_VARIANT.PRIMARY} size={SIZE.LARGE}>
          {t.copyCode}
        </Button>
        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZE.MEDIUM}
          onClick={onAddExistingStudentClick}
        >
          {t.addExistingStudentTxt}
        </Button>
      </div>
    </div>
  )
}

export default CodeMethodTab
