import { Button, BUTTON_VARIANT, COLORS, SIZE } from '@magmamath/ui'
import { Menu, MenuItem } from '@mui/material'
import clsx from 'clsx'
import { PATHS } from 'config/pathnames.config'
import { useUnit } from 'effector-react'
import { $myBooks } from 'features/bookshelf/model/myBooks'
import { updateBooksFx } from 'features/bookshelf/model/requests'
import { MuiMenuProps } from 'features/ExercisesList/components/ExerciseDropdown/MuiMenuProps'
import { toast } from 'features/ToastModel/ToastModel'
import useText from 'i18n/hook'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ArrowUpIcon from 'ui/icons/ArrowUpIcon/ArrowUpIcon'
import MoveToTopIcon from 'ui/icons/MoveToTopIcon/MoveToTopIcon'
import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import PencilIcon from 'ui/icons/PencilIcon/PencilIcon'
import RemoveBookIcon from 'ui/icons/RemoveBookIcon/RemoveBookIcon'
import { getUpdatedBooksIds } from './helpers'
import styles from './ReorderBooks.module.scss'
import { BookAction } from './types'

type ReorderBooksProps = {
  bookId: string
  bookTitle: string
}

const MENU_ITEM_ICON_SIZE = 16

const ReorderBooks = ({ bookId, bookTitle }: ReorderBooksProps) => {
  const history = useHistory()
  const location = useLocation()
  const t = useText()

  const myBooks = useUnit($myBooks)
  if (!myBooks) return null

  const isLast = myBooks[myBooks.length - 1]?._id === bookId
  const isFirst = myBooks[0]?._id === bookId
  const isMyBook = bookTitle === t.myBookTxt

  const bookActionMenuItems = [
    {
      action: BookAction.MOVE_TO_TOP,
      icon: <MoveToTopIcon color={COLORS.NEUTRAL_7} size={MENU_ITEM_ICON_SIZE} />,
      text: t.moveToTheTopTxt,
      isDisabled: isFirst,
    },
    {
      action: BookAction.MOVE_UP,
      icon: <ArrowUpIcon color={COLORS.NEUTRAL_7} size={MENU_ITEM_ICON_SIZE} />,
      text: t.moveUpTxt,
      isDisabled: isFirst,
    },
    {
      action: BookAction.MOVE_DOWN,
      icon: (
        <ArrowUpIcon color={COLORS.NEUTRAL_7} size={MENU_ITEM_ICON_SIZE} transform='rotate(180)' />
      ),
      text: t.moveDownTxt,
      isDisabled: isLast,
    },
    {
      action: BookAction.MOVE_TO_BOTTOM,
      icon: (
        <MoveToTopIcon
          color={COLORS.NEUTRAL_7}
          size={MENU_ITEM_ICON_SIZE}
          transform='rotate(180)'
        />
      ),
      text: t.moveToTheBottomTxt,
      isDisabled: isLast,
    },
    {
      action: BookAction.DELETE,
      icon: <RemoveBookIcon color={COLORS.RED_6} size={MENU_ITEM_ICON_SIZE} />,
      text: t.removeFromLibraryTxt,
      isDisabled: isMyBook,
      className: styles.Remove,
    },
  ]

  const onEditMyBookClick = (closePopup: () => void) => {
    closePopup()
    history.push({
      pathname: PATHS.CONTENT.PROBLEMS,
      state: { prevPath: location.pathname },
    })
  }

  const onBookActionClick = useCallback(
    async (action: BookAction, closePopup: () => void) => {
      closePopup()
      const updatedBooksIds = getUpdatedBooksIds(myBooks, action, bookId)
      try {
        await updateBooksFx(updatedBooksIds)
        toast.confirmation({ props: { title: t.updateBooksSuccessTxt } })
      } catch (error) {
        toast.error({ props: { title: t.updateBooksFailTxt } })
      }
    },
    [myBooks, bookId, t]
  )

  return (
    <span className={styles.DropdownMenu}>
      <PopupState variant='popover' popupId={bookId}>
        {(popupState) => (
          <>
            <Button
              classes={{ button: clsx(styles.OptionsButton, { [styles.Open]: popupState.isOpen }) }}
              {...bindTrigger(popupState)}
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZE.MEDIUM}
              icon={<OptionIcon color={COLORS.NEUTRAL_7} size={20} />}
              data-color='transparent'
            />
            <Menu
              sx={MuiMenuProps.sx}
              {...bindMenu(popupState)}
              anchorReference='anchorEl'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{ style: { transform: 'translate(6px, 4px)' } }}
            >
              {isMyBook && (
                <MenuItem
                  className={clsx(styles.MenuItem, styles.Edit)}
                  onClick={() => onEditMyBookClick(popupState.close)}
                >
                  <PencilIcon color={COLORS.NEUTRAL_7} size={MENU_ITEM_ICON_SIZE} />
                  <span>{t.edit}</span>
                </MenuItem>
              )}
              {bookActionMenuItems.map((item) => (
                <MenuItem
                  key={item.action}
                  className={clsx(styles.MenuItem, item.className)}
                  disabled={item.isDisabled}
                  onClick={() => onBookActionClick(item.action, popupState.close)}
                >
                  {item.icon}
                  <span>{item.text}</span>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </PopupState>
    </span>
  )
}

export default ReorderBooks
