import { ValidationStatus } from '@magmamath/ui'
import { ILocalization } from 'config/languages.config'
import { FormFields, ValidateFormProps } from './model/types'
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, USERNAME_MIN_LENGTH } from './constants'

export const validateFieldValue = ({ filedName, value, t }: ValidateFormProps) => {
  switch (filedName) {
    case FormFields.FIRST_NAME:
    case FormFields.LAST_NAME:
      if (!value) {
        return t.requiredFieldText
      }
      return null
    case FormFields.USERNAME:
      if (!value) {
        return t.requiredFieldText
      }
      if (value.length < USERNAME_MIN_LENGTH) {
        return t.mustBeLongerUsername
      }
      return null
    case FormFields.PASSWORD:
      if (!value) {
        return t.requiredFieldText
      }
      if (value.length < PASSWORD_MIN_LENGTH) {
        return t.mustBeGreatPassword
      }
      if (value.length > PASSWORD_MAX_LENGTH) {
        return t.mustBeLessTwenty
      }
      return null
    default:
      return null
  }
}

export const getInputValidationStatus = (error: string | null, value: string) => {
  if (!value && !error) {
    return undefined
  }

  return error ? ValidationStatus.ERROR : ValidationStatus.SUCCESS
}

export const getInputLabel = (fieldName: FormFields, t: ILocalization['data']) => {
  const placeholders: Record<FormFields, string> = {
    [FormFields.FIRST_NAME]: t.firstNameTxt,
    [FormFields.LAST_NAME]: t.lastNameTxt,
    [FormFields.USERNAME]: t.userNameTxt,
    [FormFields.PASSWORD]: t.passwordTxt,
  }

  return placeholders[fieldName]
}
