import { createEvent, restore, sample } from 'effector'
import { Group, SelectionUpdateEvent } from '../types'
import { selectAll, unselectAll } from './events'
import { $students, setStudentSelected, setStudents } from './students'

export const setGroups = createEvent<Group[]>()
export const setGroupsSelected = createEvent<{
  groupIds: string[]
  selected: boolean
  studentId: string
}>()
export const selectAllGroups = createEvent()
export const setGroupItemSelected = createEvent<SelectionUpdateEvent>()

export const $groups = restore(setGroups, [])
  .on(
    selectAll,
    (state) => state.map((group) => ({ ...group, selected: !!group.studentIds.length })) // skip empty groups
  )
  .on(unselectAll, (state) => state.map((group) => ({ ...group, selected: false })))
  .on(
    selectAllGroups,
    (state) => state.map((group) => ({ ...group, selected: !!group.studentIds.length })) // skip empty groups
  )
  .on(setGroupItemSelected, (state, { id, selected }) => {
    return state.map((group) => {
      if (group.id === id) {
        return { ...group, selected }
      }
      return group
    })
  })


sample({
  source: { groups: $groups, students: $students },
  clock: setGroupsSelected,
  fn: (source, payload) => ({ ...source, ...payload }),
}).watch(({ groups, students, groupIds, studentId, selected }) => {
  setGroups(
    groups.map((group) => {
      if (!groupIds.includes(group.id)) return group

      const groupStudents = students.filter((student) => group.studentIds.includes(student.id))

      const isSelected = groupStudents.every((student) =>
        student.id === studentId ? selected : student.selected
      )

      return {
        ...group,
        selected: isSelected,
      }
    })
  )
})

sample({
  source: $groups,
  clock: setStudentSelected,
  fn: (groups, { id: studentId, selected }) => {
    const studentGroupsIds = groups
      .filter((group) => group.studentIds.includes(studentId))
      .map(({ id }) => id)

    return {
      groupIds: studentGroupsIds,
      selected,
      studentId,
    }
  },
  target: setGroupsSelected,
})
