import { COLORS } from '@magmamath/ui'

export const MuiMenuProps = {
  sx: {
    '& .MuiMenu-paper': {
      borderRadius: 'var(--radius-300)',
      boxShadow: 'var(--shadow-4)',
    },

    '& .MuiList-padding': {
      padding: '6px 0',
    },

    '& .MuiDivider-root': {
      margin: 'var(--spacing-50) var(--spacing-200) !important',
    },

    '& .MuiMenuItem-root': {
      height: '37px',
      color: COLORS.NEUTRAL_9,
      backgroundColor: `${COLORS.NEUTRAL_1}!important`,
      padding: '0 var(--spacing-200)',
      margin: 'var(--spacing-50) var(--spacing-200)',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '22px',
      justifyContent: 'left',

      '&:hover': {
        borderRadius: 'var(--radius-200)',
        color: COLORS.PRIMARY_ORANGE,
        backgroundColor: `${COLORS.ORANGE_1}!important`,

        path: {
          fill: COLORS.PRIMARY_ORANGE,
        },
      },

      '&:last-child': {
        marginTop: 'var(--spacing-50)',
        color: COLORS.RED_6,

        '&:hover': {
          backgroundColor: `${COLORS.RED_1}!important`,

          'path[stroke]': {
            fill: 'unset',
          },
          'path:not([stroke])': {
            fill: COLORS.RED_6,
          },
        },
      },
    },

    '& .MuiMenuItem-root.Mui-selected': {
      color: COLORS.PRIMARY_BLUE,
      backgroundColor: `${COLORS.BLUE_1}!important`,
    },
  },
}
