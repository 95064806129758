export const getIdFromURL = (pathname: string) => {
  const pattern = /\/([a-f\d]{24})(?:\?|$)/
  const match = pathname.match(pattern)
  return match ? match[1] : null
}

export const hasSearchedValue = (items: string[], searchValue: string) => {
  const trimmedSearchValue = searchValue.trim().toLowerCase()
  const filteredItems = items.filter((item) =>
    (item ?? '').toLowerCase().includes(trimmedSearchValue)
  )
  return filteredItems.length > 0
}
