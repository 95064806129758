import { IStudent } from 'api/types.users'
import React, { FC, forwardRef } from 'react'
import QRCode from 'react-qr-code'
import styles from './ComponentToPrint.module.scss'
import clsx from 'clsx'
import { getFullName } from 'helpers/user.helpers'
import { IClass } from '../../api/types.classes'
import FullLogoIcon from 'ui/icons/FullLogoIcon/FullLogoIcon'
import { STUDENT_PER_PAGE_LIST } from 'features/print/PrintClass/constants'
import { LayoutVariant } from 'features/print/PrintClass/types'
import useText from 'i18n/hook'

export interface IComponentToPrintClassProps {
  qrCodesData: { userId: string; key: string }[] | null
  printedClass: IClass
  title: string
  layout: LayoutVariant | null
}

const QR_CODE_STYLES = {
  size: 120,
  containerStyle: {
    height: 'auto',
    maxWidth: '50mm',
    width: '100%',
  },
  svgStyle: {
    height: 'auto',
    maxWidth: '100%',
    width: '100%',
    margin: '5px 0',
  },
  viewBox: '0 0 120 120',
}

const PageTitle: FC<Pick<IComponentToPrintClassProps, 'title' | 'printedClass'>> = React.memo(
  ({ title, printedClass }) => {
    const t = useText()

    return (
      <div className={styles.TitleWrapper}>
        <h5 className={styles.OverlayTitle}>{title}</h5>
        <p className={styles.OverlayTitle}>
          <span>{t.classcode}:</span> <span>{printedClass.invitationalCode}</span>
        </p>
      </div>
    )
  }
)

const StudentListPages: FC<Pick<IComponentToPrintClassProps, 'title' | 'printedClass'>> =
  React.memo(({ printedClass, title }) => {
    const numPagesList = Math.ceil(printedClass.students.length / STUDENT_PER_PAGE_LIST)

    return (
      <>
        {Array.from({ length: numPagesList }).map((_, pageIndex) => (
          <div key={pageIndex}>
            <div className={clsx(styles.Page, styles.PageSize)}>
              <PageTitle title={title} printedClass={printedClass} />
              <ol className={styles.OverlayList} start={pageIndex * STUDENT_PER_PAGE_LIST + 1}>
                {printedClass.students
                  .slice(pageIndex * STUDENT_PER_PAGE_LIST, (pageIndex + 1) * STUDENT_PER_PAGE_LIST)
                  .map((student: IStudent) => (
                    <li key={student._id}>
                      <div className={clsx(styles.ListLinkItem, styles.StudentRow)}>
                        <div>
                          {student.firstName && student.lastName && (
                            <span>{getFullName(student.firstName, student.lastName)} </span>
                          )}
                          ({student.username || student.email})
                        </div>
                      </div>
                    </li>
                  ))}
              </ol>
            </div>
            {pageIndex < numPagesList - 1 && (
              <>
                <div className={styles.PageBreak} />
                <div className={clsx(styles.ItemBreak, styles.RemovedItemBreak)} />
              </>
            )}
          </div>
        ))}
      </>
    )
  })

const StudentQRCodesListPages: FC<
  Pick<IComponentToPrintClassProps, 'title' | 'printedClass' | 'layout' | 'qrCodesData'>
> = React.memo(({ title, layout, printedClass, qrCodesData }) => {
  const layoutNumber = Number(layout)

  const numPagesQR = layoutNumber ? Math.ceil(printedClass.students.length / layoutNumber) : 0

  return (
    <>
      {Array.from({ length: numPagesQR }).map((_, pageIndex) => (
        <div key={pageIndex}>
          <div className={clsx(styles.Page, styles.PageSize)}>
            <PageTitle title={title} printedClass={printedClass} />
            <div className={styles.StudentBlockContainer}>
              {printedClass.students
                .slice(pageIndex * layoutNumber!, (pageIndex + 1) * layoutNumber!)
                .map((student: IStudent) => {
                  const qrCodeObject = qrCodesData!.find((qrCode) => qrCode.userId === student._id)
                  const qrCodeString = qrCodeObject?.key || null

                  return (
                    <div
                      key={student._id}
                      className={clsx(
                        styles.StudentBlock,
                        layoutNumber === 1 ? styles.SingleBlockPerPage : styles.SeveralBlocksPerPage
                      )}
                    >
                      <div className={styles.StudentFullName}>
                        {student.firstName &&
                          student.lastName &&
                          getFullName(student.firstName, student.lastName)}
                      </div>
                      <div>({student.username || student.email})</div>
                      <div className={styles.QRCodeContainer} style={QR_CODE_STYLES.containerStyle}>
                        {qrCodeString && (
                          <QRCode
                            size={QR_CODE_STYLES.size}
                            style={QR_CODE_STYLES.svgStyle}
                            value={qrCodeString}
                            viewBox={QR_CODE_STYLES.viewBox}
                          />
                        )}
                      </div>
                      <div className={styles.MagmaIcon}>
                        <FullLogoIcon size={23} />
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
          {pageIndex < numPagesQR - 1 && (
            <>
              <div className={styles.PageBreak} />
              <div className={clsx(styles.ItemBreak, styles.RemovedItemBreak)} />
            </>
          )}
        </div>
      ))}
    </>
  )
})

const ComponentToPrintClass = forwardRef<HTMLDivElement, IComponentToPrintClassProps>(
  ({ qrCodesData, printedClass, title, layout }, ref) => {
    return (
      <div className={styles.PrintContent} ref={ref}>
        {printedClass.qrCodeLogin && qrCodesData ? (
          <StudentQRCodesListPages
            title={title}
            layout={layout!}
            printedClass={printedClass}
            qrCodesData={qrCodesData}
          />
        ) : (
          <StudentListPages title={title} printedClass={printedClass} />
        )}
      </div>
    )
  }
)

export default ComponentToPrintClass
