import { ModalType, RemovableTypes, ShareMultiplicityType } from 'helpers/enums'
import { preventRepeatingItems } from 'helpers/exercise.helpers'
import { checkSafari } from 'helpers/general'
import { sortABC } from 'helpers/sort.helpers'
import { IState } from 'interfaces/state.interface'
import { IMe, ITeacher } from 'api/types.users'
import _ from 'lodash'
import * as React from 'react'
import * as Autosuggest from 'react-autosuggest'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import { IClassActions } from 'redux/modules/class.module'
import { IGroupActions } from 'redux/modules/group.module'
import { IModal, IModalActions } from 'redux/modules/modal.module'
import { ITeachersActions } from 'redux/modules/schoolTeachers.module'
import { ScrollableContainer } from '../ScrollableContainer.component'
import { IExerciseActions } from '../../../../redux/modules/exercise.module'
import './../../modals.scss'
import { RouteComponentProps } from 'react-router'
import { ReactComponent as CloseIcon } from '../../../../assets/img/exit.svg'
import { ReactComponent as SelectedIcon } from '../../../../assets/img/selected.svg'
import PrimaryNormalButton from '../../../basic/Buttons/PrimaryNormalButton/PrimaryNormalButton'
import ModalAutosuggestClearButton from '../ModalAutosuggestClearSearchButton/ModalAutosuggestClearButton'
import '../ModalAutosuggestWindow.scss'
import { getFullName } from 'helpers/user.helpers'
import { IExercise } from '../../../../api/types.assignments'
import { IClass } from '../../../../api/types.classes'
import { IGroup } from '../../../../api/api.groups'

export interface IPopupTeachersAutosuggestProps {
  modalActions?: IModalActions | undefined
  teachers: ITeacher[]
  localization?: any
  modal?: IState<IModal> | undefined
  history: RouteComponentProps['history']
  teachersArray?: string[]
  id: string | undefined
  textInput?: any
  addedClass: IState<IClass>
  group: IState<IGroup>
  me: IMe
  teachersActions: ITeachersActions
  classActions: IClassActions
  groupActions: IGroupActions
  exercise: IState<IExercise>
  exerciseActions: IExerciseActions
}

function escapeRegexCharacters(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export class PopupTeachersAutosuggest extends React.Component<IPopupTeachersAutosuggestProps, any> {
  public suggestions =
    this.props.teachers?.filter((teacher: ITeacher) => teacher._id !== this.props.me._id) ?? []

  public inputSearch: any

  constructor(props: any) {
    super(props)
    this.state = {
      isEnable: false,
      suggestions: sortABC(this.suggestions),
      teacherId: '',
      teachersArray: this.addedExistingTeachers(),
      value: '',
    }
  }

  public componentDidMount() {
    const { classActions, groupActions, modal } = this.props
    this.getGroupOrClass(modal!.data.id).then(() => {
      if (modal!.data.typeOfItem === RemovableTypes.Class) {
        this.updateSchoolUnits(modal!.data.id, classActions.getClass)
      } else if (modal!.data.typeOfItem === RemovableTypes.Group) {
        this.updateSchoolUnits(modal!.data.id, groupActions.getGroup)
      }
    })
  }

  public componentDidUpdate(
    prevProps: IPopupTeachersAutosuggestProps,
    prevState: IPopupTeachersAutosuggestProps
  ) {
    if (prevProps.addedClass !== this.props.addedClass || prevProps.group !== this.props.group) {
      this.setState({ teachersArray: _.union(this.addedExistingTeachers()) })
    }
  }

  public updateSchoolUnits = (id: string, func: any) => {
    if (!_.isEmpty(id)) {
      func(id)
    }
  }

  public addTeachersForExercises = (id: string) => {
    if ((this.state.teachersArray as string[]).includes(id)) {
      const joined = this.state.teachersArray?.filter((currId: string) => id !== currId)
      this.setState({ teachersArray: [...joined] })
    } else {
      this.setState({
        teachersArray: [...this.state.teachersArray, id],
      })
    }
  }

  public addedExistingTeachers = () => {
    const { modal } = this.props
    if (this.props.addedClass.data._id || this.props.group.data._id) {
      if (modal!.data.typeOfItem === RemovableTypes.Class) {
        const teachersIds = this.props.addedClass.data.teachers.map(
          (teacher: ITeacher) => teacher._id
        )

        return this.props.addedClass.data ? teachersIds : []
      } else if (modal!.data.typeOfItem === RemovableTypes.Group) {
        return this.props.group.data ? this.props.group.data.teachers : []
      }
    }
    return []
  }

  public getGroupOrClass = async (id: string) => {
    const { modal, classActions, groupActions } = this.props

    if (modal!.data.typeOfItem === RemovableTypes.Group) {
      groupActions.getGroup(id)
    } else if (modal!.data.typeOfItem === RemovableTypes.Class) {
      classActions.getClass(id)
    }
  }

  public changeTeacherId = (value: string) => {
    this.setState({ schoolId: value })
  }

  public toggle = () => {
    this.props.modalActions!.closeTeachAutosuggest()
  }

  public onSaveButtonPress = () => {
    const { modal, exerciseActions, teachersActions, localization, exercise } = this.props
    const typeOfItem = modal!.data.typeOfItem
    if (typeOfItem === RemovableTypes.Class || typeOfItem === RemovableTypes.Group) {
      teachersActions.share({
        subject: modal!.data.id,
        type:
          typeOfItem === RemovableTypes.Class
            ? ShareMultiplicityType.CLASS
            : ShareMultiplicityType.GROUP,
        to: this.state.teachersArray,
      })
    } else if (typeOfItem === RemovableTypes.Teachers) {
      const classroomIdsSet = new Set(exercise.data?.classes[0]?._id)
      exerciseActions.shareExercise(classroomIdsSet, exercise.data._id, {
        success: localization!.data.shareClassroomSuccess,
        error: localization!.data.shareClassroomError,
      })
    }
    this.setState({ teachersArray: [] })
  }

  public render() {
    const { value, suggestions } = this.state
    const { localization } = this.props
    const opened: boolean =
      (this.props.modal as any).data.modalType === ModalType.TeachersAutosuggestModal
    const isSafari: boolean = checkSafari()
    const { saveTxt, searchTxt, addTeachersTxt } = localization!.data
    const theme = {
      container: 'form-group',
      input: 'form-control',
      suggestionFocused: 'active',
    }
    return (
      <Modal
        className='colored-modal custom-modal autosuggest-user'
        fade={!isSafari}
        toggle={this.toggle}
        isOpen={opened}
      >
        <div className='add-existing-user-modal-header'>
          <span className='header-title'>{addTeachersTxt}</span>
          <button
            className='close-modal-button'
            onClick={() => {
              this.setState({ teachersArray: [] })
              this.props.modalActions!.closeTeachAutosuggest()
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <ModalBody className='custom-body body-autosuggest-user'>
          <>
            {value && <ModalAutosuggestClearButton onClick={this.onSearchClear} />}
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
              focusInputOnSuggestionClick={false}
              renderSuggestionsContainer={this.renderSuggestionsContainer as any}
              renderSuggestion={this.renderSuggestion}
              getSuggestionValue={this.getSuggestionValue}
              alwaysRenderSuggestions={true}
              ref={(input: any) => {
                this.inputSearch = input
              }}
              inputProps={{
                onChange: (e, changeEvent) => {
                  this.onChange(e, changeEvent)
                },
                placeholder: searchTxt,
                value,
              }}
              theme={theme}
            />
          </>
        </ModalBody>
        <div className='add-existing-user-modal-submit-container'>
          <PrimaryNormalButton title={saveTxt} onClick={this.onSaveButtonPress} />
        </div>
      </Modal>
    )
  }

  protected onSearchClear = () => {
    this.setState({ value: '' })
    this.onSuggestionsFetchRequested({ value: '' })
  }
  protected renderSuggestion = (suggestion: ITeacher): JSX.Element => {
    const { firstName, lastName } = suggestion
    const isSelected = _.includes(this.state.teachersArray, suggestion._id)
    return (
      <div
        className={`d-flex justify-content-between align-items-baseline user-wrapper ${
          isSelected ? 'selected' : ''
        }`}
        onClick={() => {
          this.addTeachersForExercises(suggestion._id)
        }}
      >
        <span>{`${firstName} ${lastName}`}</span>
        {_.includes(this.state.teachersArray, suggestion._id) ? (
          <div className='selected-icon'>
            <SelectedIcon />
          </div>
        ) : null}{' '}
      </div>
    )
  }

  protected onChange = (event: React.FormEvent<any>, { newValue }: Autosuggest.ChangeEvent) => {
    const teacher = _.find(this.suggestions, { fullName: newValue })
    if (teacher) {
      this.changeTeacherId((teacher as any)._id)
    }
    this.setState({ value: newValue })
  }

  protected onSuggestionsFetchRequested = async ({ value }: any) => {
    const markedSuggestions: any = (this.suggestions as any).filter((suggestion: any) => {
      return _.includes(this.state.teachersArray, suggestion._id)
    })

    const foundSuggestions: any = this.getSuggestions(value) as any
    this.setState({
      suggestions: sortABC(preventRepeatingItems([...markedSuggestions, ...foundSuggestions])),
    })
  }

  protected getSuggestions = (value: string): any => {
    const escapedValue = escapeRegexCharacters(value.trim())
    const regex = new RegExp(escapedValue, 'i')

    const checkValue = this.suggestions.some((teacher: any) => {
      return value === getFullName(teacher.firstName, teacher.lastName)
    })

    if (checkValue) {
      return sortABC(this.suggestions)
    } else {
      return sortABC(
        this.suggestions.filter((teacher) =>
          regex.test(getFullName(teacher.firstName, teacher.lastName))
        )
      )
    }
  }

  protected getSuggestionValue = () => {
    return this.state.value
  }

  protected renderSuggestionsContainer = ({ containerProps, children }: any) => {
    if (children === null) {
      const { emptyUsersSearchList, tryAnotherName } = this.props.localization!.data
      return (
        <div className='empty-results-container'>
          <span className='empty-results-text'>{emptyUsersSearchList}</span>
          <span className='empty-results-text'>{tryAnotherName}</span>
        </div>
      )
    }

    return <ScrollableContainer {...containerProps}>{children}</ScrollableContainer>
  }
}
