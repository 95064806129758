import { COLORS, Listbox, ModalSideBar, SelectValue, Typography } from '@magmamath/ui'
import styles from './PrintClassModal.module.scss'
import React, { FC, useMemo, useRef } from 'react'
import ComponentToPrintClass from 'components/ComponentToPrint/ComponentToPrintClass'
import { printClassModel } from './model'
import { useUnit } from 'effector-react'
import { CLASS_PRINT_LAYOUT_OPTIONS } from './constants'
import { ILocalization } from 'config/languages.config'
import { useReactToPrint } from 'react-to-print'
import { LayoutVariant, PrintClassType } from './types'
import useText from 'i18n/hook'

type PrintClassModalProps = {
  localization: ILocalization
  title: string
}

const PrintClassModal: FC<PrintClassModalProps> = ({ title }) => {
  const t = useText()
  const data = useUnit(printClassModel.$state)

  const componentClassRef = useRef<HTMLDivElement | null>(null)

  const handlePrint = useReactToPrint({
    content: () => componentClassRef.current,
    onAfterPrint: () => printClassModel.reset(),
  })

  const layoutValue = useMemo(() => {
    if (!data || data.type !== PrintClassType.QR) return null
    const currentSelcetion = CLASS_PRINT_LAYOUT_OPTIONS.find(
      (variant) => variant.value === data.layout
    )!

    return {
      ...currentSelcetion,
      name: t[currentSelcetion.name],
    }
  }, [data])

  return (
    <ModalSideBar
      classes={{
        modal: styles.PrintClassModal,
        dialog: styles.PrintClassDialog,
        sideBarContent: styles.SideBarContent,
      }}
      isOpen={!!data}
      onClose={printClassModel.reset}
      title={data && data.type === PrintClassType.QR ? t.printQRCodes : t.printList}
      actionButtonText={t.printTxt}
      cancelButtonText={t.cancelTxt}
      onActionButtonClick={handlePrint}
      mainContent={
        data ? (
          <ComponentToPrintClass
            printedClass={data.class}
            title={title}
            ref={componentClassRef}
            qrCodesData={data.type === PrintClassType.QR ? data.qrCodedata : null}
            layout={data.type === PrintClassType.QR ? data.layout : null}
          />
        ) : null
      }
      sideBarContent={
        data && data.type === PrintClassType.QR ? (
          <>
            <Typography variant='h8' style={{ color: COLORS.NEUTRAL_7 }}>
              {t.layoutTxt}
            </Typography>
            <div style={{ position: 'relative' }}>
              <Listbox
                value={layoutValue}
                placeholder={t.layoutTxt}
                multiple={false}
                isActive={false}
                classes={{
                  ListboxOptions: styles.LayoutSelectOptions,
                  Button: styles.LayoutSelectButton,
                }}
                onChange={(option: SelectValue) =>
                  printClassModel.setLayout(option.value as LayoutVariant)
                }
              >
                {CLASS_PRINT_LAYOUT_OPTIONS.map((option) => (
                  <Listbox.Option key={option.value} value={option}>
                    {t[option.name]}
                  </Listbox.Option>
                ))}
              </Listbox>
            </div>
          </>
        ) : null
      }
    />
  )
}

export default PrintClassModal
