import { api } from 'api'
import { IStudent } from 'api/types.users'
import { createEffect, createEvent, createStore, sample } from 'effector'

export type StudentQRState = {
  key: string
  studentName: string
}

export class studentQRModel {
  public static readonly reset = createEvent()

  public static initQRData = createEvent<IStudent>()

  public static fetchStudentQRFx = createEffect(api.users.getSingleStudentQRKey)

  public static $state = createStore<StudentQRState | null>(null)
    .on(this.fetchStudentQRFx.done, (_, { params, result }) => ({
      key: result.key,
      studentName: `${params.student.firstName ?? ''} ${params.student.lastName ?? ''}`.trim(),
    }))
    .reset(this.reset)
}

sample({
  clock: studentQRModel.initQRData,
  fn: (student: IStudent) => ({ student }),
  target: studentQRModel.fetchStudentQRFx,
})
