import { SelectValue } from '@magmamath/ui'
import { LayoutVariant } from './types'

export const CLASS_PRINT_LAYOUT_OPTIONS: SelectValue[] = [
  {
    value: LayoutVariant.SIX_PER_PAGE,
    name: 'sixCodesPerPage',
  },
  {
    value: LayoutVariant.ONE_PER_PAGE,
    name: 'oneCodePerPage',
  },
]

export const STUDENT_PER_PAGE_LIST = 22
