import { ILocalization } from 'config/languages.config'
import { AddStudentMethod, Tab } from './types'

export const getTabs = (t: ILocalization['data']): Tab[] => {
  return [
    {
      value: AddStudentMethod.FORM,
      content: t.addManuallyTxt,
    },
    {
      value: AddStudentMethod.CODE,
      content: t.classcode,
    },
    {
      value: AddStudentMethod.LIST,
      content: t.sendClasslist,
    },
  ]
}
