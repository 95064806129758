import { Button, BUTTON_VARIANT, COLORS, SIZE, Typography } from '@magmamath/ui'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import CrossIcon from 'ui/icons/CrossIcon/CrossIcon'
import { SPECIAL_BOOK_HREF } from '../constants'
import styles from './SpecialBooksInfo.module.scss'

const SpecialBooksInfo = () => {
  const [isVisible, setIsVisible] = useState(true)
  const t = useText()

  if (!isVisible) return null
  return (
    <div className={styles.SpecialBooks}>
      <Typography variant='h7' className={styles.Paragraph}>
        {t.specialBook}
        <a href={SPECIAL_BOOK_HREF} target='_blank' className={styles.Link}>
          {t.startguide}
        </a>
      </Typography>
      <Button
        classes={{ button: styles.CloseButton }}
        size={SIZE.SMALL}
        onClick={() => setIsVisible(false)}
        icon={<CrossIcon color={COLORS.NEUTRAL_1} size={14} strokeWidth={1.5} />}
        variant={BUTTON_VARIANT.TERTIARY}
      />
    </div>
  )
}

export default SpecialBooksInfo
