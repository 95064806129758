import { api } from '../../../../api'
import { CdnImageSettings } from '../../../../api/api.images'

export const getImageUrl = ({ imageOrigin, image }: { imageOrigin?: string; image?: string }) =>
  imageOrigin ? api.images.url(imageOrigin) : image

type GetProblemImageUrlProps = {
  image?: string
  cdnImage?: string
  imageOrigin?: string
  drawingAreaImage?: string
  cdnDrawingAreaImage?: string
  cdnImageOptions?: CdnImageSettings
}

export const getProblemImageUrl = ({
  image,
  cdnImage,
  imageOrigin,
  drawingAreaImage,
  cdnDrawingAreaImage,
  cdnImageOptions,
}: GetProblemImageUrlProps) => {
  if (cdnImage) {
    return api.images.cdnUrl(cdnImage, cdnImageOptions)
  }

  if (cdnDrawingAreaImage) {
    return api.images.cdnUrl(cdnDrawingAreaImage, cdnImageOptions)
  }

  return image
    ? getImageUrl({
        imageOrigin,
        image,
      })
    : drawingAreaImage
}
