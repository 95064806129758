import React from 'react'
import UIModal from '../../../ui/modals/UIModal/UIModal'
import styles from './CreateGroupModal.module.scss'
import useText from '../../../i18n/hook'
import CreateGroupForm from '../../Forms/CreateGroupForm/CreateGroupForm'

type CreateGroupModalProps = {
  open: boolean
  onClose: () => void
  classroomId: string
}
const CreateGroupModal = ({ open, onClose, classroomId }: CreateGroupModalProps) => {
  const t = useText()

  return (
    <UIModal open={open} onClose={onClose} wrapperClassName={styles.Wrapper}>
      <div className={styles.Content}>
        <p className={styles.Title}>{t.newGroupWithoutPlus}</p>
        <CreateGroupForm onClose={onClose} classroomId={classroomId} />
      </div>
    </UIModal>
  )
}
export default CreateGroupModal
