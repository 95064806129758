import CreateGroupModal from 'components/Modals/CreateGroupModal/CreateGroupModal'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import PlusIcon from 'ui/icons/PlusIcon/PlusIcon'
import GroupsTab from 'views/Classes/components/GroupsTab/GroupsTab'
import styles from './MultipleGroups.module.scss'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import { IClass } from 'api/types.classes'

type MultipleGroupsProps = {
  classroom: IClass
}

const MultipleGroups = ({ classroom }: MultipleGroupsProps) => {
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false)
  const t = useText()

  return (
    <>
      {classroom.groups.length > 0 && (
        <div className={styles.Header}>
          <Button
            classes={{
              button: styles.NewGroupBtn,
            }}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZE.MEDIUM}
            color={BUTTON_COLOR.ORANGE}
            onClick={() => setIsGroupModalOpen(true)}
            icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={14} />}
            iconAlign={ICON_ALIGNMENT.RIGHT}
          >
            {t.newGroupWithoutPlus}
          </Button>
        </div>
      )}

      <GroupsTab groups={classroom.groups} onEmptyGroupsButtonClick={() => setIsGroupModalOpen(true)} />

      <CreateGroupModal
        open={isGroupModalOpen}
        onClose={() => setIsGroupModalOpen(false)}
        classroomId={classroom._id}
      />
    </>
  )
}

export default MultipleGroups