import { Accordion } from '@magmamath/ui'
import { useUnit } from 'effector-react'
import React, { useEffect, useState } from 'react'
import { Element } from 'react-scroll'
import { LoadingStatus } from '../../../helpers/enums'
import { $isSearchActive } from '../model/foundBooks'
import { $openedBooks, closeAllBooks, closeBook, toggleBook } from '../model/openedBooks'
import styles from './Book.module.scss'
import BookContent from './BookContent/BookContent'
import BookNavigation from './BookNavigation/BookNavigation'
import BookTitle from './BookTitle/BookTitle'
import { scrollToOpenedBook } from './helpers'
import { $chapters } from './model/chapters'
import { $problems } from './model/problems'
import { fetchChaptersFx, fetchSectionsFx } from './model/requests'
import { $sections } from './model/sections'
import { $selectedProblems } from './model/selectedProblems'
import ReorderBooks from './ReorderBooks/ReorderBooks'
import { PROBLEM_LEVELS } from './SectionProblems/types'

type BookProps = {
  title: string
  id: string
  image?: string
  animated?: boolean
}

const EMPTY = '@@empty'

const Book = ({ title, id, image, animated = true }: BookProps) => {
  const [chaptersLoadingState, setChaptersLoadingState] = useState<LoadingStatus>(
    LoadingStatus.IDLE
  )
  const [sectionsLoadingState, setSectionsLoadingState] = useState<LoadingStatus>(
    LoadingStatus.IDLE
  )
  const [isBookOpen, setIsBookOpen] = useState(false)

  const problems = useUnit($problems)
  const selectedProblems = useUnit($selectedProblems)
  const openedBooks = useUnit($openedBooks)
  const chapters = useUnit($chapters)
  const sections = useUnit($sections)
  const isSearchActive = useUnit($isSearchActive)
  const bookChapters = chapters?.get(id)
  const openedBookPath = openedBooks.get(id)
  const openedChapter = bookChapters?.find((chapter) => chapter.id === openedBookPath?.chapter?.id)
  const openedChapterBreadcrumb = openedBookPath?.chapter
  const openedSectionBreadcrumb = openedBookPath?.section
  const openedSectionProblems = problems
    ?.get(openedSectionBreadcrumb?.id || EMPTY)
    ?.filter((problem) => problem.level <= PROBLEM_LEVELS.HARD)
  const selectedProblemsBySection =
    selectedProblems.get(openedSectionBreadcrumb?.id || EMPTY) || new Set()

  const isOpenedSectionChecked = openedSectionProblems?.length === selectedProblemsBySection.size
  const isOpenedSectionDisabled = !openedSectionProblems || openedSectionProblems.length === 0

  useEffect(() => {
    setIsBookOpen(openedBooks.has(id))
  }, [openedBooks])

  useEffect(() => {
    if (!isBookOpen) return
    if (bookChapters) {
      setChaptersLoadingState(LoadingStatus.LOADED)
      return
    }

    setChaptersLoadingState(LoadingStatus.LOADING)
    fetchChaptersFx(id).finally(() => setChaptersLoadingState(LoadingStatus.LOADED))
  }, [isBookOpen, bookChapters])

  useEffect(() => {
    if (!openedChapterBreadcrumb) {
      return
    }

    if (sections.get(openedChapter?.id || EMPTY)) {
      setSectionsLoadingState(LoadingStatus.LOADED)
      return
    }

    setSectionsLoadingState(LoadingStatus.LOADING)
    fetchSectionsFx(openedChapterBreadcrumb.id).finally(() => {
      setSectionsLoadingState(LoadingStatus.LOADED)
    })
  }, [openedChapterBreadcrumb])

  const toggleBookAccordion = () => {
    closeAllBooks()
    setIsBookOpen(true)
    toggleBook(id)
  }

  const handleBookClose = () => {
    setIsBookOpen(false)
    closeBook(id)
  }

  return (
    <Element name={`book-${id}`}>
      <Accordion
        title={<BookTitle title={title} image={image} />}
        options={<ReorderBooks bookId={id} bookTitle={title} />}
        classes={{
          Wrapper: styles.Accordion,
          Accordion: chaptersLoadingState === LoadingStatus.LOADING && styles.Loading,
        }}
        isOpen={isBookOpen && chaptersLoadingState === LoadingStatus.LOADED}
        onClick={toggleBookAccordion}
        onAfterEnterAnimation={() => {
          if (isSearchActive) return
          scrollToOpenedBook(id)
        }}
        animated={animated}
        header={
          <BookNavigation
            title={title}
            bookId={id}
            openedChapter={openedChapterBreadcrumb}
            openedSection={openedSectionBreadcrumb}
            isOpenedSectionChecked={isOpenedSectionChecked}
            isOpenedSectionDisabled={isOpenedSectionDisabled}
            onClickBookshelf={handleBookClose}
          />
        }
      >
        <BookContent
          bookId={id}
          openedBookPath={openedBookPath}
          chaptersLoadingState={chaptersLoadingState}
          sectionsLoadingState={sectionsLoadingState}
        />
      </Accordion>
    </Element>
  )
}

export default Book
