import React, { useEffect } from 'react'
import StudentsTableHeader from '../StudentsTable/StudentsTableHeader/StudentsTableHeader'
import StudentsTable from '../StudentsTable/StudentsTable'
import styles from '../../../screens/Students/Students.module.scss'
import ChangeMultiplicityAccess from '../ChangeMultiplicityAccess/ChangeMultiplicityAccess'
import ChangeMultiplicityInfo from '../ChangeClassInfo/ChangeClassInfo'
import { StudentsTab } from '../../../screens/Students/Students'
import { IModalActions } from '../../../redux/modules/modal.module'
import { IMe, ITeacher } from '../../../api/types.users'
import ChangeMultiplicitySettings from '../ChangeMultiplicitySetttings/ChangeMultiplicitySettings'
import { useUnit } from 'effector-react'
import { studentsTabModel } from '../StudentsTable/model'
import { useParams } from 'react-router'
import { IClass } from '../../../api/types.classes'
import { ISelectedOption } from 'redux/modules/filterExercises.module'
import { IGroup } from '../../../api/api.groups'
import GroupsTabContent from 'features/GroupsTab/GroupsTabContent/GroupsTabContent'

type IMultiplicityTableContainerProps = {
  classOrGroup: IClass | IGroup | null
  activeTab: StudentsTab | null
  modalActions: IModalActions
  me: IMe
  teachers: ITeacher[]
  selectedOption: ISelectedOption
}

const MultiplicityTableContainer: React.FC<IMultiplicityTableContainerProps> = ({
  classOrGroup,
  activeTab,
  modalActions,
  me,
  teachers,
  selectedOption,
}) => {
  const { id: classId } = useParams<{ id: string }>()
  const classroom = useUnit(studentsTabModel.$class)

  useEffect(() => {
    studentsTabModel.fetchClassByIdFx(classId)
  }, [classId])

  if (!(classOrGroup && selectedOption) || !classroom) {
    return null
  }

  return (
    <>
      {activeTab === StudentsTab.MULTIPLICITY && (
        <>
          <StudentsTableHeader classroom={classroom} modalActions={modalActions} />
          <StudentsTable modalActions={modalActions} classroom={classroom} />
        </>
      )}
      {activeTab === StudentsTab.SETTINGS && (
        <div className={styles.SettingsWrapper}>
          <ChangeMultiplicityAccess classOrGroup={classOrGroup} teachers={teachers} me={me} />
          <ChangeMultiplicitySettings classOrGroup={classOrGroup} />
          <ChangeMultiplicityInfo classItem={classOrGroup as IClass} me={me} />
        </div>
      )}

      {activeTab === StudentsTab.GROUPS && <GroupsTabContent />}
    </>
  )
}

export default MultiplicityTableContainer
