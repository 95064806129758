import StudentsGroupOptionsMenu from 'features/tables/StudentsTable/StudentTableRow/StudentsGroupOptionsMenu/StudentsGroupOptionsMenu'
import useText from 'i18n/hook'
import React from 'react'
import { useHistory } from 'react-router'
import PlusIcon from 'ui/icons/PlusIcon/PlusIcon'
import StudentsTableUI from 'ui/tables/StudentsTableUI/StudentsTableUI'
import StudentsTableUIRow from 'ui/tables/StudentsTableUI/StudentsTableUIRow/StudentsTableUIRow'
import styles from './SingleGroupTableContent.module.scss'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import { IClass } from 'api/types.classes'
import { getActiveGroup } from './../helpers'

type SingleGroupTableContentProps = {
  classroom: IClass
  toggleModal: () => void
}

const SingleGroupTableContent = ({ classroom, toggleModal }: SingleGroupTableContentProps) => {
  const history = useHistory()
  const t = useText()
  const activeGroup = getActiveGroup(history.location.search, classroom.groups)

  if (!activeGroup) return null

  return (
    <>
      {activeGroup.students?.length > 0 ? (
        <StudentsTableUI>
          {activeGroup.students.map((student) => (
            <StudentsTableUIRow key={student._id} student={student}>
              <StudentsGroupOptionsMenu student={student} group={activeGroup} />
            </StudentsTableUIRow>
          ))}
        </StudentsTableUI>
      ) : (
        <div className={styles.Container}>
          <Button
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZE.LARGE}
            color={BUTTON_COLOR.ORANGE}
            onClick={toggleModal}
            icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={18} />}
            iconAlign={ICON_ALIGNMENT.RIGHT}
          >
            {t.addStudent}
          </Button>
        </div>
      )}
    </>
  )
}


export default SingleGroupTableContent
