import { Modal } from '@magmamath/ui'
import { useUnit } from 'effector-react'
import React, { FC } from 'react'
import { studentQRModel } from './model'
import QRCode from 'react-qr-code'
import styles from './StudentQRCodeModal.module.scss'

export const StudentQRCodeModal: FC = () => {
  const qrData = useUnit(studentQRModel.$state)

  return (
    <Modal
      classes={{
        Modal: styles.StudentQRCodeModal,
      }}
      isOpen={!!qrData}
      onClose={studentQRModel.reset}
    >
      <Modal.Header title={qrData?.studentName} />
      <Modal.Content classes={{ Content: styles.StudentQRCodeModalContent }}>
        {qrData && <QRCode value={qrData.key} size={310} />}
      </Modal.Content>
    </Modal>
  )
}
