import React from 'react'
import { isEmpty } from 'lodash'
import styles from '../../../../screens/Students/Students.module.scss'
import StudentsButtonBlock from '../../../buttonsBlocks/StudentsButtonBlock/StudentsButtonBlock'
import StudentsTableUIHeader from '../StudentsTableRow/StudentsTableUIHeader/StudentsTableUIHeader'
import { IModalActions } from '../../../../redux/modules/modal.module'
import { IClass } from '../../../../api/types.classes'

type IStudentsTableHeaderProps = {
  classroom: IClass
  modalActions: IModalActions
}

const StudentsTableHeader: React.FC<IStudentsTableHeaderProps> = ({ classroom, modalActions }) => {
  if (isEmpty(classroom.students)) return null

  return (
    <>
      <div className={styles.ButtonsWrapper}>
        <StudentsButtonBlock classroom={classroom} modalActions={modalActions} />
      </div>
      <StudentsTableUIHeader classOrGroup={classroom} />
    </>
  )
}

export default StudentsTableHeader
