import { ILocalization } from 'config/languages.config'
import { ErrorCause } from './types'

const getErrorText = (errorCause: ErrorCause, t: ILocalization['data']): string => {
  const errorTexts = {
    [ErrorCause.EMAIL]: t.userExistingWithEmail,
    [ErrorCause.USERNAME]: t.incorrectUsername,
  }
  return errorTexts[errorCause]
}

export { getErrorText }
