import { NotifyType, ResponseType } from 'helpers/enums'
import { dispatchError } from 'helpers/error.helpers'
import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { IStudent } from 'api/types.users'
import { SubmissionError } from 'redux-form'
import { getClass } from './class.module'
import { getGroups } from './groups.module'
import { MODAL } from './modal.module'
import { NOTIFY } from './notify.module'
import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'
import { UpdateGroupPayload } from '../../api/api.groups'

// Groups constants
export enum GROUP {
  ADD_GROUP = 'ADD_GROUP',
  ADD_SUCCESS = 'ADD_SUCCESS',
  ADD_ERROR = 'ADD_ERROR',
  GET_GROUP_REQUEST = 'GROUP_REQUEST',
  GET_GROUP_SUCCESS = 'GROUP_SUCCESS',
  GET_GROUP_ERROR = 'GROUP_ERROR',
  DELETE_ERROR_GROUP = 'DELETE_ERROR_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  DELETE_REQUEST_GROUP = 'DELETE_REQUEST_GROUP',
  DELETE_STUDENT = 'DELETE_STUDENT',
  GROUP_EDIT_REQUEST = 'GROUP_EDIT_REQUEST',
  GROUP_EDIT_SUCCESS = 'GROUP_EDIT_SUCCESS',
  GROUP_EDIT_ERROR = 'GROUP_EDIT_ERROR',
}

// Groups reducer
const initialState = {
  data: {
    name: null,
    success: false,
  },
  error: null,
  loading: false,
}
export function groupReducer(state: any = initialState, action: IAction<GROUP>) {
  switch (action.type) {
    case GROUP.ADD_GROUP:
      return {
        ...state,
        data: { ...state.data, name: action.payload },
        loading: true,
      }
    case GROUP.ADD_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case GROUP.ADD_SUCCESS:
      return {
        data: { ...state.data },
        error: action.payload,
        loading: false,
      }
    case GROUP.GET_GROUP_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case GROUP.GET_GROUP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case GROUP.GET_GROUP_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GROUP.DELETE_REQUEST_GROUP:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case GROUP.DELETE_GROUP:
      return {
        data: { ...state.data },
        error: action.payload,
        loading: false,
      }
    case GROUP.DELETE_ERROR_GROUP:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GROUP.DELETE_STUDENT:
      return {
        ...state,
        data: {
          ...state.data,
          students: state.data?.students.filter(
            (student: IStudent) => student._id !== action.payload.id
          ),
        },
      }
    case GROUP.GROUP_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case GROUP.GROUP_EDIT_SUCCESS:
      return {
        ...state,
        data: { ...state.data },
        loading: false,
      }
    case GROUP.GROUP_EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

// Groups actions
export function addGroup(
  data: { classroomId: string; name: string },
  history: any,
  localization: ILocalization
) {
  let isSuccess = false
  const notification: string = localization.data.groupAdded
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: GROUP.ADD_GROUP })
      const res = await api.groups.createNew(data)
      dispatch({ type: GROUP.ADD_SUCCESS, payload: res.success })
      dispatch({ type: MODAL.CLOSE_MODAL, payload: { opened: false } })
      await getGroups()(dispatch)
      await getClass(data.classroomId)(dispatch)

      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
      isSuccess = true
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          if (!!error.response) {
            const details = error.response.data.error_details
            if (details.includes('fields')) {
              const arr: any[] = JSON.parse(details.substr(10))

              const result = arr.reduce((obj, item) => {
                obj[item.field] = ResponseType[item.code]
                return obj
              }, {})
              dispatch({
                type: GROUP.ADD_ERROR,
              })
              throw new SubmissionError(result)
            } else {
              dispatchError(dispatch, error, GROUP.ADD_ERROR)
            }
          }
        },
        () => handleNetworkError(GROUP.ADD_ERROR, error, dispatch)
      )

      dispatch({ type: MODAL.CLOSE_MODAL, payload: { opened: false } })
      isSuccess = false
    }
    return isSuccess
  }
}

export function getGroup(id: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: GROUP.GET_GROUP_REQUEST })
      const res = await api.groups.getSingle(id)
      dispatch({ type: GROUP.GET_GROUP_SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: GROUP.GET_GROUP_ERROR,
      })
    }
  }
}

export function editGroup(
  data: UpdateGroupPayload['data'],
  id: string,
  localization: ILocalization
) {
  const notification: string = data.name
    ? localization.data.groupNameEditedTxt
    : localization.data.studentAddedTxt
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: GROUP.GROUP_EDIT_REQUEST })
      const res = await api.groups.updateSingle({ id, data })
      dispatch({ type: GROUP.GROUP_EDIT_SUCCESS, payload: res })
      if (data.name) {
        dispatch({ type: MODAL.CLOSE_MODAL, payload: { opened: false } })
      }
      await getGroup(id)(dispatch)
      await getGroups()(dispatch)
      if (data.classroomId) await getClass(data.classroomId)(dispatch)
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: GROUP.GROUP_EDIT_ERROR,
          })
        },
        () => handleNetworkError(GROUP.GROUP_EDIT_ERROR, error, dispatch)
      )
    }
  }
}

export interface IGroupActions {
  addGroup: (
    data: { classroomId: string; name: string },
    history: any,
    localization: ILocalization
  ) => void
  getGroup: (id: string) => void
  editGroup: (
    data: { name?: string; studentIds?: string[]; classroomId?: string; ownerId?: string },
    id: string,
    localization: ILocalization
  ) => void
}
