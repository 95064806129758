import { sessionStorageKeys } from 'config/localStorageKeys'
import { createEvent, createStore } from 'effector'
import { persist } from 'effector-storage/session'
import { ClassesStoreItem, ClassesUpdateEvent } from '../types'

export const updateClassesWithClass = createEvent<ClassesUpdateEvent>()
export const setClasses = createEvent<ClassesStoreItem[]>()
export const setClassSelectionStatus = createEvent<{
  classId: string
  isSelected: boolean
}>()
export const deleteClassFromClasses = createEvent<{classId: string}>()

export const resetClasses = createEvent()

export const $classes = createStore<ClassesStoreItem[]>([])
  .on(setClasses, (_, payload) => payload)
  .on(setClassSelectionStatus, (state, payload) => {
    const index = state.findIndex((classes) => classes.classStore?.id === payload.classId)
    const classToUpdate = state.find((classes) => {
      return classes.classStore?.id === payload.classId
    })

    if (!classToUpdate || index === -1) return state

    const newState = [...state]
    const updatedClass = {
      id: classToUpdate.id,
      classStore: classToUpdate.classStore
        ? {
            ...classToUpdate.classStore,
            selected: payload.isSelected,
          }
        : null,
      groupsStore: classToUpdate.groupsStore.map((group) => ({
        ...group,
        selected: payload.isSelected,
      })),
      studentsStore: classToUpdate.studentsStore.map((student) => ({
        ...student,
        selected: payload.isSelected,
      })),
    }

    newState[index] = updatedClass
    return newState
  })
  .on(updateClassesWithClass, (state, payload) => {
    const index = state.findIndex((item) => item.classStore?.id === payload.id)
    if (index === -1) return [...state, payload.value]
    const newState = [...state]
    newState[index] = payload.value
    return newState
  })
  .on(deleteClassFromClasses, (state, payload) => {
    return state.filter((item) => item.classStore?.id !== payload.classId)
  })
  .reset(resetClasses)

persist({ store: $classes, key: sessionStorageKeys.cafClassesStore })
