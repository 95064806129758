import { api } from 'api'
import { createEffect, createEvent, createStore, split } from 'effector'
import {
  LayoutVariant,
  PrintClassState,
  PrintClassType,
  PrintListClassType,
  SetStateProps,
} from './types'

export class printClassModel {
  public static readonly setState = createEvent<SetStateProps>()

  public static readonly setListStudentData = createEvent<PrintListClassType>()

  public static readonly setLayout = createEvent<LayoutVariant>()

  public static readonly reset = createEvent()

  public static readonly fetchQRCodesDataFx = createEffect(
    async (state: SetStateProps) => await api.classes.getQRCodes(state.class._id)
  )

  public static readonly $loadingQRCodeData = this.fetchQRCodesDataFx.pending

  public static readonly $state = createStore<PrintClassState>(null)
    .on(this.setListStudentData, (_, data) => data)
    .on(this.fetchQRCodesDataFx.done, (_, { result, params }) => ({
      class: params.class,
      qrCodedata: result,
      layout: LayoutVariant.SIX_PER_PAGE,
      type: PrintClassType.QR,
    }))
    .on(this.setLayout, (state, layout) =>
      state && state.type === PrintClassType.QR
        ? {
            ...state,
            layout,
          }
        : null
    )
    .reset(this.reset)
}

split({
  source: printClassModel.setState,
  match: ({ type }) => type,
  cases: {
    [PrintClassType.LIST]: printClassModel.setListStudentData,
    [PrintClassType.QR]: printClassModel.fetchQRCodesDataFx,
  },
})
