import { BUTTON_COLOR } from '@magmamath/ui'
import { IMultipleSolution, IStudentSolutionStats } from 'api/types.solutions'
import clsx from 'clsx'
import { AnswerType, MyScriptCharacterTypes } from 'helpers/enums'
import React, { useState } from 'react'
import { IProblem, ISubProblem } from '../../../api/types.problem'
import { useDrawBoardPreviewPreset } from './DrawBoardPreview/hook.preset'
import styles from './SolutionCard.module.scss'
import SolutionCardBody from './SolutionCardBody/SolutionCardBody'
import SolutionCardFooter from './SolutionCardFooter/SolutionCardFooter'
import SolutionCardHeader from './SolutionCardHeader/SolutionCardHeader'
import Timestamp from './Timestamp/Timestamp'
import { getAttemptOffsetInitialData, getSolutionCardInfo } from './helpers'

type SolutionCardProps = {
  solution: IMultipleSolution | IStudentSolutionStats
  fullSolution?: IStudentSolutionStats
  title: string
  buttonColor: BUTTON_COLOR
  studentId: string
  exerciseId: string
  problemId: string
  correctAnswer: string[]
  problem: IProblem | ISubProblem
  answerType: AnswerType
  characterType: MyScriptCharacterTypes
  isExpanded?: boolean
  withTimestamp?: boolean
  subProblemId?: string
  withPlayable?: boolean
  navigationLinks?: Map<string, { next?: string; prev?: string }>
  headerColor?: BUTTON_COLOR.BLUE | BUTTON_COLOR.PURPLE
  lockAtAttemptNumber?: number
  isUserDeleted: boolean
}

const SolutionCard = ({
  solution,
  fullSolution,
  title,
  buttonColor,
  exerciseId,
  subProblemId,
  problemId,
  correctAnswer,
  studentId,
  navigationLinks,
  problem,
  answerType,
  characterType,
  headerColor,
  withTimestamp,
  withPlayable = true,
  lockAtAttemptNumber,
  isUserDeleted,
}: SolutionCardProps) => {
  const [attemptOffset, setAttemptOffset] = useState(() =>
    getAttemptOffsetInitialData({
      solution,
      locationAttemptNumber: lockAtAttemptNumber,
    })
  )
  const { attemptsCount, currentAnswerInfo, isCurrentDrawingNotAvailable } = getSolutionCardInfo({
    solution,
    attemptOffset,
    locationAttemptNumber: lockAtAttemptNumber,
  })

  const realAttemptsCount = currentAnswerInfo?.selectedAttempt?.solutionAnswerArray
    ? currentAnswerInfo.selectedAttempt.solutionAnswerArray.length
    : attemptsCount

  const subProblem =
    'subProblems' in problem &&
    problem.subProblems?.find((subProblem) => subProblem._id === subProblemId)

  const preset = useDrawBoardPreviewPreset(withPlayable)
  if (preset) {
    preset.context.attemptOffsetRef =
      lockAtAttemptNumber !== undefined ? realAttemptsCount - lockAtAttemptNumber : attemptOffset
  }

  const credits = {
    userId: studentId,
    subProblemId: subProblemId ?? null,
    problemId: subProblemId ? null : problemId,
    exerciseId,
  }
  const problemBackgroundImage = (subProblem || problem)?.drawingAreaImage

  return (
    <div className={clsx(styles.Wrapper, { [styles.KeepRatio]: !withPlayable })}>
      <div className={styles.Card}>
        <div className={styles.Content}>
          <SolutionCardHeader
            correctAnswer={correctAnswer}
            currentAnswerInfo={currentAnswerInfo}
            exerciseId={exerciseId}
            headerColor={headerColor}
            navigationLinks={navigationLinks}
            problemId={problemId}
            solution={solution}
            studentId={studentId}
            subProblemId={subProblemId}
            title={title}
            isUserDeleted={isUserDeleted}
          />
          <SolutionCardBody
            attemptImage={currentAnswerInfo?.drawing}
            attemptsCount={realAttemptsCount}
            backgroundImage={problemBackgroundImage}
            credits={credits}
            drawBoardPreset={preset}
            noAnswer={isCurrentDrawingNotAvailable}
            noSolutionAnswer={currentAnswerInfo?.answer ?? solution.lastAnswer}
            answerType={answerType}
            characterType={characterType}
          />
          <SolutionCardFooter
            answerType={answerType}
            attemptOffset={attemptOffset}
            attemptsCount={realAttemptsCount}
            buttonColor={buttonColor}
            characterType={characterType}
            credits={credits}
            currentAnswerInfo={currentAnswerInfo}
            drawBoardBackground={problemBackgroundImage}
            drawBoardPreset={!isCurrentDrawingNotAvailable ? preset : null}
            onAttemptOffsetChange={(offset) => {
              if (lockAtAttemptNumber !== undefined) return
              setAttemptOffset(offset)
            }}
            solution={solution}
            lockAtAttemptNumber={lockAtAttemptNumber}
          />
        </div>
        {withTimestamp && (
          <Timestamp
            currentAnswerInfo={currentAnswerInfo}
            answerArray={fullSolution?.answerArray}
          />
        )}
      </div>
    </div>
  )
}

export default SolutionCard
