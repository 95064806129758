export const validateForm = (values: any, props: any) => {
  const errors: any = {}
  const { mustBeGreatPassword, mustBeLessTwenty, passDontMatch, requiredFieldText } =
    props.localization.data
  if (!values.password) {
    errors.password = requiredFieldText
  } else if (values.password.length < 6) {
    errors.password = mustBeGreatPassword
  } else if (values.password.length > 20) {
    errors.password = mustBeLessTwenty
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = requiredFieldText
  } else if (values.passwordConfirmation.length < 6) {
    errors.passwordConfirmation = mustBeGreatPassword
  } else if (values.passwordConfirmation.length > 20) {
    errors.passwordConfirmation = mustBeLessTwenty
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = passDontMatch
  }

  return errors
}

export const validateCodeForm = (values: any, props: any) => {
  const errors: any = {}
  const { mustBeSixCode, requiredFieldText } = props.localization.data

  if (!values.code) {
    errors.code = requiredFieldText
  } else if (values.code.length < 6) {
    errors.code = mustBeSixCode
  } else if (values.code.length > 6) {
    errors.code = mustBeSixCode
  }
  if (!values.username) {
    errors.username = requiredFieldText
  }

  return errors
}

export const validateEmailAndUsernameForm = (values: any, props: any) => {
  const errors: any = {}
  const { requiredFieldText, invalidEmail } = props.localization.data
  const checkedValue: string = values.email
  if (!checkedValue) {
    errors.email = requiredFieldText
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(checkedValue)) {
    errors.email = invalidEmail
  }
  return errors
}
