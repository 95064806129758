import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import * as React from 'react'
import { PropsWithChildren } from 'react'
import { IStudent } from '../../../../api/types.users'
import UITooltip from '../../../tooltips/UITooltip/Tooltip'
import styles from './StudentsTableUIRow.module.scss'
import clsx from 'clsx'
import { BUTTON_VARIANT, Button, SIZE } from '@magmamath/ui'
import QRIcon from 'ui/icons/QRIcon/QRIcon'

interface IStudentsTableUIRowProps {
  student: IStudent
  isQRCodeEnabled?: boolean
  onClickShowQRCode?: () => void
}

const StudentsTableUIRow = ({
  student,
  children,
  isQRCodeEnabled = false,
  onClickShowQRCode,
}: PropsWithChildren<IStudentsTableUIRowProps>) => {
  const { Row, Content, Item, ItemBtn, Flex } = styles
  const { overflowRef: firstNameRef, isOverflowing: isFirstNameOverflowing } =
    useOverflowDetection()
  const { overflowRef: lastNameRef, isOverflowing: isLastNameOverflowing } = useOverflowDetection()
  const { overflowRef: usernameRef, isOverflowing: isUsernameOverflowing } = useOverflowDetection()

  return (
    <div className={Row}>
      <div className={Content}>
        <UITooltip title={isFirstNameOverflowing ? student.firstName : null}>
          <div ref={firstNameRef} className={Item}>
            {student.firstName}
          </div>
        </UITooltip>
        <UITooltip ref={lastNameRef} title={isLastNameOverflowing ? student.lastName : null}>
          <div className={Item}>{student.lastName}</div>
        </UITooltip>
        <UITooltip ref={usernameRef} title={isUsernameOverflowing ? student.username : null}>
          <div className={clsx(Item, Flex)}>{student?.username}</div>
        </UITooltip>
        <div className={clsx(Item, ItemBtn)}>
          {isQRCodeEnabled && (
            <Button
              icon={<QRIcon color='var(--icon-color)' size={16} />}
              onClick={onClickShowQRCode}
              size={SIZE.SMALL}
              variant={BUTTON_VARIANT.TERTIARY}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

export default StudentsTableUIRow
