import { createEffect, createStore } from 'effector'
import { IClassesStudents } from 'api/types.classesStudents'
import { IClass } from 'api/types.classes'
import { api } from 'api'
import {
  AddStudentToClassProps,
  DeleteStudentFromClassFxProps,
  ErrorCause,
  SignUpStudentFxProps,
} from './types'
import { getErrorText } from './helpers'

export class studentsTabModel {
  public static readonly fetchClassByIdFx = createEffect((id: string) =>
    api.classes.getSingle({ id, data: { fetchAll: 1 } })
  )
  public static readonly fetchClassesFx = createEffect((schoolId: string) =>
    api.classesStudents.getAll({ schoolId })
  )
  public static readonly signUpStudentFx = createEffect(
    async ({ data, classId, t }: SignUpStudentFxProps) => {
      try {
        await api.users.signUpStudent(data)
        this.fetchClassByIdFx(classId)
        return { error: null }
      } catch (error) {
        const details = error.response?.data?.details
        if (
          details &&
          (details.includes(ErrorCause.EMAIL) || details.includes(ErrorCause.USERNAME))
        ) {
          return { error: getErrorText(details, t) }
        }
        return { error: t.somethingWentWrong }
      }
    }
  )
  public static readonly deleteStudentFromClassFx = createEffect(
    async ({ classId, studentId, schoolId }: DeleteStudentFromClassFxProps) => {
      await api.classes.deleteStudentFromClass({ classId, studentId })
      this.fetchClassByIdFx(classId)
      this.fetchClassesFx(schoolId)
    }
  )
  public static readonly addStudentToClassFx = createEffect(
    async ({ studentIds, classId, schoolId }: AddStudentToClassProps) => {
      await api.classes.addStudentToClass({ classId, data: { studentIds } })
      this.fetchClassByIdFx(classId)
      this.fetchClassesFx(schoolId)
    }
  )

  public static readonly $addStudentDataLoading = this.addStudentToClassFx.pending
  public static readonly $fetchClassDataLoading = this.fetchClassByIdFx.pending
  public static readonly $class = createStore<IClass | null>(null).on(
    this.fetchClassByIdFx.doneData,
    (_, payload) => payload
  )
  public static readonly $classes = createStore<IClassesStudents[]>([]).on(
    this.fetchClassesFx.doneData,
    (_, payload) => payload
  )
}
