import * as React from 'react'
import StudentsTableUI from '../../../ui/tables/StudentsTableUI/StudentsTableUI'
import { IModalActions } from '../../../redux/modules/modal.module'
import StudentsTableRow from './StudentsTableRow/StudentsTableRow'
import { IClass } from '../../../api/types.classes'
import { getSortedStudents } from './helpers'
import { studentQRModel } from 'features/studentQRCode/model'
import { StudentQRCodeModal } from 'features/studentQRCode/StudentQRCodeModal'
import { IStudent } from 'api/types.users'

type IStudentsTableProps = {
  modalActions: IModalActions
  classroom: IClass
}

const StudentsTable: React.FC<IStudentsTableProps> = ({ modalActions, classroom }) => {
  const students = getSortedStudents(classroom.students)
  if (students.length === 0) return null

  const onClickShowQRCode = (student: IStudent) => {
    studentQRModel.initQRData(student)
  }

  return (
    <>
      <StudentsTableUI>
        {students.map((student) => (
          <StudentsTableRow
            key={student._id}
            student={student}
            modalActions={modalActions}
            classroom={classroom}
            onClickShowQRCode={() => onClickShowQRCode(student)}
          />
        ))}
      </StudentsTableUI>
      <StudentQRCodeModal />
    </>
  )
}

export default StudentsTable
