import React, { useState } from 'react'
import ButtonLoader from 'ui/loaders/ButtonLoader/ButtonLoader'
import useText from '../../../i18n/hook'
import UIButton from '../../../ui/Buttons/UIButton/Button'
import CommonInputModal from '../../../ui/inputs/CommonInputModal/CommonInputModal'
import styles from './CreateGroupForm.module.scss'
import clsx from 'clsx'
import { groupTabModel } from 'features/GroupsTab/model'
import { toast } from 'features/ToastModel/ToastModel'
import { useUnit } from 'effector-react'

type CreateGroupFormProps = {
  onClose: () => void
  classroomId: string
}

const CreateGroupForm = ({ onClose, classroomId }: CreateGroupFormProps) => {
  const isLoading = useUnit(groupTabModel.$isAddGroupFxPending)
  const [groupName, setGroupName] = useState('')
  const t = useText()

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      await groupTabModel.addGroupFx({ classroomId, name: groupName })
      toast.confirmation({ props: { title: t.groupAdded } })
    } catch (error) {
      toast.error({ props: { title: t.somethingWentWrong } })
    } finally {
      setGroupName('')
      onClose()
    }
  }

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setGroupName(e.currentTarget.value)
  }

  return (
    <form className={styles.Form} onSubmit={onFormSubmit}>
      <CommonInputModal
        value={groupName}
        placeholder={t.createNewGroup}
        onChange={onChangeHandler}
        maxLength={50}
      />

      <UIButton
        className={clsx({ [styles.NonClickable]: isLoading })}
        disabled={!groupName}
        type='submit'
      >
        {isLoading ? <ButtonLoader className={styles.Center} /> : t.createTxt}
      </UIButton>
    </form>
  )
}

export default CreateGroupForm
