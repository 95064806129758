import * as React from 'react'
import { ChangeEvent, useEffect, useState } from 'react'

import './../modals.scss'
import './ModalSimpleWindow.component.scss'

import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { localStorageKeys } from 'config/localStorageKeys'
import { ActionElement, Keys, ModalType, StudentsCategoryTypes } from 'helpers/enums'
import { focusElement } from 'helpers/form.helpers'
import { checkSafari } from 'helpers/general'
import { getItemFromLocalStorage } from 'helpers/storage.helper'
import { IState } from 'interfaces/state.interface'
import { IClassActions } from 'redux/modules/class.module'
import { IModal, IModalActions } from 'redux/modules/modal.module'
import { ISectionActions } from 'redux/modules/section.module'
import { IUserActions } from 'redux/modules/student.module'
import { ITopicActions } from 'redux/modules/topic.module'
import { IGroupActions } from '../../../redux/modules/group.module'

import CancelButton from 'components/basic/Buttons/CancelButton/CancelButton'
import { useHistory } from 'react-router'
import Input from 'reactstrap/lib/Input'
import { IClass } from '../../../api/types.classes'

export interface IPopupProps {
  classActions?: IClassActions
  groupActions?: IGroupActions
  modalActions?: IModalActions
  topicActions?: ITopicActions
  userActions?: IUserActions
  sectionActions?: ISectionActions
  localization?: any
  modal?: IState<IModal>
  addedClass?: IState<IClass>
  group?: any
}

const PopupContainer: React.FC<IPopupProps> = ({
  classActions,
  groupActions,
  modalActions,
  topicActions,
  userActions,
  sectionActions,
  localization,
  modal,
  addedClass,
  group,
}) => {
  const history = useHistory()

  const [isEnable, setIsEnable] = useState<boolean>(false)
  const { title, actionElement, me, settings } = modal!.data

  const [inputValue, setInputValue] = useState<string>('')
  const [secondInputValue, setSecondInputValue] = useState<string>('')
  const {
    saveTxt,
    nameYourNew,
    newExerciseTxt,
    cancelTxt,
    changeStudentUsername,
    chooseGrade,
    editClassNameTxt,
    editDisplayName,
    addDisplayName,
  } = localization!.data
  const isSafari: boolean = checkSafari()
  const opened: boolean = (modal as any).data.modalType === ModalType.SimpleModal
  const minGrade = 1
  const maxGrade = 12

  useEffect(() => {
    const inputElement = document.querySelector('.modal-default-input') as any
    if (inputElement) {
      focusElement(inputElement)
    }

    const defaultValue: string = getDefaultValue()
    if (defaultValue) {
      setInputValue(defaultValue)
      setIsEnable(true)
    }
  }, [])

  useEffect(() => {
    if (
      actionElement === ActionElement.createClass &&
      inputValue &&
      +secondInputValue >= minGrade &&
      +secondInputValue <= maxGrade
    ) {
      setIsEnable(true)
      return
    }

    if (
      actionElement === ActionElement.changeGrade &&
      +secondInputValue >= minGrade &&
      +secondInputValue <= maxGrade
    ) {
      setIsEnable(true)
      return
    }

    if (
      actionElement === ActionElement.createClass ||
      actionElement === ActionElement.changeGrade
    ) {
      setIsEnable(false)
      return
    }
  }, [inputValue, secondInputValue])

  const toggle = () => {
    modalActions!.closeModal()
  }

  const handlerChange = (e: any) => {
    setIsEnable(e.target.value.length > 0)
    setInputValue(e.currentTarget.value)
  }

  const addClass = async () => {
    classActions!.addClass(
      { schoolId: me.school._id, name: inputValue, grade: +secondInputValue },
      history,
      localization
    )
  }

  const addGroup = async () => {
    groupActions!.addGroup(
      { classroomId: group.classroomId, name: inputValue },
      history,
      localization
    )
  }

  const addTopic = async () => {
    topicActions!.addTopic({ name: inputValue, exercises: settings.exercisesIds || [] })
  }

  const create = (e: any) => {
    const { addNewTopic, editGroupNameTxt, newClassTxt, newGroupTxt } =
      localization!.data

    if (title === newClassTxt) {
      addClass()
    }

    if (title === newGroupTxt) {
      addGroup()
    }

    if (title === editClassNameTxt) {
      classActions!.editClass({ name: inputValue }, addedClass!.data._id, history, localization)
    }

    if ([editDisplayName, addDisplayName].includes(title)) {
      classActions!.editClass(
        { displayName: inputValue },
        addedClass!.data._id,
        history,
        localization
      )
    }

    if (actionElement === ActionElement.changeGrade) {
      classActions!.editClass(
        { grade: +secondInputValue },
        addedClass!.data._id,
        history,
        localization
      )
    }
    if (title === changeStudentUsername) {
      const associationId: string =
        addedClass && !group.data.name ? addedClass!.data._id : group!.data._id
      const categoryType: StudentsCategoryTypes =
        addedClass && !group.data.name ? StudentsCategoryTypes.Class : StudentsCategoryTypes.Group
      userActions!.editStudent(
        { username: inputValue },
        modal!.data.id,
        associationId,
        categoryType,
        localization
      )
    }
    if (title === editGroupNameTxt) {
      groupActions!.editGroup(
        { name: inputValue, classroomId: addedClass?.data._id },
        modal!.data.id,
        localization
      )
    }

    if (title === addNewTopic) {
      modalActions!.closeModal()
      addTopic()
    }
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === Keys.ENTER && isEnable) {
      create(e)
    }
  }

  const getDefaultValue = (): string => {
    const exerciseName: string =
      getItemFromLocalStorage(localStorageKeys.exerciseName) || addedClass?.data.name || ''
    const oldStudentUsername: string = settings && settings.student ? settings.student.username : ''

    const hasExerciseName: boolean =
      (title === editClassNameTxt || title === newExerciseTxt) && !!exerciseName
    const hasStudentUsername: boolean = title === changeStudentUsername && !!oldStudentUsername

    if ([editDisplayName, addDisplayName].includes(title)) {
      return addedClass!.data.displayName || ''
    }

    if (hasExerciseName) {
      return exerciseName
    } else if (hasStudentUsername) {
      return oldStudentUsername
    } else {
      return ''
    }
  }

  const secondInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSecondInputValue(e.currentTarget.value)
  }

  return (
    <div>
      <Modal fade={!isSafari} isOpen={opened} className='custom-modal modal-simple' toggle={toggle}>
        <ModalHeader toggle={toggle} className='custom-header'>
          {title}
        </ModalHeader>
        <ModalBody className='custom-body modal-simple-body'>
          <FormGroup className='input-wrapper'>
            {actionElement !== ActionElement.changeGrade && (
              <>
                <Label className={title === newExerciseTxt ? 'up' : 'low'}>
                  {title !== newExerciseTxt && nameYourNew} <span>{actionElement}</span>
                </Label>
                <Input
                  type='text'
                  name='class'
                  className='modal-default-input'
                  defaultValue={getDefaultValue()}
                  onChange={handlerChange}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                />
              </>
            )}
            {(actionElement === ActionElement.createClass ||
              actionElement === ActionElement.changeGrade) && (
                <div className='extra-input-wrapper'>
                  <Label className='up'>
                    <span>{chooseGrade}</span>
                    <span className='range'>{`(${minGrade} - ${maxGrade})`}</span>
                  </Label>
                  <Input
                    type='number'
                    name='class'
                    className='modal-default-input'
                    value={secondInputValue}
                    onChange={secondInputHandler}
                  />
                </div>
              )}
          </FormGroup>
        </ModalBody>
        <ModalFooter className='custom-footer'>
          <CancelButton cancelFunc={toggle} title={cancelTxt} />
          <Button
            className='custom-create-button'
            disabled={!isEnable}
            onClick={create}
            type='submit'
          >
            <span>{saveTxt}</span>
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default PopupContainer
