import React from 'react'
import styles from './SuccessFormTab.module.scss'
import useText from 'i18n/hook'
import { Button, BUTTON_VARIANT, SIZE, ICON_ALIGNMENT, Typography } from '@magmamath/ui'
import PlusIcon from 'ui/icons/PlusIcon/PlusIcon'
import { PATHS } from 'config/pathnames.config'
import { Link } from 'react-router-dom'

type SuccessFormTabProps = {
  onAddStudent: () => void
}

const SuccessFormTab = ({ onAddStudent }: SuccessFormTabProps) => {
  const t = useText()
  return (
    <div className={styles.SuccessFormTab}>
      <div className={styles.BtnsWrap}>
        <Button
          onClick={onAddStudent}
          size={SIZE.LARGE}
          variant={BUTTON_VARIANT.PRIMARY}
          icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={14} />}
          iconAlign={ICON_ALIGNMENT.RIGHT}
        >
          {t.addMoreStudents}
        </Button>
        <Typography variant='xs' className={styles.Text}>
          {t.orTxt}
        </Typography>
        <Button
          classes={{ button: styles.GoToAssignmentsBtn }}
          As={Link}
          to={PATHS.EXERCISES.EXERCISES_MAIN}
          size={SIZE.LARGE}
          variant={BUTTON_VARIANT.SECONDARY}
        >
          {t.goToAssignmentsTxt}
        </Button>
      </div>
    </div>
  )
}

export default SuccessFormTab
