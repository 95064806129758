import { apiManager } from './apiManager'
import { GetAllBooksPayload, GetAllBooksResponse } from './types.library'
import { IMyBook } from './types.books'

type AddBooksPayload = {
  bookIds: string[]
}

type BooksResponse = {
  books: IMyBook[]
}

export class libraryApi {
  public static readonly getAll = apiManager.get<GetAllBooksResponse, GetAllBooksPayload | void>(
    'library'
  )
  public static readonly deleteBook = apiManager.delete<BooksResponse, string>('library/books')
  public static readonly addBooks = apiManager.post<BooksResponse, AddBooksPayload>('library/books')
  public static readonly updateBooks = apiManager.put<BooksResponse, AddBooksPayload>('library')
}
