import React, { useState, useEffect } from 'react'
import { Modal, TabGroup } from '@magmamath/ui'
import { AddStudentMethod, DirectMethodTabStages } from './types'
import styles from './AddStudentToClassModal.module.scss'
import useText from 'i18n/hook'
import CodeMethodTab from './CodeMethodTab/CodeMethodTab'
import ListMethodTab from './ListMethodTab/ListMethodTab'
import FormMethodTab from './FormMethodTab/FormMethodTab'
import { getTabs } from './helpers'
import { addStudentToClassForm } from './FormMethodTab/model'
import SuccessFormTab from './SuccessFormTab/SuccessFormTab'
import { IClass } from 'api/types.classes'

type AddStudentToClassModalProps = {
  isOpen: boolean
  onClose: () => void
  onAddExistingStudentClick: () => void
  classroom: IClass
}

const AddStudentToClassModal = ({
  isOpen,
  onClose,
  onAddExistingStudentClick,
  classroom,
}: AddStudentToClassModalProps) => {
  const [activeDirectMethodStage, setActiveDirectMethodStage] = useState(DirectMethodTabStages.FORM)
  const [activeMethod, setActiveMethod] = useState(AddStudentMethod.CODE)
  const t = useText()

  useEffect(() => {
    return () => {
      addStudentToClassForm.reset()
      setActiveDirectMethodStage(DirectMethodTabStages.FORM)
      setActiveMethod(AddStudentMethod.CODE)
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      classes={{
        Dialog: styles.Dialog,
        Modal: styles.Modal,
      }}
    >
      <Modal.Header className={styles.Header}>
        {activeDirectMethodStage === DirectMethodTabStages.FORM ? (
          <TabGroup
            layoutId='modal-tab'
            value={activeMethod}
            onOptionChange={(stage: AddStudentMethod) => setActiveMethod(stage)}
            classes={{ tabGroup: styles?.TabGroup }}
          >
            {getTabs(t).map(({ value, content }) => (
              <TabGroup.Option
                key={value}
                value={value}
                classes={{ content: styles.OptionContent }}
              >
                {content}
              </TabGroup.Option>
            ))}
          </TabGroup>
        ) : (
          <h2 className={styles.SuccessTitle}>{t.addStudentSuccessTxt}</h2>
        )}
      </Modal.Header>
      <Modal.Content>
        {activeMethod === AddStudentMethod.FORM && (
          <>
            {activeDirectMethodStage === DirectMethodTabStages.FORM && (
              <FormMethodTab
                onAddExistingStudentClick={onAddExistingStudentClick}
                classroom={classroom}
                onSubmit={() => setActiveDirectMethodStage(DirectMethodTabStages.SUCCESS)}
              />
            )}
            {activeDirectMethodStage === DirectMethodTabStages.SUCCESS && (
              <SuccessFormTab
                onAddStudent={() => setActiveDirectMethodStage(DirectMethodTabStages.FORM)}
              />
            )}
          </>
        )}
        {activeMethod === AddStudentMethod.CODE && (
          <CodeMethodTab
            invitationalCode={classroom.invitationalCode ?? 0}
            onAddExistingStudentClick={onAddExistingStudentClick}
          />
        )}
        {activeMethod === AddStudentMethod.LIST && (
          <ListMethodTab
            invitationalCode={classroom.invitationalCode ?? 0}
            onAddExistingStudentClick={onAddExistingStudentClick}
          />
        )}
      </Modal.Content>
    </Modal>
  )
}

export default AddStudentToClassModal
