import { ClassroomGroup } from "api/types.classes"

export const getActiveGroup = (queryString?: string, groups?: ClassroomGroup[]) => {
  if (!groups || !queryString) return null

  const regex = /(?:\?|&)group=([^&]+)/
  const match = queryString.match(regex)
  if (match) return groups.find((group) => group._id === match[1]) ?? null

  return null
}