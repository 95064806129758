import { createEffect, createEvent, createStore, sample } from 'effector'
import { enhanceSkillData, TransformedRecord } from '@magmamath/skills-utils'
import { api } from '../../../../api'
import { AssignmentStatisticsEntityType } from 'api/types.oldSkills'
import { getSkills } from './helpers'

type SkillsParams = {
  assignmentId: string
  entityIds: string[]
  entityType: AssignmentStatisticsEntityType
  treeId: string
}

export const fetchTreeNodeFx = createEffect(api.skills.getTreeChildren)

export const fetchSkillsFx = createEffect(api.oldSkills.getAssignmentStatistics)

export const fetchSkillsDataFx = createEffect((skillsParams: SkillsParams) =>
  Promise.all([fetchSkillsFx(skillsParams), fetchTreeNodeFx(skillsParams.treeId)])
)

export const $isFetchingSkills = fetchSkillsFx.pending

type SkillsData = {
  skills: TransformedRecord | null
  nodeIdByGrade: Record<string, string> | null
  treeDepth: number
  grade: number | null
}

export const resetSkills = createEvent()
export const setGrade = createEvent<number>()

export const $skills = createStore<SkillsData>({
  skills: null,
  nodeIdByGrade: null,
  treeDepth: 0,
  grade: null,
})
  .on(fetchSkillsFx.doneData, (skillsData, data) => {
    const enhancedSkillData = enhanceSkillData(data)
    const newState = {
      ...skillsData,
      skills: enhancedSkillData,
    }
    if (skillsData && skillsData.grade && skillsData.nodeIdByGrade) {
      newState.skills = getSkills(skillsData.nodeIdByGrade, skillsData.grade, enhancedSkillData)
    }

    return newState
  })
  .on(fetchSkillsDataFx.doneData, (skillsData, [skills, treeNodes]) => {
    const nodeIdByGrade = treeNodes.reduce<Record<string, string>>((acc, node) => {
      if (node?.attributes?.grade) {
        acc[node.attributes.grade] = node._id
      }
      return acc
    }, {})

    const treeNodesTypes = new Set(skills.map((node) => node.nodeType))
    const newState = {
      ...skillsData,
      nodeIdByGrade,
      treeDepth: treeNodesTypes.size,
    }
    const enhancedSkillData = enhanceSkillData(skills)
    if (skillsData && skillsData.grade) {
      newState.skills = getSkills(nodeIdByGrade, skillsData.grade, enhancedSkillData)
      const gradeNodeId = nodeIdByGrade[skillsData.grade]
      const node = enhancedSkillData?.children.find((nodeItem) => nodeItem._id === gradeNodeId)
      if (node) {
        newState.treeDepth = treeNodesTypes.size - 1
      }
    } else {
      newState.skills = enhancedSkillData
    }
    return newState
  })
  .on(setGrade, (skillsData, grade) => {
    return { ...skillsData, grade }
  })
  .reset(resetSkills)
