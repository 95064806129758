import React from 'react'
import { SvgIconProps } from '../types/types'

const MoveToTopIcon = ({ size = 15, color, ...restProps }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 14 15'
      fill='none'
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.75 0.870239C0.75 0.456026 1.08579 0.120239 1.5 0.120239H12.5C12.9142 0.120239 13.25 0.456026 13.25 0.870239C13.25 1.28445 12.9142 1.62024 12.5 1.62024H1.5C1.08579 1.62024 0.75 1.28445 0.75 0.870239Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5303 7.90057C11.2374 8.19346 10.7626 8.19346 10.4697 7.90057L7.75 5.1809V14.25C7.75 14.6642 7.41422 15 7 15C6.58579 15 6.25 14.6642 6.25 14.25V5.1809L3.53033 7.90057C3.23744 8.19346 2.76257 8.19346 2.46967 7.90057C2.17678 7.60767 2.17678 7.1328 2.46967 6.83991L6.46967 2.83991C6.76257 2.54702 7.23744 2.54702 7.53033 2.83991L11.5303 6.83991C11.8232 7.1328 11.8232 7.60767 11.5303 7.90057Z'
        fill={color}
      />
    </svg>
  )
}

export default MoveToTopIcon
