import clsx from 'clsx'
import { SearchParam } from 'helpers/enums'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import ArrowLeftIcon from 'ui/icons/ArrowLeftIcon/ArrowLeftIcon'
import PlusIcon from 'ui/icons/PlusIcon/PlusIcon'
import styles from './SingleGroup.module.scss'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, COLORS, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import { IClass } from 'api/types.classes'
import ManageGroupModal from 'features/modals/ManageGroupModal/ManageGroupModal'
import { getActiveGroup } from './helpers'
import SingleGroupTableContent from './SingleGroupTableContent/SingleGroupTableContent'
import { useUnit } from 'effector-react'
import { groupTabModel } from '../model'
import Spinner from 'features/Spinner/Spinner'
import { Link } from 'react-router-dom'

type SingleGroupProps = {
  classroom: IClass
}

const SingleGroup = ({ classroom }: SingleGroupProps) => {
  const history = useHistory()
  const t = useText()
  const [isManageGroupModalOpen, setIsManageGroupModalOpen] = useState(false)
  const isClassDataLoading = useUnit(groupTabModel.$isFetchClassByIdPending)

  const activeGroup = getActiveGroup(history.location.search, classroom.groups)
  const activeStudentsInGroup = new Set(activeGroup?.students.map((student) => student._id) ?? [])

  const toggleModal = () => setIsManageGroupModalOpen((prev) => !prev)

  if (!activeGroup) return null

  return (
    <>
      <div className={styles.Header}>
        <Link
          to={{
            pathname: history.location.pathname,
            search: SearchParam.GROUPS_TAB,
          }}
          className={styles.GoBackLink}
        >
          <ArrowLeftIcon color={COLORS.NEUTRAL_9} size={17} strokeWidth='2.5' />
          {t.allGroups}
        </Link>
        <div className={styles.HeaderTitle}>{activeGroup.name}</div>
        <Button
          classes={{
            button: clsx({
              [styles.Hidden]: !activeGroup.students?.length,
            }),
          }}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZE.MEDIUM}
          color={BUTTON_COLOR.ORANGE}
          onClick={toggleModal}
          icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={14} />}
          iconAlign={ICON_ALIGNMENT.RIGHT}
        >
          {t.addStudent}
        </Button>
      </div>
      {isClassDataLoading
        ? <Spinner className={styles.Loader} />
        : <SingleGroupTableContent classroom={classroom} toggleModal={toggleModal} />
      }
      {isManageGroupModalOpen && (
        <ManageGroupModal
          isOpen={isManageGroupModalOpen}
          onClose={toggleModal}
          classroom={classroom}
          activeStudentsInGroup={activeStudentsInGroup}
          activeGroupId={activeGroup._id}
        />)}
    </>
  )
}


export default SingleGroup
