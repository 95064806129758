import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { isSingleGroupPage } from 'screens/Students/Students'
import MultipleGroups from 'features/GroupsTab/MultipleGroups/MultipleGroups'
import SingleGroup from 'features/GroupsTab/SingleGroup/SingleGroup'
import { groupTabModel } from 'features/GroupsTab/model'
import { useUnit } from 'effector-react'
import Spinner from 'features/Spinner/Spinner'
import styles from './GroupsTabContent.module.scss'

type GroupsTabContentProps = {}

const GroupsTabContent = ({}: GroupsTabContentProps) => {
  const history = useHistory()
  const { id: classId } = useParams<{ id: string }>()
  const classroom = useUnit(groupTabModel.$class)

  useEffect(() => {
    groupTabModel.fetchClassByIdFx(classId)
  }, [classId])

  if (!classroom) return <Spinner className={styles.Spinner} />

  if (isSingleGroupPage(history.location.search)) {
    return <SingleGroup classroom={classroom} />
  }

  return <MultipleGroups classroom={classroom} />
}

export default GroupsTabContent
