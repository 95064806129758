import { createEffect, createStore } from 'effector'
import { IClass } from 'api/types.classes'
import { api } from 'api'
import {
  DeleteGroupFxProps,
  DeleteStudentFromGroupFxProps,
  EditGroupFxProps,
} from './types'
import { CreateNewGroupPayload } from 'api/api.groups'

export class groupTabModel {
  public static readonly fetchClassByIdFx = createEffect((id: string) =>
    api.classes.getSingle({ id, data: { fetchAll: 1 } })
  )
  public static readonly editGroupFx = createEffect(
    async ({ data, groupId, classId }: EditGroupFxProps) => {
      await api.groups.updateSingle({ id: groupId, data })
      this.fetchClassByIdFx(classId)
    }
  )
  public static readonly deleteStudentFromGroupFx = createEffect(
    async ({ studentId, groupId, classId }: DeleteStudentFromGroupFxProps) => {
      await api.groups.deleteStudentFromGroup({ groupId, studentId })
      if (classId) this.fetchClassByIdFx(classId)
    }
  )
  public static readonly addGroupFx = createEffect(
    async ({ classroomId, name }: CreateNewGroupPayload) => {
      await api.groups.createNew({ classroomId, name })
      this.fetchClassByIdFx(classroomId)
    }
  )
  public static readonly deleteGroupFx = createEffect(
    async ({ groupId, classId }: DeleteGroupFxProps) => {
      await api.groups.deleteSingle(groupId)
      if (classId) this.fetchClassByIdFx(classId)
    }
  )

  public static readonly $isEditGroupFxPending = this.editGroupFx.pending
  public static readonly $isAddGroupFxPending = this.addGroupFx.pending
  public static readonly $isFetchClassByIdPending = this.fetchClassByIdFx.pending
  public static readonly $class = createStore<IClass | null>(null).on(
    this.fetchClassByIdFx.doneData,
    (_, payload) => payload
  )
}
