import { IStudent } from "api/types.users"

const filterAndSortStudents = (students: IStudent[], searchValue: string) => {
  const trimmedSearchValue = searchValue.trim().toLowerCase()
  return students
    .filter((student) => {
      return `${student.firstName} ${student.lastName} ${student.firstName}`.toLowerCase().includes(trimmedSearchValue)
    })
    .sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase()
      const nameB = `${b.firstName} ${b.lastName}`.toLowerCase()
      return nameA.localeCompare(nameB)
    })
}

export {
  filterAndSortStudents
}