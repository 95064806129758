import { PATHS } from 'config/pathnames.config'
import { NotifyType, SearchParam, SortingTypes, TypesOfParams } from 'helpers/enums'
import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { IMe } from 'api/types.users'
import { getClasses } from './classes.module'
import { getExercises } from './exercises.module'
import { MODAL } from './modal.module'
import { NOTIFY } from './notify.module'
import { IState } from '../../interfaces/state.interface'
import { changeClassesForExercises } from './choosing.stud.module'
import { compact } from 'lodash'
import { getDataForUnfollowClass } from '../../lib/redux/getPortionsData'
import { matchPath } from 'react-router-dom'
import { filterByClasses } from './filterExercises.module'
import { ILocalization } from '../../config/languages.config'
import { getSchoolClasses } from './schoolClasses.module'
import {
  CLASSES_FILTER,
  selectClass,
} from '../../features/filters/MultiplicityFilter/classesFilter.module'
import { IClass, ResetClassPasswordsPayload } from '../../api/types.classes'
import { api } from '../../api'

// Class constants
export enum CLASS {
  ADD_CLASS = 'ADD_CLASS',
  ADD_ERROR = 'ADD_ERROR',
  ADD_SUCCESS = 'ADD_SUCCESS',
  GET_CLASS_REQUEST = 'GET_CLASS_REQUEST',
  GET_CLASS_SUCCESS = 'GET_CLASS_SUCCESS',
  GET_CLASS_ERROR = 'GET_CLASS_ERROR',
  CLASS_EDIT_REQUEST = 'CLASS_EDIT_REQUEST',
  CLASS_EDIT_SUCCESS = 'CLASS_EDIT_SUCCESS',
  CLASS_EDIT_ERROR = 'CLASS_EDIT_ERROR',
  CLASS_DELETE_SUCCESS = 'CLASS_DELETE_SUCCESS',
  CLASS_DELETE_REQUEST = 'CLASS_DELETE_REQUEST',
  CLASS_DELETE_ERROR = 'CLASS_DELETE_ERROR',
  CLASS_RESET_PASSWORDS_REQUEST = 'CLASS_RESET_PASSWORDS_REQUEST',
  CLASS_RESET_PASSWORDS_SUCCESS = 'CLASS_RESET_PASSWORDS_SUCCESS',
  CLASS_RESET_PASSWORDS_ERROR = 'CLASS_RESET_PASSWORDS_ERROR',
  CLASS_UNFOLLOW_TEACHER_SUCCESS = 'CLASS_UNFOLLOW_TEACHER_SUCCESS',
  CLASS_UNFOLLOW_TEACHER_ERROR = 'CLASS_UNFOLLOW_TEACHER_ERROR',
  CLASS_FOLLOW_TEACHER_SUCCESS = 'CLASS_FOLLOW_TEACHER_SUCCESS',
  CLASS_FOLLOW_TEACHER_ERROR = 'CLASS_FOLLOW_TEACHER_ERROR',
}

// Class reducer
const initialState = {
  data: {
    _id: null,
    invitationalCode: null,
    name: null,
    students: [],
    teachers: null,
  },
  error: null,
  loading: false,
}

export function classReducer(state: any = initialState, action: IAction<CLASS>) {
  switch (action.type) {
    case CLASS.ADD_CLASS:
      return {
        ...state,
        data: { ...state.data, name: action.payload },
        loading: true,
      }
    case CLASS.ADD_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case CLASS.ADD_SUCCESS:
      return {
        data: { ...state.data },
        error: null,
        loading: false,
      }
    case CLASS.GET_CLASS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case CLASS.GET_CLASS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case CLASS.GET_CLASS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CLASS.CLASS_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case CLASS.CLASS_EDIT_SUCCESS:
      return {
        ...state,
        data: { ...state.data },
        loading: false,
      }
    case CLASS.CLASS_EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CLASS.CLASS_RESET_PASSWORDS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case CLASS.CLASS_RESET_PASSWORDS_SUCCESS:
      return {
        ...state,
        data: { ...state.data },
        loading: false,
      }
    case CLASS.CLASS_RESET_PASSWORDS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CLASS.CLASS_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case CLASS.CLASS_DELETE_SUCCESS:
      return {
        data: { ...state.data },
        error: null,
        loading: false,
      }
    case CLASS.CLASS_DELETE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CLASS.CLASS_UNFOLLOW_TEACHER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      }
    case CLASS.CLASS_UNFOLLOW_TEACHER_ERROR:
      return {
        ...state,
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

// Class actions
export function addClass(
  data: { schoolId: string; name: string; grade: number },
  history: any,
  localization: ILocalization,
  settings?: { shouldRedirectOnClassCreation?: boolean }
) {
  const notification: string = localization.data.classAddedTxt
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: CLASS.ADD_CLASS })
      const res = await api.classes.createNew(data)
      dispatch({ type: CLASS.ADD_SUCCESS, payload: res.message })
      const classes = await getClasses()(dispatch)
      await getSchoolClasses(data.schoolId)(dispatch)

      // add the class to exercise if it is user's first class see: MAT-4144
      const classesIds = classes?._embedded.classes?.map(
        (classObject: { _id: string }) => classObject._id
      )
      const newClass = res.newClass.data
      const newClassId = newClass._id
      if (classesIds?.length === 1) changeClassesForExercises([newClassId])(dispatch)

      dispatch({ type: MODAL.CLOSE_MODAL, payload: { opened: false } })
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })

      filterByClasses(
        [newClass._id],
        { _id: newClass._id, name: newClass.name, type: TypesOfParams.CLASS },
        SortingTypes.StartDate
      )(dispatch)

      if (!settings?.shouldRedirectOnClassCreation) return
      selectClass({
        value: newClassId,
        name: newClass.displayName || newClass.name,
      })(dispatch)
      history.push(`${PATHS.PARTS.CLASS_STUDENTS}${newClassId}${SearchParam.STUDENTS_TAB}`)
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          if (!!error.response) {
            dispatch({
              payload: !!error.response ? error.response.data.message : error.message,
              type: CLASS.ADD_ERROR,
            })
          }
        },
        () => handleNetworkError(CLASS.ADD_ERROR, error, dispatch)
      )
      dispatch({ type: MODAL.CLOSE_MODAL, payload: { opened: false } })
    }
  }
}

export function getClass(id: string, notification?: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: CLASS.GET_CLASS_REQUEST })
      const res = await api.classes.getSingle({ id, data: { fetchAll: 1 } })
      dispatch({ type: CLASS.GET_CLASS_SUCCESS, payload: res })
      if (notification) {
        dispatch({
          type: NOTIFY.NOTIFY_BEGIN,
          payload: { message: notification, type: NotifyType.Success },
        })
        dispatch({ type: NOTIFY.NOTIFY_END })
      }
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: CLASS.GET_CLASS_ERROR,
      })
    }
  }
}

export function editClass(
  data: { name?: string; grade?: number; displayName?: string },
  id: string,
  history: any,
  localization: ILocalization
) {
  const notification: string = data?.name
    ? localization.data.classNameEditedTxt
    : localization.data.gradeChanged
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: CLASS.CLASS_EDIT_REQUEST })
      const res = await api.classes.updateSingle({ id, data })
      dispatch({ type: CLASS.CLASS_EDIT_SUCCESS, payload: res })
      if (data.name || data.grade || data.displayName) {
        dispatch({ type: MODAL.CLOSE_MODAL, payload: { opened: false } })
      }
      getClass(id)(dispatch)
      getClasses()(dispatch)
      getExercises({})
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Info },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: CLASS.CLASS_EDIT_ERROR,
          })
        },
        () => handleNetworkError(CLASS.CLASS_EDIT_ERROR, error, dispatch)
      )
    }
  }
}

export function deleteClass(id: string, history: any, localization: ILocalization) {
  const notification: string = localization.data.classDeletedTxt
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: CLASS.CLASS_DELETE_REQUEST })
      const res = await api.classes.deleteSingle(id)
      dispatch({ type: MODAL.CLOSE_CLASSES_MODAL })
      dispatch({ type: CLASS.CLASS_DELETE_SUCCESS, payload: res })

      getClasses()(dispatch)
      const isStudentsPathname = !!matchPath(
        history.location.pathname,
        PATHS.STUDENTS.STUDENTS_MAIN
      )
      if (isStudentsPathname) {
        history.push(PATHS.CLASSES)
      }
      setTimeout(() => {
        dispatch({
          type: NOTIFY.NOTIFY_BEGIN,
          payload: { message: notification, type: NotifyType.Success },
        })
        dispatch({ type: NOTIFY.NOTIFY_END })
      }, 500)
    } catch (error) {
      dispatch({ type: MODAL.CLOSE_CLASSES_MODAL })
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: CLASS.CLASS_DELETE_ERROR,
      })
    }
  }
}

export function resetPasswordsForClass(
  data: ResetClassPasswordsPayload['data'],
  id: string,
  history: any,
  localization: ILocalization
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: CLASS.CLASS_RESET_PASSWORDS_REQUEST })
      const res = await api.classes.resetPasswords({ classId: id, data })
      dispatch({ type: CLASS.CLASS_RESET_PASSWORDS_SUCCESS, payload: res })

      dispatch({
        type: MODAL.CLOSE_ALL_PASSWORDS_MODAL,
        payload: { openedAllPasswordsModal: false },
      })

      getClass(id, localization.data.allPasswordsOfClassWereUpdated)(dispatch)
      getClasses()(dispatch)
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: CLASS.CLASS_RESET_PASSWORDS_ERROR,
      })
    }
  }
}

export function unfollowTeacherFromClass(
  classOrGroup: IState<IClass>,
  me: IMe,
  localization: ILocalization,
  history: any
) {
  return async (dispatch: IDispatch<any>) => {
    let notification: string
    let notificationType: NotifyType
    let isSuccess = false
    try {
      notification = localization.data.unfollowedSuccess
      notificationType = NotifyType.Success
      const data = getDataForUnfollowClass(classOrGroup, me)
      await api.classes.updateSingle({ id: classOrGroup.data._id, data })
      isSuccess = true
      dispatch({ type: CLASS.CLASS_UNFOLLOW_TEACHER_SUCCESS })
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: notificationType },
      })

      const isStudentsPathname = !!matchPath(
        history.location.pathname,
        PATHS.STUDENTS.STUDENTS_MAIN
      )
      if (isStudentsPathname) {
        history.push(PATHS.CLASSES)
      }
      dispatch({
        type: CLASSES_FILTER.SELECT_CLASS,
        payload: { name: localization.data.allClassesTxt, value: null },
      })
      return isSuccess
    } catch (error) {
      isSuccess = false
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: CLASS.CLASS_UNFOLLOW_TEACHER_ERROR,
      })
      return isSuccess
    }
  }
}

export function followTeacherToClass(
  classOrGroup: IState<IClass>,
  me: IMe,
  localization: ILocalization
) {
  return async (dispatch: IDispatch<any>) => {
    let notification: string
    let notificationType: NotifyType
    let isSuccess = false
    try {
      notification = localization.data.followSuccess
      notificationType = NotifyType.Success
      const teacherIds = compact(
        classOrGroup.data.teachers.map((teacher) => teacher?._id ?? teacher)
      )
      const data = { teacherIds: [me._id, ...teacherIds] }
      await api.classes.updateSingle({ id: classOrGroup.data._id, data })
      isSuccess = true
      dispatch({ type: CLASS.CLASS_FOLLOW_TEACHER_SUCCESS })
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: notificationType },
      })
      return isSuccess
    } catch (error) {
      isSuccess = false
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: CLASS.CLASS_FOLLOW_TEACHER_ERROR,
      })
      return isSuccess
    }
  }
}

export const setTextToSpeechForWholeClass = ({
  classId,
  textToSpeechStatus,
  localization,
}: {
  classId: string
  textToSpeechStatus: boolean
  localization: ILocalization
}) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      await api.classes.updateSettings({
        classId,
        data: { textToSpeech: textToSpeechStatus },
      })
      //TODO: going to remove this if everything works fine without extra check for 204
      // if (res.status === 204) {
      //   dispatch({
      //     type: NOTIFY.NOTIFY_BEGIN,
      //     payload: { message: localization.data.readAloudSuccessTxt, type: NotifyType.Success },
      //   })
      //   dispatch({ type: NOTIFY.NOTIFY_END })
      //   getClass(classId)(dispatch)
      // }

      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: localization.data.readAloudSuccessTxt, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
      getClass(classId)(dispatch)
    } catch (error) {
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: localization.data.readAloudErrorTxt, type: NotifyType.Danger },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
    }
  }
}

export interface IClassActions {
  addClass: (
    data: { schoolId: string; name: string; grade: number },
    history: any,
    localization: ILocalization
  ) => void
  getClass: (id: string) => void
  editClass: (
    data: { name?: string; grade?: number; displayName?: string },
    id: string,
    history: any,
    localization: ILocalization
  ) => void
  deleteClass: (id: string, history: any, localization: ILocalization) => void
  resetPasswordsForClass: (
    data: { password: string; passwordConfirmation: string },
    id: string,
    history: any,
    localization: ILocalization
  ) => void
  setTextToSpeechForWholeClass: ({
    classId,
    textToSpeechStatus,
    localization,
  }: {
    classId: string
    textToSpeechStatus: boolean
    localization: ILocalization
  }) => void
  unfollowTeacherFromClass: (
    classOrGroup: IState<IClass>,
    me: IMe,
    localization: ILocalization,
    history: any
  ) => void
}
