import { SignUpStudentPayload } from 'api/api.users'
import { ILocalization } from 'config/languages.config'

export type InputState = {
  value: string
  error: string | null
}

export enum FormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  USERNAME = 'username',
  PASSWORD = 'password',
}

export type FormState = Record<FormFields, InputState>

export type ValidateFormProps = {
  filedName: FormFields
  value: string
  t: ILocalization['data']
}

export type CheckUsernameFxProps = {
  username: string
  t: ILocalization['data']
}

export type SignUpStudentFxProps = {
  data: SignUpStudentPayload
  classId: string
}

export type SubmitFormFxProps = {
  invitationalCode: number
  schoolId: string
  classId: string
  t: ILocalization['data']
}
