import React, { useMemo, useState } from 'react'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, Checkbox, Modal, Search, SIZE, Typography } from '@magmamath/ui'
import styles from './ManageGroupModal.module.scss'
import useText from 'i18n/hook'
import { IClass } from 'api/types.classes'
import { filterAndSortStudents } from './helpers'
import { toast } from 'features/ToastModel/ToastModel'
import { useUnit } from 'effector-react'
import { groupTabModel } from 'features/GroupsTab/model'
import { useParams } from 'react-router'

type ManageGroupModalProps = {
  isOpen: boolean
  onClose: () => void
  classroom: IClass
  activeStudentsInGroup: Set<string>
  activeGroupId: string
}

const TOAST_LIFE_PERIOD = 8000

function ManageGroupModal({
  isOpen,
  onClose,
  classroom,
  activeStudentsInGroup,
  activeGroupId
}: ManageGroupModalProps) {
  const t = useText()
  const { id: classId } = useParams<{ id: string }>()
  const [searchInputValue, setSearchInputValue] = useState('')
  const [selectedStudents, setSelectedStudents] = useState<Set<string>>(activeStudentsInGroup)
  const isClassDataLoading = useUnit(groupTabModel.$isFetchClassByIdPending)
  const isGroupDataLoading = useUnit(groupTabModel.$isEditGroupFxPending)

  const isLoading = isClassDataLoading || isGroupDataLoading

  const filteredSortedStudents = useMemo(() => {
    return filterAndSortStudents(classroom.students, searchInputValue)
  }, [classroom.students, searchInputValue])

  const onSave = async () => {
    const data = {
      studentIds: Array.from(selectedStudents),
      classroomId: classroom._id
    }
    try {
      await groupTabModel.editGroupFx({ data, groupId: activeGroupId, classId })
      toast.confirmation({ props: { title: t.groupUpdatedNotificationText }, autoCloseTime: TOAST_LIFE_PERIOD })
    } catch (error) {
      toast.error({ props: { title: t.somethingWentWrong }, autoCloseTime: TOAST_LIFE_PERIOD })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      hiddenCloseButton
      isOpen={isOpen}
      onClose={onClose}
      classes={{
        Dialog: styles.Dialog,
        Modal: styles.Modal
      }}
    >
      <Modal.Header title={t.addStudents}>
        <div className={styles.SearchWrap}>
          <Search
            placeholder={t.searchTxt}
            onChange={(e) => setSearchInputValue(e.target.value)}
            value={searchInputValue}
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className={styles.List}>
          <div className={styles.ListGroup}>
            {filteredSortedStudents.length
              ? (
                <>
                  <Typography variant='h9' className={styles.BlockTitle}>{classroom.name}</Typography>
                  {
                    filteredSortedStudents.map((student) => {
                      return (
                        <Checkbox
                          classes={{
                            InputControl: styles.InputControl,
                            Label: styles.Label
                          }}
                          key={student._id}
                          size={SIZE.MEDIUM}
                          checked={selectedStudents.has(student._id)}
                          onChange={() => {
                            setSelectedStudents((prev) => {
                              const newSet = new Set(prev)
                              newSet.has(student._id) ? newSet.delete(student._id) : newSet.add(student._id)
                              return newSet
                            })
                          }}
                          label={`${student.firstName} ${student.lastName}`}
                        />
                      )
                    })
                  }
                </>
              )
              : (
                <div className={styles.NoSearchResults}>
                  <p>{t.emptyUsersSearchList}</p>
                  <p>{t.tryAnotherName}</p>
                </div>
              )
            }
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZE.LARGE}
          onClick={onClose}
        >
          {t.cancelTxt}
        </Button>
        <Button
          color={BUTTON_COLOR.ORANGE}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZE.LARGE}
          onClick={onSave}
          isLoading={isLoading}
        >
          {t.saveTxt}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ManageGroupModal