import { Divider, Menu, MenuItem } from '@mui/material'
import { MuiMenuProps } from 'features/ExercisesList/components/ExerciseDropdown/MuiMenuProps'
import useText from 'i18n/hook'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React from 'react'

import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import { isClassCreatedManually } from '../../../../../helpers/classesView.helpers'
import { DropdownPopupIds, FormTypes, RemovableTypes } from '../../../../../helpers/enums'
import { IMe, IStudent } from '../../../../../api/types.users'
import { IModalActions } from '../../../../../redux/modules/modal.module'
import { Button, BUTTON_VARIANT, COLORS, SIZE } from '@magmamath/ui'
import { IClass, SourceType } from '../../../../../api/types.classes'
import { PATHS } from 'config/pathnames.config'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { useHistory } from 'react-router'

type IStudentsOptionsMenuProps = {
  type?: FormTypes
  modalActions: IModalActions
  student?: IStudent
  username?: string
  classOfLastRow?: string
  classroom: IClass
}

const StudentsOptionsMenuUpdated: React.FC<IStudentsOptionsMenuProps> = ({
  modalActions,
  student,
  username,
  classroom,
}) => {
  const history = useHistory()
  const t = useText()
  const moveToStudentStat = (studentId: string) => {
    history.push(`${PATHS.PARTS.STUDENT_PROGRESS}/${studentId}`)
  }
  const me = useSelector((state: RootState) => state.auth.data.me)
  const localization = useSelector((state: RootState) => state.localization)

  const isOptionForManualClass =
    isClassCreatedManually(me.municipality.source) || !me.municipality.source
  const isManualRestrictedClass =
    me.municipality.source === SourceType.matteappen && me.municipality.isPrivate
  const isRemoveOptionAvailable = classroom._id && !classroom.source

  return (
    <PopupState variant='popover' popupId={DropdownPopupIds.STUDENT_LIST_ITEM}>
      {(popupState) => (
        <>
          <Button
            size={SIZE.SMALL}
            variant={BUTTON_VARIANT.TERTIARY}
            icon={<OptionIcon size={18} color={COLORS.NEUTRAL_10} />}
            {...bindTrigger(popupState)}
          />

          <Menu sx={MuiMenuProps.sx} {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                popupState.close()
                moveToStudentStat(student!._id)
              }}
            >
              {t.showStats}
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close()
                modalActions!.openModalPassword(me, username!, localization!)
              }}
            >
              {t.resetPassTxt}
            </MenuItem>
            {isOptionForManualClass && !isManualRestrictedClass && (
              <MenuItem
                onClick={() => {
                  const studentSettings: { student?: IStudent } = { student }
                  popupState.close()
                  modalActions!.openModal(
                    t.changeStudentUsername,
                    t.userNameTxt,
                    me,
                    student!._id,
                    studentSettings
                  )
                }}
              >
                {t.changeUsernameTxt}
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                const studentSettings: { student?: IStudent } = { student }
                popupState.close()
                modalActions!.openLanguageSettingsModal(studentSettings, classroom._id)
              }}
            >
              {t.languageSupportTxt}
            </MenuItem>
            {(isOptionForManualClass || isManualRestrictedClass) && (
              <MenuItem
                onClick={() => {
                  popupState.close()
                  modalActions!.openClassesModal(student!._id)
                }}
              >
                {t.moveToAnotherClass}
              </MenuItem>
            )}
            {isRemoveOptionAvailable && <Divider sx={{ margin: '0!important' }} />}
            {isRemoveOptionAvailable && (
              <MenuItem
                onClick={() => {
                  popupState.close()
                  const currentType: RemovableTypes = classroom._id
                    ? RemovableTypes.StudentFromClass
                    : RemovableTypes.StudentFromGroup
                  modalActions!.openSimpleConfirmModal(student!, currentType)
                }}
              >
                <span style={{ color: COLORS.PRIMARY_RED }}>{t.removeStudentFromClass}</span>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </PopupState>
  )
}

export default StudentsOptionsMenuUpdated
