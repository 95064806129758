import { IActions } from 'interfaces/actions.interface'
import { IReduxState } from 'interfaces/redux.state.interface'
import * as Redux from 'redux'
import {
  autoLogin,
  autoLoginError,
  getAutologinTokenAndRedirect,
} from 'redux/modules/autologin.module'
import { clearBookSeries, getBookSeries } from 'redux/modules/book-series'
import { getMyBooks, addBook, removeBook } from '../../redux/modules/my-books'
import {
  changeClassesForExercises,
  changeGroupsForExercises,
  changeStudentsForExercises,
  clearAllEntityForExercises,
} from 'redux/modules/choosing.stud.module'
import { changeSolutionCorrectness } from 'redux/modules/correctness.module'
import { getCountries } from 'redux/modules/countries.module'
// tslint:disable-next-line:ordered-imports
import {
  changeStatus,
  defaultSortAndOrder,
  filterByClasses,
  filterByWholeClass,
  filterByDate,
  filterByExam,
  filterByGroups,
  filterByStudents,
  filterByTopics,
  resetFilters,
  resetStudentsFilters,
  sortByAsc,
  sortByDesc,
} from 'redux/modules/filterExercises.module'
import { setPrevLocation } from 'redux/modules/prevPathname.module'
import {
  getPracticeCategories,
  setOpenedCategory,
  setFilteredCategories,
  getSubcategoryStatistics,
  setSubcategoryStatistics,
  subcategoriesStatisticsRequest,
  setActiveSubcategoryId,
  setGrade,
} from 'redux/modules/practice.module'
import { getPracticeResults, getLastUserSolution } from '../../redux/modules/practiceResults.module'
import { getPracticeExample } from '../../redux/modules/practiceExampleSolution'
import { getPracticeTheoryData } from '../../redux/modules/practiceTheory.module'
import { closeSubchapter, openSubchapter } from 'redux/modules/problemsSidebarLinks.module'
import { getSchoolClasses } from 'redux/modules/schoolClasses.module'
import { getTeachersBySchool, share } from 'redux/modules/schoolTeachers.module'
import { clearSection, editSection, getSection } from 'redux/modules/section.module'
import { sortSolutions } from 'redux/modules/solutionsSorting.module'
import {
  clearStudProgress,
  getStudentAllProgress,
  getStudentProgress,
} from 'redux/modules/student-report.module'
import { addTopic, editTopic } from 'redux/modules/topic.module'
import { getTopics } from 'redux/modules/topics.module'
import {
  changePassword,
  editAccount,
  resetPasswordRequest,
} from '../../redux/modules/account.module'
import { getMe, getTokenFromCallback, login, logout } from '../../redux/modules/auth.module'
import { getBooksForCreatingProblems } from '../../redux/modules/books.module'
import {
  addClass,
  deleteClass,
  editClass,
  getClass,
  resetPasswordsForClass,
  unfollowTeacherFromClass,
  followTeacherToClass,
  setTextToSpeechForWholeClass,
} from '../../redux/modules/class.module'
import {
  changeLoginWithQrCodeStatus,
  changeArchivedClassStatus,
  getClasses,
  moveStudents,
} from '../../redux/modules/classes.module'
import {
  addExercise,
  addBulkExercises,
  clearExercise,
  deleteExercise,
  editExercise,
  getExercise,
  getExerciseManual,
  shareExercise,
} from '../../redux/modules/exercise.module'
import {
  bulkRemoveExercisesFromList,
  bulkUpdateExercisesStatuses,
  clearExercises,
  getExercises,
  removeExerciseFromList,
  setExercisesListPage,
  updateExerciseInExercises,
  updateExerciseStatsInExercises,
} from '../../redux/modules/exercises.module'
import {
  getArchived,
  getAvailable,
  getUnpublished,
} from '../../redux/modules/exercisesStatuses.module'
import { addGroup, editGroup, getGroup } from '../../redux/modules/group.module'
import { getGroups } from '../../redux/modules/groups.module'
import {
  setBritish,
  setDefaultLanguage,
  setUS,
  setSwedish,
} from '../../redux/modules/localization.module'
import {
  changeTranslationLanguage,
  changeTextSize,
  changeSoundState,
  setStudentSettings,
  clearAllSettings,
  pushStudentSettings,
} from '../../redux/modules/languageSettings.module'
import {
  closeAllPasswordsModal,
  closeChooseStudentsModalUpdated,
  closeClassesModal,
  closeCreateClassModal,
  closeCreateOrEditExerciseModal,
  closeGroupStudentAutosuggest,
  closeMapOverlay,
  closeModal,
  closeModalPassword,
  closeOverlay,
  closeSelectSchoolsModal,
  closeShareLinkModal,
  closeSimpleAsyncModal,
  closeSimpleConfirmModal,
  closeStatisticsOverlay,
  closeTeachAutosuggest,
  openAllPasswordsModal,
  openChooseStudentsModalUpdated,
  openClassesModal,
  openCreateClassModal,
  openCreateOrEditExerciseModal,
  openGroupStudentAutosuggest,
  openMapOverlay,
  openModal,
  openModalPassword,
  openOverlay,
  openShareLinkModal,
  openLanguageSettingsModal,
  closeLanguageSettingsModal,
  openTranslationLanguageSelectorModal,
  closeTranslationLanguageSelectorModal,
  openSimpleAsyncModal,
  openSimpleConfirmModal,
  openStatisticsOverlay,
  openTeachAutosuggest,
  openOldPracticeResultsModal,
  closeOldPracticeResultsModal,
  openPracticeResultsModal,
  closePracticeResultsModal,
} from '../../redux/modules/modal.module'
import {
  addProblem,
  deleteProblem,
  editProblem,
  getProblem,
} from '../../redux/modules/problem.module'
import {
  addStudentToClass,
  changeStudentsPassword,
  editStudent,
} from '../../redux/modules/student.module'
import { getStudents } from '../../redux/modules/students.module'
import './App.container.scss'
import {
  clearDataMultipleSolutions,
  getMultipleSolutions,
  updateCorrectness,
} from '../../redux/modules/multipleSolutions.module'
import {
  clearSolutions,
  pinSolution,
  unfixSolution,
} from '../../redux/modules/pinnedSolutions.module'
import { clearSkillsStats, getSkillsStats } from '../../redux/modules/skillsStat.module'
import { clearAllSkills, reloadAllSkills } from '../../redux/modules/skills.module'
import { changeMultiplicityFilter } from '../../redux/modules/filterMultiplicity.module'
import {
  clearExerciseWithoutStats,
  getExerciseWithoutStats,
  getStatsForSingleStudent,
  updateStats,
} from '../../redux/modules/exerciseWithoutStats.module'
import { changeLanguage } from '../../redux/modules/language.module'
import { createDefaultAnswer } from '../../redux/modules/defaultAnswer.module'
import { getSolutionStatuses } from '../../redux/modules/solutionStatuses.module'
import {
  clearSkillsChaptersStats,
  getSkillsChaptersStats,
} from '../../redux/modules/skillsChaptersStat.module'
import { getSchoolsByTeacher } from 'redux/modules/teachersSchools.module'

export const mapState2Props = (state: IReduxState): any => {
  return {
    account: state.account,
    addedClass: state.addedClass,
    auth: state.auth,
    autologin: state.autologin,
    bookSeries: state.bookSeries,
    bookSeriesNames: state.bookSeriesNames,
    books: state.books,
    myBooks: state.myBooks,
    chapter: state.chapter,
    classes: state.classes,
    classesStudents: state.classesStudents,
    collectionId: state.collectionId,
    countries: state.countries,
    exercise: state.exercise,
    exercises: state.exercises,
    exercisesStatuses: state.exercisesStatuses,
    filterExercises: state.filterExercises,
    form: state.form,
    group: state.group,
    groups: state.groups,
    image: state.image,
    localization: state.localization,
    language: state.language,
    languageSettings: state.languageSettings,
    modal: state.modal,
    notify: state.notify,
    pinnedSolutions: state.pinnedSolutions,
    practice: state.practice,
    prevLocation: state.prevLocation,
    problem: state.problem,
    problems: state.problems,
    schoolClasses: state.schoolClasses,
    section: state.section,
    skills: state.skills,
    skillsStats: state.skillsStats,
    solution: state.solution,
    solutionsSorting: state.solutionsSorting,
    studentProgress: state.studentProgress,
    subProblem: state.subProblem,
    multipleSolutions: state.multipleSolutions,
    subchaptersLinks: state.subchaptersLinks,
    teachers: state.teachers,
    teacherSchools: state.teacherSchools,
    topic: state.topic,
    topics: state.topics,
    user: state.user,
  }
}

export const mapDispatch2Props = (dispatch: Redux.Dispatch<Redux.AnyAction>): IActions => {
  return {
    accountActions: Redux.bindActionCreators(
      {
        changePassword,
        editAccount,
        resetPasswordRequest,
      },
      dispatch
    ),
    authActions: Redux.bindActionCreators(
      {
        getMe,
        getTokenFromCallback,
        login,
        logout,
      },
      dispatch
    ),
    autologinActions: Redux.bindActionCreators(
      {
        autoLogin,
        autoLoginError,
        getAutologinTokenAndRedirect,
      },
      dispatch
    ),
    bookSeriesActions: Redux.bindActionCreators(
      {
        getBookSeries,
        clearBookSeries,
      },
      dispatch
    ),
    booksActions: Redux.bindActionCreators(
      {
        getBooksForCreatingProblems,
      },
      dispatch
    ),
    myBooksActions: Redux.bindActionCreators(
      {
        getMyBooks,
        addBook,
        removeBook,
      },
      dispatch
    ),
    defaultAnswerActions: Redux.bindActionCreators(
      {
        createDefaultAnswer,
      },
      dispatch
    ),

    filterMultiplicityActions: Redux.bindActionCreators(
      {
        changeMultiplicityFilter,
      },
      dispatch
    ),
    classActions: Redux.bindActionCreators(
      {
        addClass,
        deleteClass,
        editClass,
        getClass,
        resetPasswordsForClass,
        unfollowTeacherFromClass,
        followTeacherToClass,
        setTextToSpeechForWholeClass,
      },
      dispatch
    ),
    classesActions: Redux.bindActionCreators(
      {
        getClasses,
        moveStudents,
        changeArchivedClassStatus,
        changeLoginWithQrCodeStatus,
      },
      dispatch
    ),
    collectionIdActions: Redux.bindActionCreators(
      {
        changeStudentsForExercises,
        changeClassesForExercises,
        changeGroupsForExercises,
        clearAllEntityForExercises,
      },
      dispatch
    ),
    countriesActions: Redux.bindActionCreators(
      {
        getCountries,
      },
      dispatch
    ),

    exerciseActions: Redux.bindActionCreators(
      {
        addExercise,
        addBulkExercises,
        clearExercise,
        deleteExercise,
        editExercise,
        getExercise,
        getExerciseManual,
        shareExercise,
      },
      dispatch
    ),
    exerciseWithoutStatsActions: Redux.bindActionCreators(
      {
        getExerciseWithoutStats,
        clearExerciseWithoutStats,
        updateStats,
        getStatsForSingleStudent,
      },
      dispatch
    ),
    exercisesActions: Redux.bindActionCreators(
      {
        setExercisesListPage,
        getExercises,
        clearExercises,
        updateExerciseStatsInExercises,
        removeExerciseFromList,
        updateExerciseInExercises,
        bulkRemoveExercisesFromList,
        bulkUpdateExercisesStatuses,
      },
      dispatch
    ),
    exercisesStatusActions: Redux.bindActionCreators(
      {
        getArchived,
        getAvailable,
        getUnpublished,
      },
      dispatch
    ),
    filterExercisesActions: Redux.bindActionCreators(
      {
        filterByClasses,
        filterByWholeClass,
        filterByDate,
        filterByExam,
        filterByGroups,
        filterByStudents,
        filterByTopics,
        resetFilters,
        resetStudentsFilters,
        sortByAsc,
        sortByDesc,
        defaultSortAndOrder,
        changeStatus,
      },
      dispatch
    ),
    groupActions: Redux.bindActionCreators(
      {
        addGroup,
        editGroup,
        getGroup,
      },
      dispatch
    ),
    groupsActions: Redux.bindActionCreators(
      {
        getGroups,
      },
      dispatch
    ),
    localizationActions: Redux.bindActionCreators(
      {
        setDefaultLanguage,
        setUS,
        setSwedish,
        setBritish,
      },
      dispatch
    ),
    languageActions: Redux.bindActionCreators(
      {
        changeLanguage,
      },
      dispatch
    ),
    languageSettingsActions: Redux.bindActionCreators(
      {
        changeTranslationLanguage,
        changeTextSize,
        changeSoundState,
        setStudentSettings,
        clearAllSettings,
        pushStudentSettings,
      },
      dispatch
    ),
    modalActions: Redux.bindActionCreators(
      {
        closeAllPasswordsModal,
        closeClassesModal,
        closeCreateOrEditExerciseModal,
        closeModal,
        closeModalPassword,
        closeOverlay,
        closeMapOverlay,
        closeSelectSchoolsModal,
        closeSimpleAsyncModal,
        closeSimpleConfirmModal,
        closeStatisticsOverlay,
        closeGroupStudentAutosuggest,
        closeTeachAutosuggest,
        openAllPasswordsModal,
        openClassesModal,
        openCreateOrEditExerciseModal,
        openModal,
        openModalPassword,
        openOverlay,
        openMapOverlay,
        openSimpleAsyncModal,
        openSimpleConfirmModal,
        openStatisticsOverlay,
        openGroupStudentAutosuggest,
        openTeachAutosuggest,
        openShareLinkModal,
        openLanguageSettingsModal,
        closeLanguageSettingsModal,
        openTranslationLanguageSelectorModal,
        closeTranslationLanguageSelectorModal,
        closeShareLinkModal,
        openCreateClassModal,
        closeCreateClassModal,
        openChooseStudentsModalUpdated,
        closeChooseStudentsModalUpdated,
        openOldPracticeResultsModal,
        closeOldPracticeResultsModal,
        openPracticeResultsModal,
        closePracticeResultsModal,
      },
      dispatch
    ),
    practiceActions: Redux.bindActionCreators(
      {
        getPracticeCategories,
        setOpenedCategory,
        setFilteredCategories,
        getSubcategoryStatistics,
        subcategoriesStatisticsRequest,
        setSubcategoryStatistics,
        setActiveSubcategoryId,
        setGrade,
      },
      dispatch
    ),
    practiceResultsActions: Redux.bindActionCreators(
      {
        getPracticeResults,
        getLastUserSolution,
      },
      dispatch
    ),
    practiceExampleActions: Redux.bindActionCreators(
      {
        getPracticeExample,
      },
      dispatch
    ),
    practiceTheoryActions: Redux.bindActionCreators(
      {
        getPracticeTheoryData,
      },
      dispatch
    ),
    prevLocationActions: Redux.bindActionCreators(
      {
        setPrevLocation,
      },
      dispatch
    ),
    problemActions: Redux.bindActionCreators(
      {
        addProblem,
        deleteProblem,
        editProblem,
        getProblem,
      },
      dispatch
    ),
    schoolClassesActions: Redux.bindActionCreators(
      {
        getSchoolClasses,
      },
      dispatch
    ),
    sectionActions: Redux.bindActionCreators(
      {
        editSection,
        getSection,
        clearSection,
      },
      dispatch
    ),
    // @ts-ignore
    skillsActions: Redux.bindActionCreators(
      {
        reloadAllSkills,
        clearAllSkills,
      },
      dispatch
    ),
    skillsStatsActions: Redux.bindActionCreators(
      {
        getSkillsStats,
        clearSkillsStats,
      },
      dispatch
    ),
    skillsChaptersStatsActions: Redux.bindActionCreators(
      {
        getSkillsChaptersStats,
        clearSkillsChaptersStats,
      },
      dispatch
    ),
    solutionActions: Redux.bindActionCreators(
      {
        changeSolutionCorrectness,
      },
      dispatch
    ),
    solutionsSortingActions: Redux.bindActionCreators(
      {
        sortSolutions,
      },
      dispatch
    ),
    solutionStatuses: Redux.bindActionCreators(
      {
        getSolutionStatuses,
      },
      dispatch
    ),
    studentProgressActions: Redux.bindActionCreators(
      {
        getStudentProgress,
        getStudentAllProgress,
        clearStudProgress,
      },
      dispatch
    ),
    classesStudentsActions: Redux.bindActionCreators(
      {
        getStudents,
      },
      dispatch
    ),
    multipleSolutionsActions: Redux.bindActionCreators(
      {
        getMultipleSolutions,
        clearDataMultipleSolutions,
        updateCorrectness,
      },
      dispatch
    ),
    pinnedSolutionsActions: Redux.bindActionCreators(
      {
        pinSolution,
        unfixSolution,
        clearSolutions,
      },
      dispatch
    ),
    subchaptersLinksActions: Redux.bindActionCreators(
      {
        closeSubchapter,
        openSubchapter,
      },
      dispatch
    ),
    teachersActions: Redux.bindActionCreators(
      {
        getTeachersBySchool,
        share,
      },
      dispatch
    ),
    teacherSchoolsActions: Redux.bindActionCreators(
      {
        getSchoolsByTeacher,
      },
      dispatch
    ),
    topicActions: Redux.bindActionCreators(
      {
        addTopic,
        editTopic,
      },
      dispatch
    ),
    topicsActions: Redux.bindActionCreators(
      {
        getTopics,
      },
      dispatch
    ),
    userActions: Redux.bindActionCreators(
      {
        addStudentToClass,
        changeStudentsPassword,
        editStudent,
      },
      dispatch
    ),
  }
}
