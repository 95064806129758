import React, { useState, useMemo, useEffect } from 'react'
import styles from './AddExistingStudentModal.module.scss'
import { Button, BUTTON_VARIANT, Checkbox, Modal, Search, SIZE, Typography } from '@magmamath/ui'
import useText from 'i18n/hook'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { useParams } from 'react-router'
import { formatClasses, filterClassesBySearch } from './helpers'
import { studentsTabModel } from 'features/tables/StudentsTable/model'
import { toast } from 'features/ToastModel/ToastModel'
import { useUnit } from 'effector-react'
import NoResultsStub from './NoResultsStub/NoResultsStub'

type AddExistingStudentModalProps = {
  isOpen: boolean
  onClose: () => void
}

const TOAST_LIFE_PERIOD = 8000

const AddExistingStudentModal = ({ isOpen, onClose }: AddExistingStudentModalProps) => {
  const me = useSelector((state: RootState) => state.auth.data.me)
  const isClassDataLoading = useUnit(studentsTabModel.$fetchClassDataLoading)
  const isAddStudentDataLoading = useUnit(studentsTabModel.$addStudentDataLoading)
  const classes = useUnit(studentsTabModel.$classes)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [selectedStudents, setSelectedStudents] = useState<Set<string>>(new Set())
  const { id: classId } = useParams<{ id: string }>()
  const t = useText()

  useEffect(() => {
    studentsTabModel.fetchClassesFx(me.school._id)
  }, [me.school._id])

  const formattedClasses = useMemo(() => formatClasses(classes, classId), [classes, classId])
  const filteredClasses = useMemo(
    () => filterClassesBySearch(formattedClasses, searchInputValue),
    [formattedClasses, searchInputValue]
  )

  const closeModal = () => {
    onClose()
    setSelectedStudents(new Set())
    setSearchInputValue('')
  }

  const addStudentToClass = async () => {
    try {
      await studentsTabModel.addStudentToClassFx({
        studentIds: Array.from(selectedStudents),
        classId,
        schoolId: me.school._id,
      })
      toast.confirmation({ props: { title: t.studentAddedTxt }, autoCloseTime: TOAST_LIFE_PERIOD })
    } catch (error) {
      toast.error({ props: { title: t.somethingWentWrong }, autoCloseTime: TOAST_LIFE_PERIOD })
    }
    closeModal()
  }

  return (
    <Modal
      classes={{
        Modal: styles.Modal,
        Dialog: styles.Dialog,
      }}
      hiddenCloseButton
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Modal.Header title={t.addStudents}>
        <div className={styles.SearchWrap}>
          <Search
            placeholder={t.searchTxt}
            onChange={(e) => setSearchInputValue(e.target.value)}
            value={searchInputValue}
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        {filteredClasses.length > 0 ? (
          filteredClasses.map(({ name, students }) => {
            return (
              <div className={styles.ListGroup} key={name}>
                <Typography variant='h9' className={styles.BlockTitle}>
                  {name}
                </Typography>
                {students.map((student) => {
                  const isSelected = selectedStudents.has(student._id)
                  return (
                    <Checkbox
                      classes={{
                        InputControl: styles.InputWithDivider,
                        Label: styles.Label,
                      }}
                      key={student._id}
                      size={SIZE.MEDIUM}
                      checked={isSelected}
                      onChange={() => {
                        setSelectedStudents((prev) => {
                          const newSet = new Set(prev)
                          isSelected ? newSet.delete(student._id) : newSet.add(student._id)
                          return newSet
                        })
                      }}
                      label={`${student.firstName} ${student.lastName}`}
                    />
                  )
                })}
              </div>
            )
          })
        ) : (
          <NoResultsStub searchValue={searchInputValue} />
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button variant={BUTTON_VARIANT.TERTIARY} size={SIZE.LARGE} onClick={closeModal}>
          {t.cancelTxt}
        </Button>
        <Button
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZE.LARGE}
          onClick={addStudentToClass}
          isLoading={isAddStudentDataLoading || isClassDataLoading}
          disabled={selectedStudents.size === 0}
        >
          {t.saveTxt}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddExistingStudentModal
