import { useStoreMap, useUnit } from 'effector-react'
import { sortABC } from 'helpers/sort.helpers'
import useText from 'i18n/hook'
import { IBasicUser, IStudent } from 'api/types.users'
import { hasSearchedValue } from 'lib/filters'
import React, { useEffect, useMemo, useState } from 'react'
import styles from './ClassroomModal.module.scss'
import { getClassroomInitStoresData } from './helpers'
import { $class, setClass } from './model/class'
import { selectAll, unselectAll } from './model/events'
import { $groups, setGroupItemSelected, setGroups } from './model/groups'
import { $students, setStudentSelected, setStudents } from './model/students'
import { ClassesStoreItem } from './types'
import { updateClassesWithClass } from './model/classes'
import { Modal, Button, BUTTON_VARIANT, BUTTON_COLOR, SIZE, Search, Checkbox, COMPONENT_COLOR } from '@magmamath/ui'
import { hasDisplayName } from 'helpers/classesView.helpers'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'
import { $exerciseSettings } from 'features/caf/exerciseSettings/model/exerciseSettings'

type ClassroomModalProps = {
  modalClass: IClass
  onClose: () => void
  classesStore: ClassesStoreItem[]
}

const ClassroomModal = ({ modalClass, onClose, classesStore }: ClassroomModalProps) => {
  const t = useText()

  const [searchInputValue, setSearchInputValue] = useState('')
  const isExamMode = useStoreMap($exerciseSettings, (state) => state.isExamMode)

  const classStore = useUnit($class)
  const groupsStore = useUnit($groups)
  const studentsStore = useUnit($students)

  const isClassSelected = !!classStore?.selected

  const groupsToDisplay = useMemo(
    () =>
      sortABC(modalClass.groups).filter(
        (group: any) =>
          group.students?.length > 0 && hasSearchedValue([group.name], searchInputValue)
      ) as unknown as IGroup[],
    [modalClass.groups, searchInputValue]
  )

  const studentsToDisplay = useMemo(
    () =>
      sortABC(modalClass.students).filter((student: IBasicUser) =>
        hasSearchedValue([`${student.firstName} ${student.lastName} ${student.firstName}`], searchInputValue)
      ) as unknown as IStudent[],
    [modalClass.students, searchInputValue]
  )

  const onSave = () => {
    updateClassesWithClass({
      id: modalClass._id,
      value: { id: modalClass._id, classStore, groupsStore, studentsStore },
    })
    onClose()
  }

  useEffect(() => {
    const modalClassStores = classesStore.find((store) => store.classStore?.id === modalClass._id)

    if (modalClassStores) {
      // editing / duplicating assignment
      setClass(modalClassStores.classStore)
      setGroups(modalClassStores.groupsStore)
      setStudents(modalClassStores.studentsStore)
      return
    }

    // creating new assignment when no storeToUpdateModalStores - no exercise.data so we need to create new unselected stores
    const { initialClass, initialGroups, initialStudents } = getClassroomInitStoresData(
      modalClass,
      false
    )

    setClass(initialClass)
    setGroups(initialGroups)
    setStudents(initialStudents)

    return () => {
      setClass(null)
      setGroups([])
      setStudents([])
    }
  }, [])

  return (
    <Modal
      classes={{ Dialog: styles.Dialog }}
      isOpen={!!modalClass}
      onClose={onClose}
      hiddenCloseButton
    >
      <Modal.Header title={hasDisplayName(modalClass) ? modalClass.displayName : modalClass.name} >
        <div className={styles.SearchWrapper}>
          <Search
            placeholder={t.searchTxt}
            onChange={(e) => setSearchInputValue(e.target.value)}
            value={searchInputValue}
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className={styles.List}>
          {groupsToDisplay.length > 0 && (
            <div className={styles.ListGroup}>
              <div className={styles.BlockTitle}>{t.groupsTxt}</div>
              {groupsToDisplay.map((group) => {
                const isSelected =
                  groupsStore.find((groupStore) => groupStore.id === group._id)?.selected ?? false
                return (
                  <Checkbox
                    classes={{
                      InputControl: styles.InputControl,
                      Label: styles.Label
                    }}
                    size={SIZE.MEDIUM}
                    color={isExamMode ? COMPONENT_COLOR.PURPLE : COMPONENT_COLOR.BLUE}
                    checked={isSelected}
                    onChange={() => {
                      setGroupItemSelected({
                        id: group._id,
                        selected: !isSelected,
                      })
                    }}
                    label={group.name}
                  />
                )
              })}
            </div>
          )}

          <div className={styles.ListGroup}>
            <div className={styles.BlockTitle}>{t.studentsTxt}</div>
            <Checkbox
              classes={{
                InputControl: styles.InputWithDivider,
                Label: styles.Label
              }}
              size={SIZE.MEDIUM}
              color={isExamMode ? COMPONENT_COLOR.PURPLE : COMPONENT_COLOR.BLUE}
              checked={isClassSelected}
              onChange={() => (isClassSelected ? unselectAll() : selectAll())}
              label={t.allStudentsTxt}
            />
            {studentsToDisplay.map((student) => {
              const isSelected =
                studentsStore.find((studentStore) => studentStore.id === student._id)?.selected ??
                false

              return (
                <Checkbox
                  classes={{
                    InputControl: styles.InputControl,
                    Label: styles.Label
                  }}
                  size={SIZE.MEDIUM}
                  color={isExamMode ? COMPONENT_COLOR.PURPLE : COMPONENT_COLOR.BLUE}
                  checked={isSelected}
                  onChange={() => {
                    setStudentSelected({
                      id: student._id,
                      selected: !isSelected,
                    })
                  }}
                  label={`${student.firstName} ${student.lastName}`}
                />
              )
            })}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer className={styles.ModalFooter}>
        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZE.LARGE}
          onClick={onClose}
        >
          {t.cancelTxt}
        </Button>
        <Button
          color={isExamMode ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.BLUE}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZE.LARGE}
          onClick={onSave}
        >
          {t.saveTxt}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ClassroomModal
