import { useStoreMap, useUnit } from 'effector-react'
import { getFilteredStats } from 'features/filters/StatsFilter/helpers'
import { getButtonColor } from 'features/heatmap/HeatmapMenu/getButtonColor'
import Problem from 'features/heatmap/Problem/Problem'
import SolutionCard from 'features/heatmap/SolutionCard/SolutionCard'
import { $isPinsFilterActive } from 'features/pinnedSolutions/model/isPinsFilterActive'
import { $pinnedSolutions } from 'features/pinnedSolutions/model/pinnedSolutions'
import { $heatmapSettings } from 'features/tables/HeatmapTable/model/heatmapSettings'
import { IStudentStat } from 'api/types.solutions'
import { IStudent } from 'api/types.users'
import { getSearchParam } from 'lib/url/getSearchParam'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import {
  getStudentSolutionsNavigationLinks,
  getStudentSolutionsNavigationLinksByStudent,
} from 'screens/AllSolutions/helpers/getNavigationLinks'
import { mapStudentsById } from 'screens/AllSolutions/helpers/mapStudentsById'
import { RootState } from 'store/store'
import {
  checkIsUserRemovedFromAssignment,
  getFullName,
  getTeacherData,
} from '../../helpers/user.helpers'
import useText from '../../i18n/hook'
import styles from './SingleStudentsSolution.module.scss'
import { IExercise } from '../../api/types.assignments'
import { IProblem, ISubProblem } from '../../api/types.problem'

type SingleStudentsSolutionProps = {
  exercise: IExercise
  problem: IProblem | null
  subProblem: ISubProblem | null
  usersStats: IStudentStat[] | null
  sortedStudents: IStudent[]
}

type SingleStudentsSolutionRouteParams = {
  studentId: string
  exerciseId: string
  taskId: string
}

const SingleStudentsSolution = ({
  exercise,
  problem,
  subProblem,
  usersStats,
  sortedStudents,
}: SingleStudentsSolutionProps) => {
  const t = useText()
  const { studentId, taskId } = useParams<SingleStudentsSolutionRouteParams>()
  const location = useLocation()
  const currentTeacher = useSelector((state: RootState) => getTeacherData(state.auth.data.me))
  const heatmapSettings = useUnit($heatmapSettings)
  const isPinsFilterActive = useUnit($isPinsFilterActive)

  const currentUsersStats = useStoreMap({
    store: $pinnedSolutions,
    keys: [usersStats, heatmapSettings.sortOption],
    fn: (pinnedSolutions) =>
      getFilteredStats({
        usersStats,
        statsFilterStore: {
          pinsData: pinnedSolutions.pinsData,
          isPinsFilterActive: pinnedSolutions.isPinsFilterActive,
          commonErrorData: null,
          commonErrorFilterTask: null,
        },
        sortOption: heatmapSettings.sortOption,
      }),
  })

  const problemNavigationLinks = getStudentSolutionsNavigationLinks(
    exercise.problems || [],
    exercise._id,
    studentId
  )

  const locationAttemptParam = getSearchParam(location.search, 'attempt')
  const formattedStudents = mapStudentsById([...sortedStudents, currentTeacher])
  const buttonColor = getButtonColor(exercise.testModeEnabled)

  const studentStats = currentUsersStats?.find((stat) => stat.studentId === studentId)?.stats
  const fullStudentStats = usersStats?.find((stat) => stat.studentId === studentId)?.stats

  const filteredStudents = isPinsFilterActive
    ? [...sortedStudents, currentTeacher].filter((student) =>
        currentUsersStats.some((user) => user.studentId === student._id)
      )
    : [...sortedStudents, currentTeacher]

  const studentNavigationLinks = getStudentSolutionsNavigationLinksByStudent(
    filteredStudents,
    exercise._id,
    taskId
  )
  const student = formattedStudents.get(studentId)

  const isUserDeleted = checkIsUserRemovedFromAssignment({ currentTeacher, student })

  const anonymousCardTitle = `${t.studentTxt} ${
    [...sortedStudents, currentTeacher].findIndex(
      (student) => student._id === studentStats?.studentId
    ) + 1
  }`

  return (
    <div className={styles.Solution}>
      {problem && (
        <Problem
          problem={problem}
          subProblem={subProblem}
          exerciseId={exercise._id}
          buttonColor={buttonColor}
          nextLink={problemNavigationLinks.get(subProblem?._id || problem._id)?.next}
          prevLink={problemNavigationLinks.get(subProblem?._id || problem._id)?.prev}
          isCorrectnessHidden={heatmapSettings.isAnonymousCorrectnessMode}
        />
      )}
      {studentStats && problem?._id && (
        <>
          <SolutionCard
            key={`${studentStats.studentId}-${subProblem?._id || problem?._id}`}
            title={
              heatmapSettings.isAnonymousNameMode
                ? anonymousCardTitle
                : getFullName(student?.firstName, student?.lastName)
            }
            solution={studentStats}
            fullSolution={fullStudentStats}
            buttonColor={buttonColor}
            exerciseId={exercise._id}
            problem={subProblem || problem}
            problemId={problem._id}
            subProblemId={subProblem?._id}
            studentId={studentStats.studentId}
            navigationLinks={studentNavigationLinks}
            headerColor={(heatmapSettings.isAnonymousCorrectnessMode && buttonColor) || undefined}
            correctAnswer={subProblem?.answer || problem.answer}
            characterType={problem.characterType}
            answerType={(subProblem || problem).answerType}
            withTimestamp
            lockAtAttemptNumber={Number(locationAttemptParam) || undefined}
            isUserDeleted={isUserDeleted}
          />
        </>
      )}
    </div>
  )
}

export default SingleStudentsSolution
