import { IAnswerInfo, IMultipleSolution, IStudentSolutionStats } from 'api/types.solutions'

type GetAttemptOffsetInitialDataProps = {
  locationAttemptNumber?: number
  solution: IMultipleSolution | IStudentSolutionStats
}

export const getAttemptOffsetInitialData = ({
  solution,
  locationAttemptNumber,
}: GetAttemptOffsetInitialDataProps) => {
  let attemptOffset = 0
  const totalAttempts = solution.answerArray?.length ?? 0

  const selectedAttemptAnswer = solution.answerArray?.find(
    (answer) => answer.selectedAttempt?.attemptNumber
  )

  if (selectedAttemptAnswer?.selectedAttempt) {
    attemptOffset = totalAttempts - selectedAttemptAnswer.selectedAttempt.attemptNumber
  }

  if (locationAttemptNumber) {
    attemptOffset = totalAttempts - locationAttemptNumber
  }

  return Math.max(0, attemptOffset)
}

type GetSolutionCardInfoProps = {
  solution: IMultipleSolution | IStudentSolutionStats
  attemptOffset: number
  locationAttemptNumber?: number
}

export const getSolutionCardInfo = ({
  solution,
  attemptOffset,
  locationAttemptNumber,
}: GetSolutionCardInfoProps) => {
  const selectedAttemptAnswer = solution.answerArray?.find(
    (answer) => answer.selectedAttempt?.attemptNumber
  )
  const selectedAttempt = selectedAttemptAnswer?.selectedAttempt

  const totalAttempts = solution.answerArray?.length ?? 0
  const currentAttemptNumber = locationAttemptNumber
    ? Math.max(locationAttemptNumber - 1, 0)
    : Math.max(totalAttempts - attemptOffset - 1, 0)
  const solutionAnswers = solution.answerArray || []
  const currentAnswerInfo =
    solutionAnswers.length > 0 ? solutionAnswers[currentAttemptNumber] : null
  const isCurrentDrawingNotAvailable = !currentAnswerInfo?.drawing && solutionAnswers.length > 0

  if (!!selectedAttempt) {
    return {
      attemptsCount: totalAttempts,
      currentAttemptNumber: selectedAttempt.attemptNumber,
      currentAnswerInfo: selectedAttemptAnswer,
      isCurrentDrawingNotAvailable: !selectedAttemptAnswer?.drawing && !!totalAttempts,
    }
  }

  return {
    attemptsCount: totalAttempts,
    currentAttemptNumber,
    currentAnswerInfo,
    isCurrentDrawingNotAvailable,
  }
}

export const cumulateTimeToSolve = (answerArray?: IAnswerInfo[]) => {
  if (!answerArray) return []
  let cumulativeTimeToSolve = 0
  return answerArray.map((answerInfo) => {
    cumulativeTimeToSolve += answerInfo.timeToSolve ?? 0
    return {
      ...answerInfo,
      cumulativeTimeToSolve:
        answerInfo.timeToSolve !== undefined ? cumulativeTimeToSolve : undefined,
    }
  })
}
