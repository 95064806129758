import React, { memo } from 'react'
import { AnswerType, MyScriptCharacterTypes, Separator } from '../../helpers/enums'
import Markdown from '../../components/RichDescription/RichDescription.component'
import { divideDisplayingPartsOfAnswer } from '../../helpers/solutions.helpers'
import { hardBracketToKatex } from '../../helpers/hardBracketToKatex'
import styles from './Answer.module.scss'
import { extractAtPart, extractSubTextPartsBetweenBrackets } from './helpers'

type AnswerProps = {
  answerValue: string
  answerType?: AnswerType
  characterType: MyScriptCharacterTypes
  index: number
  length: number
  separator: Separator
  withComma?: boolean
  keepLargeKatex?: boolean
}
const REGEXP_FOR_KATEX = /[$].*[$]+.*$/
const REGEXP_AT_SYMBOL = /\d+(.\d+)?@\d+(.\d+)?/g

const Answer = ({
  answerValue,
  answerType,
  characterType,
  index,
  length,
  separator,
  keepLargeKatex,
  withComma = true,
}: AnswerProps) => {
  const isFractionWithoutBrackets = answerValue.match(/[\/]/g)
  const isSeparatorComma = separator === Separator.COMMA
  const isShowComma = withComma && index + 1 !== length && isSeparatorComma
  const matchAtSymbol = REGEXP_AT_SYMBOL.exec(answerValue)

  if (answerType === AnswerType.handwriting) {
    const katex = hardBracketToKatex(answerValue, characterType, keepLargeKatex)
    const isKatex = katex.match(REGEXP_FOR_KATEX)

    if (!isKatex) {
      return (
        <>
          <span>{answerValue.replaceAll('[', '').replaceAll(']', '')}</span>
          {isShowComma && <span className={styles.Comma}>,</span>}
        </>
      )
    }

    if (matchAtSymbol) {
      return <span>{answerValue.replaceAll('@', ' – ')}</span>
    }

    return (
      <div key={`${answerValue}-${index}-${length}`} className={styles.Handwriting}>
        <Markdown>{hardBracketToKatex(answerValue, characterType, keepLargeKatex)}</Markdown>
        {isShowComma && <span className={styles.Comma}>,</span>}
      </div>
    )
  }

  if (answerValue.match(REGEXP_FOR_KATEX)) {
    return (
      <>
        <Markdown>{answerValue}</Markdown>
        {isShowComma && <span className={styles.Comma}>,</span>}
      </>
    )
  }

  if (matchAtSymbol) {
    const answerWithAtSymbol = extractAtPart(answerValue, matchAtSymbol)

    return (
      <>
        <span>{answerWithAtSymbol}</span>
        {isShowComma && <span className={styles.Comma}>,</span>}
      </>
    )
  }

  if (!isFractionWithoutBrackets) {
    return (
      <>
        <span>{divideDisplayingPartsOfAnswer(answerValue)}</span>
        {isShowComma && <span className={styles.Comma}>,</span>}
      </>
    )
  }

  const { partBeforeBrackets, supText, clearSubText, partAfterBrackets } =
    extractSubTextPartsBetweenBrackets(answerValue)

  return (
    <span key={answerValue}>
      <span>{partBeforeBrackets.replace(/[[\]]/g, '')}</span>
      <span>
        <sup>{divideDisplayingPartsOfAnswer(supText)}</sup>
        <span>&frasl;</span>
        <sub>{divideDisplayingPartsOfAnswer(clearSubText)}</sub>
      </span>
      <span>{partAfterBrackets.replace(/[[\]]/g, '')}</span>
      {isShowComma && <span className={styles.Comma}>,</span>}
    </span>
  )
}

export default memo(Answer)
