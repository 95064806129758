import { AnimatePresence } from 'framer-motion'
import { useSocketEvents } from 'lib/hooks/useSocketEvents'
import isEmpty from 'lodash/isEmpty'
import throttle from 'lodash/throttle'
import React, { useEffect, useState, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IAutologinActions } from 'redux/modules/autologin.module'
import { IExerciseActions } from 'redux/modules/exercise.module'
import { clearExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import {
  IExercisesActions,
  clearExercises,
  removeExerciseFromList,
  setExercisesListPage,
  updateExerciseInExercises,
  updateExerciseStatsInExercises,
} from 'redux/modules/exercises.module'
import { IFilterExercisesActions } from 'redux/modules/filterExercises.module'
import { IModalActions } from 'redux/modules/modal.module'
import { RootState } from 'store/store'
import { PATHS } from '../../config/pathnames.config'
import { Events, SortingTypes, socketEvents } from '../../helpers/enums'
import { getKeyByValue } from '../../helpers/exercises.helpers'
import socket from '../../helpers/socket.helper'
import { $filterSelectorStore } from '../filters/StudentsFilter/model'
import './ExercisesList.view.scss'
import AssignmentsFilter from './components/AssignmentsFilter/AssignmentsFilter'
import BulkSelectToggle from './components/BulkSelectToggle/BulkSelectToggle'
import EmptyExerciseContainer from './components/EmptyExerciseContainer/EmptyExerciseContainer'
import ExerciseContainer from './components/ExerciseContainer/ExerciseContainer'
import NoExercisesView from './components/NoExercisesView/NoExercisesView'
import {
  EXERCISES_PER_PAGE,
  FILTER_BADGES_DEFAULT,
  FIRST_PAGE,
  INFINITE_SCROLL_ID,
  INFINITE_SCROLL_STYLES,
  THROTTLE_TIME_IN_MS,
} from './constants'
import { useBulkSelectContext } from './contexts/BulkSelectContext/BulkSelectContext'
import { AssignmentFilterBadges } from './types/types'
import useFilterData from './useFilterData'
import { useUnit } from 'effector-react'
import { GetAllAssignmentsResponse, IExercise } from '../../api/types.assignments'
import { IState } from '../../interfaces/state.interface'
import { ExercisesListModel } from 'features/ExercisesList/model'
import { Listbox } from '@magmamath/ui'

import { ITopic } from '../../api/api.topics'

type ExercisesListProps = {
  modalActions: IModalActions
  exercisesActions: IExercisesActions
  exerciseActions: IExerciseActions
  autologinActions: IAutologinActions
  filterExercisesActions: IFilterExercisesActions
}

const ExercisesList = ({
  autologinActions,
  modalActions,
  exerciseActions,
  exercisesActions,
}: ExercisesListProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const exercise = useSelector((state: RootState) => state.exercise)
  const exercisesListPage = useSelector((state: RootState) => state.exercises.page)
  const exercises = useSelector((state: RootState) => state.exercises)
  const exercisesStatuses = useSelector((state: RootState) => state.exercisesStatuses)
  const filterExercises = useSelector((state: RootState) => state.filterExercises)
  const topics = useSelector((state: RootState) => state.topics.data.topics)
  const classesFilter = useSelector((state: RootState) => state.classesFilter)
  const classesFilterSelector = useUnit($filterSelectorStore)
  const sortBy = exercisesStatuses.available ? SortingTypes.StartDate : SortingTypes.CreatedAt
  const { changeBulkModeState, isBulkUpdating } = useBulkSelectContext()
  const [filterBadges, setFilterBadges] = useState<AssignmentFilterBadges>(FILTER_BADGES_DEFAULT)
  const [isHideEmptyList, setIsHideEmptyList] = useState(false)
  const exercisesWrap = useRef<HTMLDivElement>(null)
  const dataLength = exercises?.data?._embedded?.exercises?.length || EXERCISES_PER_PAGE
  const heightClass: string = exercisesListPage === 1 ? 'height' : 'low'
  const shouldRenderExercisesList = !isEmpty(exercises?.data?._embedded?.exercises)
  const shouldRenderNoExercisesView =
    !isHideEmptyList &&
    !exercises?.loading &&
    exercises?.data?._embedded?.exercises?.length === 0
  const shouldRenderEmptyExerciseContainer = exercises?.loading || isEmpty(exercises.data)

  const refreshFirstPageData = () => {
    dispatch(setExercisesListPage(0))
    dispatch(setExercisesListPage(FIRST_PAGE))
  }

  const setNextPage = () => {
    if (exercisesListPage < exercises.data.pages) {
      if (exercisesListPage === FIRST_PAGE && dataLength < EXERCISES_PER_PAGE) {
        return refreshFirstPageData()
      }
      dispatch(setExercisesListPage(exercisesListPage + 1))
    }
  }

  const handleAssignmentClick = (assignmentIndex: number) => {
    if (exercisesWrap.current) {
      const assignmentOrder = assignmentIndex + 1
      ExercisesListModel.setScrollPosition(exercisesWrap.current.scrollTop)
      ExercisesListModel.setAssignmentOrder(assignmentOrder)
      dispatch(setExercisesListPage(Math.ceil(assignmentOrder / EXERCISES_PER_PAGE)))
    }
  }

  const updateExercisesList = throttle(
    // throttle is used here to prevent multiple calls of updateExercisesList() ...
    // ... in a short period of time invoked by socket events on bulk update
    () => {
      setIsHideEmptyList(true)
      changeBulkModeState(false)
      refreshFirstPageData()
      dispatch(clearExercises())
    },
    THROTTLE_TIME_IN_MS,
    { trailing: false }
  )

  const changeStateOfExerciseFromSocketEvents = (event: socketEvents) => {
    if (isBulkUpdating) {
      setFilterBadges(FILTER_BADGES_DEFAULT)
      changeBulkModeState(false)
      // prevent calling updateExercisesList() to keep animation of bulk update - exercises are already updated
      return
    }
    socket.on(event, async (data: { exerciseId: string }) => {
      const isExercisesMainTab: boolean =
        history!.location.pathname === PATHS.EXERCISES.EXERCISES_MAIN
      if (isExercisesMainTab) {
        const sortHandlerMap: Partial<Record<socketEvents, any>> = {
          [socketEvents.exercisePublished]: () => updateExercisesList(),
          [socketEvents.exerciseArchived]: () => updateExercisesList(),
          [socketEvents.exerciseDeleted]: () => dispatch(removeExerciseFromList(data.exerciseId)),
          [socketEvents.exerciseUpdated]: () =>
            dispatch(updateExerciseInExercises(data.exerciseId)),
          [socketEvents.statisticsUpdate]: () => {
            dispatch(updateExerciseStatsInExercises(data.exerciseId))
            dispatch(clearExerciseWithoutStats())
          },
          [socketEvents.exerciseAdded]: () => updateExercisesList(),
        }

        const handler = sortHandlerMap[event] || updateExercisesList()
        return handler()
      }
      return
    })
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    const resetHasGoneToHeatmap = () => {
      timeoutId = setTimeout(() => {
        ExercisesListModel.setAssignmentOrder(null)
        ExercisesListModel.setScrollPosition(0)
      }, 0)
    }

    if (ExercisesListModel.assignmentOrder !== null) {
      if (ExercisesListModel.scrollPositionBeforeClose) {
        exercisesWrap.current?.scrollTo(0, ExercisesListModel.scrollPositionBeforeClose)
      } else {
        resetHasGoneToHeatmap()
      }
    }

    exercisesWrap.current?.addEventListener(Events.SCROLL, resetHasGoneToHeatmap)
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
      exercisesWrap.current?.removeEventListener(Events.SCROLL, resetHasGoneToHeatmap)
    }
  }, [])

  useEffect(() => {
    setFilterBadges(FILTER_BADGES_DEFAULT)
  }, [filterExercises.data.selectedOption])

  useEffect(() => {
    setIsHideEmptyList(true)
    changeBulkModeState(false)
    if (ExercisesListModel.assignmentOrder === null) {
      refreshFirstPageData()
      dispatch(clearExercises())
    }
  }, [exercisesStatuses.data, classesFilterSelector, classesFilter])

  useEffect(() => {
    const shouldScrollToPreviousPosition =
      ExercisesListModel.scrollPositionBeforeClose
      && ExercisesListModel.assignmentOrder !== null

    if (!shouldScrollToPreviousPosition && (classesFilter?.value || classesFilterSelector?.value)) {
      exercisesWrap.current?.scrollTo(0, 0)
    }
  }, [classesFilter, classesFilterSelector, exercisesStatuses.data])

  useEffect(() => {
    if (!filterExercises.data.sortBy || exercise.loading || !exercisesListPage) {
      return
    }

    const exerciseParams = {
      pageNumber: exercisesListPage,
      ...filterExercises.data,
      selectedOption: null,
      sortBy: exercisesStatuses.data.available ? SortingTypes.StartDate : SortingTypes.CreatedAt,
      classes:
        classesFilter && classesFilter?.value && classesFilter.value !== Listbox.ALL_OPTION
          ? [classesFilter?.value]
          : [],
      statusExercise: getKeyByValue(exercisesStatuses.data),
    }

    exercisesActions.getExercises(exerciseParams)
    setIsHideEmptyList(false)
  }, [
    exercisesListPage,
    filterExercises.data,
    exercise.loading,
    exercisesStatuses.data,
    classesFilter
  ])

  useEffect(() => {
    changeStateOfExerciseFromSocketEvents(socketEvents.exerciseDeleted)
    changeStateOfExerciseFromSocketEvents(socketEvents.statisticsUpdate)
    changeStateOfExerciseFromSocketEvents(socketEvents.exerciseUpdated)
    changeStateOfExerciseFromSocketEvents(socketEvents.exerciseAdded)
    changeStateOfExerciseFromSocketEvents(socketEvents.exercisePublished)
    changeStateOfExerciseFromSocketEvents(socketEvents.exerciseArchived)
    return () => {
      socket.removeListener(socketEvents.exerciseDeleted)
      socket.removeListener(socketEvents.exerciseUpdated)
      socket.removeListener(socketEvents.statisticsUpdate)
      socket.removeListener(socketEvents.exerciseAdded)
      socket.removeListener(socketEvents.exercisePublished)
      socket.removeListener(socketEvents.exerciseArchived)
    }
  }, [isBulkUpdating])

  useSocketEvents(socket, {
    event: socketEvents.studentStateUpdated,
    handler: (data) => dispatch(updateExerciseInExercises(data.exerciseId)),
  })

  useFilterData({
    classesFilter,
    filterSelector: classesFilterSelector,
    sortBy,
    dispatch,
    topics,
  })

  return (
    <>
      <AssignmentsFilter
        exercises={exercises as IState<GetAllAssignmentsResponse | null>}
        onAssignmentStatusChange={setIsHideEmptyList}
        filterBadges={filterBadges}
        onBulkUpdate={setFilterBadges}
      />
      {shouldRenderExercisesList && <BulkSelectToggle />}
      <div id={INFINITE_SCROLL_ID} className='scrollable-wrapper' ref={exercisesWrap}>
        {shouldRenderExercisesList && (
          <InfiniteScroll
            refreshFunction={updateExercisesList}
            dataLength={dataLength}
            next={setNextPage}
            hasMore={exercisesListPage < exercises?.data?.pages}
            loader={<div />}
            style={INFINITE_SCROLL_STYLES}
            scrollableTarget={INFINITE_SCROLL_ID}
          >
            <AnimatePresence>
              {(exercises.data?._embedded?.exercises as IExercise[])?.map((exercise, index) => {
                const topicsOfExercise: ITopic[] = topics.filter((topic: ITopic) =>
                  topic.exercises.includes(exercise._id)
                )
                return (
                  <div key={exercise._id} id={exercise._id} data-testid='assignment-card'>
                    <div>
                      <ExerciseContainer
                        autologinActions={autologinActions}
                        topics={topicsOfExercise}
                        modalActions={modalActions}
                        exerciseActions={exerciseActions}
                        exercise={exercise}
                        setFilterBadges={setFilterBadges}
                        onExerciseClick={() => handleAssignmentClick(index)}
                      />
                    </div>
                  </div>
                )
              })}
            </AnimatePresence>
          </InfiniteScroll>
        )}
        {shouldRenderNoExercisesView && <NoExercisesView />}
        {shouldRenderEmptyExerciseContainer && <EmptyExerciseContainer heightClass={heightClass} />}
      </div>
    </>
  )
}

export default ExercisesList
