import { COLORS } from '@magmamath/ui'
import React from 'react'
import bookCover from '../../../../assets/img/BookCover.png'
import useText from '../../../../i18n/hook'
import LibraryIcon from '../../../../ui/icons/LibraryIcon/LibraryIcon'
import { getImageUrl } from '../../../heatmap/Problem/helpers/imageUrl'
import styles from '../Book.module.scss'

type BookTitleProps = {
  title: string
  image?: string
}

const BookTitle = ({ title, image }: BookTitleProps) => {
  const t = useText()
  const imageUrl = image ? getImageUrl({ imageOrigin: image }) : bookCover
  const isMyBook = title === t.myBookTxt

  return (
    <div className={styles.Title}>
      {isMyBook ? (
        <LibraryIcon color={COLORS.NEUTRAL_7} size={24} className={styles.Icon} />
      ) : (
        <img src={imageUrl} alt='Book' className={styles.Image} />
      )}
      {title}
    </div>
  )
}

export default BookTitle
