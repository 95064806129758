import React, { useEffect } from 'react'
import styles from './FormMethodTab.module.scss'
import useText from 'i18n/hook'
import { Button, BUTTON_VARIANT, Input, SIZE } from '@magmamath/ui'
import { validateFieldValue, getInputLabel, getInputValidationStatus } from './helpers'
import { FormFields } from './model/types'
import { defaultFormState } from './model/constants'
import { addStudentToClassForm } from './model'
import { useStoreMap, useUnit } from 'effector-react'
import { USERNAME_MIN_LENGTH } from './constants'
import { IClass } from 'api/types.classes'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { useParams } from 'react-router'
import { toast } from 'features/ToastModel/ToastModel'
import { Events, Keys } from 'helpers/enums'

type FormMethodTabProps = {
  onAddExistingStudentClick: () => void
  classroom: IClass
  onSubmit: () => void
}

const FormMethodTab = ({ onAddExistingStudentClick, classroom, onSubmit }: FormMethodTabProps) => {
  const me = useSelector((state: RootState) => state.auth.data.me)
  const formState = useUnit(addStudentToClassForm.$formState)
  const hasError = useUnit(addStudentToClassForm.$hasError)
  const t = useText()
  const { id: classId } = useParams<{ id: string }>()
  const hasEmptyFields = useStoreMap({
    store: addStudentToClassForm.$formState,
    keys: [],
    fn: (state) => Object.values(state).some((field) => !field.value),
  })

  useEffect(() => {
    const handleEnterPress = (event: KeyboardEvent) => {
      if (event.key === Keys.ENTER) {
        event.preventDefault()
        if (!hasError && !hasEmptyFields) {
          handleSubmit()
        }
      }
    }

    document.addEventListener(Events.KEYPRESS, handleEnterPress)
    return () => document.removeEventListener(Events.KEYPRESS, handleEnterPress)
  }, [hasError, hasEmptyFields])

  const validateInput = (filedName: FormFields, value: string) => {
    if (filedName === FormFields.USERNAME && value.length >= USERNAME_MIN_LENGTH) {
      addStudentToClassForm.checkUsernameFx({ username: value, t })
      return
    }
    const error = validateFieldValue({ filedName, value, t })
    addStudentToClassForm.setError({ fieldName: filedName, error })
  }

  const onInputValueChange = (e: React.ChangeEvent<HTMLInputElement>, error: string | null) => {
    if (error || e.target.name === FormFields.PASSWORD) {
      validateInput(e.target.name as FormFields, e.target.value)
    }

    if (e.target.name === FormFields.USERNAME && e.target.value.length >= USERNAME_MIN_LENGTH) {
      addStudentToClassForm.checkUsernameFx({ username: e.target.value, t })
    }

    addStudentToClassForm.setValue({
      fieldName: e.target.name as FormFields,
      value: e.target.value,
    })
  }

  const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    validateInput(event.target.name as FormFields, event.target.value)
  }

  async function handleSubmit() {
    const res = await addStudentToClassForm.submitFormFx({
      invitationalCode: classroom.invitationalCode ?? 0,
      schoolId: me.school._id,
      classId,
      t,
    })
    if (res.error) {
      toast.error({ props: { title: res.error } })
      return
    }
    onSubmit()
    addStudentToClassForm.reset()
  }

  return (
    <div className={styles.DirectMethodTab}>
      <form className={styles.Form} onSubmit={(e) => e.preventDefault()}>
        {Object.keys(defaultFormState).map((key) => {
          const formFieldKey = key as FormFields
          const error = formState[formFieldKey].error
          const value = formState[formFieldKey].value
          return (
            <Input
              key={formFieldKey}
              type={formFieldKey === FormFields.PASSWORD ? 'password' : 'text'}
              classes={{
                InputControl: styles.InputControl,
                InputWrapper: styles.InputWrapper,
              }}
              name={formFieldKey}
              value={value}
              label={getInputLabel(formFieldKey, t)}
              onChange={(e) => onInputValueChange(e, error)}
              onBlur={onInputBlur}
              validationStatus={getInputValidationStatus(error, value)}
              errorText={error || undefined}
            />
          )
        })}
      </form>
      <div className={styles.BtnsWrap}>
        <Button
          onClick={handleSubmit}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZE.LARGE}
          disabled={hasError || hasEmptyFields}
        >
          {t.saveTxt}
        </Button>
        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZE.MEDIUM}
          onClick={onAddExistingStudentClick}
        >
          {t.addExistingStudentTxt}
        </Button>
      </div>
    </div>
  )
}

export default FormMethodTab
