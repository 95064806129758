import { LocaleData, StudentListMail } from 'helpers/enums'
import React from 'react'
import { currentLocale } from 'config/modules.config'
import useText from 'i18n/hook'

type EmailClasslistLinkProps = {
  externalClassName?: string
  children: React.ReactNode
}

const linkMap: Record<string, StudentListMail> = {
  [LocaleData.SE]: StudentListMail.SE,
  [LocaleData.US]: StudentListMail.US,
  [LocaleData.GB]: StudentListMail.GB,
}

const EmailClasslistLink = ({
  externalClassName,
  children,
}: EmailClasslistLinkProps) => {
  const t = useText()

  const textForEmailBody = `${t.hiTxt}!%0A${t.findAttachedStudentLists}.%0A${t.thankYouTxt}!`

  return (
    <a
      className={externalClassName ?? ''}
      href={`mailto:${linkMap[currentLocale.currentLang] || linkMap[LocaleData.US]
        }?subject=${t.klasslistaSubject}&body=${textForEmailBody}`}
    >
      {children}
    </a>
  )
}

export default EmailClasslistLink
