import React from 'react'
import styles from './NoResultsStub.module.scss'
import useText from 'i18n/hook'
import { Typography } from '@magmamath/ui'

type NoResultsStubProps = {
  searchValue: string
}

const NoResultsStub = ({ searchValue }: NoResultsStubProps) => {
  const t = useText()

  if (!searchValue) {
    return (
      <div className={styles.NoSearchResults}>
        <Typography variant='xs'>{t.noStudentsForAddingToClass}</Typography>
      </div>
    )
  }

  return (
    <div className={styles.NoSearchResults}>
      <Typography variant='xs'>{t.emptyUsersSearchList}</Typography>
      <Typography variant='xs'>{t.tryAnotherName}</Typography>
    </div>
  )
}

export default NoResultsStub
