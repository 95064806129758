import { FormattedClass } from './types'
import { IClassesStudents } from 'api/types.classesStudents'

const getCurrentClassStudentsIds = (
  classes: IClassesStudents[],
  currentClassId: string
): Set<string> => {
  const currentClass = classes.find((cls) => cls._id === currentClassId)
  return new Set(currentClass?.students.map((student) => student._id))
}

export const formatClasses = (classes: IClassesStudents[], currentClassId: string): FormattedClass[] => {
  const currentClassStudentsIds = getCurrentClassStudentsIds(classes, currentClassId)
  return classes
    .filter((cls) => cls._id !== currentClassId && !cls.archived && cls.students.length > 0)
    .map((cls) => ({
      name: cls.name,
      students: cls.students
        .filter((student) => !currentClassStudentsIds.has(student._id))
        .sort((a, b) => a.firstName.localeCompare(b.firstName)),
    }))
}

export const filterClassesBySearch = (
  classes: FormattedClass[],
  searchValue: string
): FormattedClass[] => {
  const trimmedSearchValue = searchValue.trim().toLowerCase()
  return classes.reduce((acc, cls) => {
    const students = cls.students.filter((student) =>
      `${student.firstName} ${student.lastName} ${student.firstName}`
        .toLowerCase()
        .includes(trimmedSearchValue)
    )

    if (students.length > 0) {
      acc.push({
        name: cls.name,
        students,
      })
    }

    return acc
  }, [] as FormattedClass[])
}
