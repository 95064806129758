import React from 'react'
import { SvgIconProps } from '../types/types'

const RemoveBookIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
    >
      <path
        d='M12.1989 3.91835L10.7989 5.44563V12.3599L7.79771 10.2162C7.44108 9.96148 6.99073 9.90173 6.59013 10.037L3.12462 13.8175C3.40539 13.8301 3.69651 13.7534 3.95771 13.5669L7.01885 11.3803L10.08 13.5669C10.9669 14.2004 12.1989 13.5664 12.1989 12.4765V3.91835Z'
        fill={color}
      />
      <path
        d='M1.83885 10.0405V2.84012C1.83885 1.74659 2.72533 0.860117 3.81885 0.860117H10.2189C10.2306 0.860117 10.2423 0.860219 10.254 0.860422L8.97091 2.26012H3.81885C3.49853 2.26012 3.23885 2.51979 3.23885 2.84012V8.51327L1.83885 10.0405Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9941 0.366793C13.2781 0.629016 13.2958 1.07188 13.0336 1.35595L1.99513 13.3143C1.7329 13.5984 1.29004 13.6161 1.00597 13.3539C0.721895 13.0917 0.70418 12.6488 0.966403 12.3647L12.0049 0.40636C12.2671 0.122285 12.71 0.104571 12.9941 0.366793Z'
        fill={color}
      />
    </svg>
  )
}

export default RemoveBookIcon
