import { apiManager } from './apiManager'
import { IStudent } from './types.users'

export type GetSingleStudentQRKeyPayload = {
  student: IStudent
}

export type GetSingleStudentQRKeyResponse = {
  key: string
}

export type SignUpStudentPayload = {
  firstName: string
  lastName: string
  username: string
  password: string
  phone: number
  type: number
  invitationalCode: number
  schoolId: string
}

type SignUpStudentResponse = {}

export type UpdateSingleStudentPayload = {
  studentId: string
  data: {
    username: string
  }
}

export type UpdateSingleStudentResponse = {}

export type UpdateMySettingsPayload = {
  chosenClass?: string | null
  appLocale?: string
}

export type UpdateMySettingsResponse = {}

export type UpdateStudentSettingsPayload = {
  studentId: string
  data: {
    translationLanguage?: string | null
    textSize?: number
    textToSpeech?: boolean
  }
}

export type UpdateStudentSettingsResponse = {}

export class usersApi {
  public static readonly getSingleStudentQRKey = apiManager.get<
    GetSingleStudentQRKeyResponse,
    GetSingleStudentQRKeyPayload
  >(({ student }) => `students/${student._id}/qr-code`)

  public static readonly signUpStudent = apiManager.post<
    SignUpStudentResponse,
    SignUpStudentPayload
  >('users')

  public static readonly updateSingleStudent = apiManager.put<
    UpdateSingleStudentResponse,
    UpdateSingleStudentPayload
  >(({ studentId, data }) => ({
    url: `students/${studentId}`,
    body: data,
  }))

  public static readonly updateMySettings = apiManager.put<
    UpdateMySettingsResponse,
    UpdateMySettingsPayload
  >('users/me/settings')

  public static readonly updateStudentSettings = apiManager.put<
    UpdateStudentSettingsResponse,
    UpdateStudentSettingsPayload
  >(({ studentId, data }) => ({
    url: `students/${studentId}/settings`,
    body: data,
  }))
}
