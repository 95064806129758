import clsx from 'clsx'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { addClass } from 'redux/modules/class.module'
import UIButton from 'ui/Buttons/UIButton/Button'
import { IMe } from '../../../api/types.users'
import { EN_GRADES_LENGTH, getFullGrades } from '../../../lib/grades'
import styles from './CreatingClassCard.module.scss'
import ButtonLoader from '../../loaders/ButtonLoader/ButtonLoader'
import { ILocalization } from '../../../config/languages.config'

type CreatingClassCardProps = {
  localization: ILocalization
  me: IMe
  schoolId: string
  headerTitle?: string
  headerSubtitle?: string
  shouldRedirectOnClassCreation?: boolean
}

const DEFAULT_INPUT_VALUE = ''
const DEFAULT_SELECT_VALUE = ''

const CreatingClassCard = ({
  localization,
  me,
  schoolId,
  headerTitle,
  headerSubtitle,
  shouldRedirectOnClassCreation,
}: CreatingClassCardProps) => {
  const { createClassNameTxt, create, grade, requiredFieldText } = localization.data

  const history = useHistory()
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState<string>(DEFAULT_INPUT_VALUE)
  const [isInputError, setIsInputError] = useState(false)
  const [isGradeError, setIsGradeError] = useState(false)
  const [selectedGrade, setSelectedGrade] = useState<string | number>(DEFAULT_SELECT_VALUE)
  const [isAnswerSubmitted, setAnswerSubmitted] = useState(false)
  const handleErrorState = (selectedGrade: string | number, inputValue: string) => {
    if (!selectedGrade) setIsGradeError(true)
    if (!inputValue) setIsInputError(true)
  }
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value || ''
    if (isInputError && newValue) setIsInputError(false)

    setInputValue(newValue)
  }

  const onCreateNewClass = async () => {
    if (isAnswerSubmitted) return
    if (!inputValue || !selectedGrade) return handleErrorState(selectedGrade, inputValue)

    setAnswerSubmitted(true)
    await dispatch(
      addClass(
        { schoolId, name: inputValue, grade: Number(selectedGrade) },
        history,
        localization,
        {
          shouldRedirectOnClassCreation,
        }
      )
    )
    setSelectedGrade(DEFAULT_SELECT_VALUE)
    setInputValue(DEFAULT_INPUT_VALUE)
  }

  const onFormSubmit = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    onCreateNewClass()
  }

  const getOrderedGrades = useCallback(() => {
    const grades = getFullGrades(me.setting.appLanguage, localization)
    const otherGrade = grades.shift()
    if (otherGrade) grades.push(otherGrade)
    return grades
  }, [me, localization])

  const orderedGrades = useMemo(() => getOrderedGrades(), [me, localization])

  return (
    <div className={styles.Card}>
      {!!(headerTitle || headerSubtitle) && (
        <div className={styles.Header}>
          {!!headerTitle && <h2 className={styles.HeaderTitle}>{headerTitle}</h2>}
          {!!headerSubtitle && <p className={styles.HeaderSubtitle}>{headerSubtitle}</p>}
        </div>
      )}

      <form onSubmit={onFormSubmit} className={styles.FormBody}>
        <div className={styles.InputWrapper}>
          <input
            autoFocus
            className={clsx(styles.Input, { [styles.InputError]: isInputError })}
            value={inputValue}
            placeholder={createClassNameTxt}
            onChange={onInputChange}
          />
          {isInputError && <span className={styles.ErrorMessage}>{requiredFieldText}</span>}
        </div>

        <div className={styles.GradesWrapper}>
          <span className={styles.GradeText}>{grade}</span>
          <div className={styles.Grades}>
            {orderedGrades.map((grade) => {
              const isLargeButton = grade.label?.toString().length > 2
              return (
                <UIButton
                  className={clsx(styles.GradeButton, {
                    [styles.GradeButtonLarge]: isLargeButton,
                    [styles.GradeButtonExtraLarge]:
                      isLargeButton && orderedGrades.length === EN_GRADES_LENGTH,
                    [styles.GradeButtonSelected]: selectedGrade === grade.value,
                  })}
                  type='button'
                  variant='basic'
                  color='blue'
                  size='large'
                  key={grade.value}
                  onClick={() => {
                    setIsGradeError(false)
                    setSelectedGrade(grade.value)
                  }}
                >
                  {grade.label}
                </UIButton>
              )
            })}
          </div>
          {isGradeError && <span className={styles.GradesError}>{requiredFieldText}</span>}
        </div>

        <UIButton
          type='submit'
          className={clsx(styles.SubmitButton, { [styles.SubmitButtonLoader]: isAnswerSubmitted })}
          variant='basic'
          size='medium'
          color='green'
        >
          {isAnswerSubmitted ? <ButtonLoader /> : create}
        </UIButton>
      </form>
    </div>
  )
}

export default CreatingClassCard
