import { COLORS, Typography } from '@magmamath/ui'
import { IAnswerInfo } from 'api/types.solutions'
import { currentLocale } from 'config/modules.config'
import { DateFormats, LocaleData } from 'helpers/enums'
import useText from 'i18n/hook'
import { getCorrectDate } from 'lib/dates'
import React, { useMemo } from 'react'
import TimerIcon from 'ui/icons/TimerIcon/TimerIcon'
import { cumulateTimeToSolve } from '../helpers'
import styles from './Timestamp.module.scss'

type TimestampProps = {
  currentAnswerInfo: IAnswerInfo | null
  answerArray?: IAnswerInfo[]
}

const Timestamp = ({ currentAnswerInfo, answerArray }: TimestampProps) => {
  const t = useText()
  const isSwLocale = currentLocale.currentLang === LocaleData.SE
  const dateFormat = isSwLocale ? DateFormats.FULL_WITH_SECONDS : DateFormats.FULL_WITH_SECONDS_US
  const formattedCreatedAt =
    currentAnswerInfo?.createdAt &&
    getCorrectDate(
      currentAnswerInfo.createdAt,
      dateFormat,
      currentLocale.currentLang || LocaleData.US
    )

  const formattedTimeToSolve = useMemo(() => {
    const cumulativeTimeToSolve = cumulateTimeToSolve(answerArray).find(
      (answer) => answer._id === currentAnswerInfo?._id
    )?.cumulativeTimeToSolve
    if (cumulativeTimeToSolve === undefined || cumulativeTimeToSolve < 0) return null
    const seconds = Math.floor(cumulativeTimeToSolve / 1000)
    if (seconds === 0) return `1 ${t.secondsAbbreviation}`
    if (seconds < 60) return `${seconds} ${t.secondsAbbreviation}`
    if (seconds < 300) {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = seconds % 60
      return `${minutes} ${t.minutesAbbreviation} ${remainingSeconds} ${t.secondsAbbreviation}`
    }
    if (seconds < 600) return `${t.moreThan} 5 ${t.minutesAbbreviation}`
    return `${t.moreThan} 10 ${t.minutesAbbreviation}`
  }, [answerArray, currentAnswerInfo?._id, t])

  return (
    <div className={styles.TimeInfo}>
      {formattedTimeToSolve && (
        <>
          <TimerIcon color={COLORS.NEUTRAL_9} size={16} />
          <Typography variant='h9' className={styles.Timestamp}>
            {formattedTimeToSolve}
          </Typography>
          <span className={styles.Divider} />
        </>
      )}

      <Typography variant='h9' className={styles.Timestamp}>
        {formattedCreatedAt}
      </Typography>
    </div>
  )
}

export default Timestamp
